import React, { useEffect, useState } from "react";
import Header from "../elements/header";
import notificationData from "../../content/notificationData.json";
import { NotificationCard } from "../elements/cards";
import { getPushContentByStudentUid } from "../../function/Student/pushNotification/PushContent";
import { useLocation } from "react-router-dom";
import { getIndividualResource } from "../../function/Student/resources/resources";
import searchIcon from "../../images/searchIcon.svg";
import Footer from "../elements/Footer";
// import { uuid } from "../../redux/useReducer";
let count=0;

function ResourcePage() {
  const { state } = useLocation();
  const [values] = useState(state);
  const [resourceData, setResourceData] = useState([]) as any;
  const [resoursestate, setresourseState] = useState(false);
  let data_Length;
  const [search, setSearch] = useState("");
  const uuid:any = localStorage.getItem('uid')

  const searchresult = () => {
    if (search.length > 0) {
      return resourceData.filter((obj: any) =>
        obj.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    return resourceData;
  };
  const data = async () => {
    const RescourceData = await getIndividualResource(values.resourceId, uuid);
    setResourceData(RescourceData);
    setresourseState(true);
    data_Length = await resourceData?.map((item: any) => {
      return (count += 1);
    });
  };

  const [lengthcount, setCount] = useState(9);
  const handleLoad = () => {
    let data_Lenght = resourceData?.map((item: any) => {
      return item;
    });
    setCount(data_Lenght.length);
  };
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    data();
  }, []);

//   console.log("resourceData", resourceData);

  return (
    <div className="bg-[#F9FCFF] h-[100vh]">
      <Header />
      <div>
        <div className="flex mx-auto justify-center items-center">
          <input
            placeholder="Search..."
            className=" mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]"
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <img
            className="bg-[#036AD1] p-2 shadow-3xl  w-[45px] h-[40px]"
            src={searchIcon}
            alt="icon"
          />
        </div>
        {resoursestate ? (
          <div>
            {resourceData.length === 0 ? 
              <div>
                <p>No Resource Available</p>
              </div>
             : 3 > count && count >1 ? (
              <div className="flex mt-5 mx-auto w-11/12 items-center max-monitor:justify-center max-desktop:justify-center max-laptop:block max-mobile:block">
                {searchresult()
                  ?.slice(0, lengthcount)
                  .map((data: any) => {
                    return (
                      <div className="m-4 w-5/12" key={data.key}>
                        
                        <NotificationCard
                          title={data.title}
                          image={data?.thumbnail?.url}
                          desc={data?.text_message}
                          subType=""
                          content_type={data?.content_type}
                          data={data}
                        />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-4 items-center mx-auto w-11/12 max-mobile:w-full max-mobile:p-4 p-10 max-laptop:grid-cols-2 max-mobile:grid-cols-1">
                {searchresult()
                  ?.slice(0, lengthcount)
                  .map((data: any) => {
                    return (
                      <div key={data.key}>
                      
                        <NotificationCard
                          title={data.title}
                          image={data?.thumbnail?.url}
                          desc={data?.text_message}
                          subType=""
                          content_type={data?.content_type}
                          data={data}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
        {resourceData.length  > 6 ?   <button
          onClick={handleLoad}
          className="text-white text-center mt-5  w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
        >
          {" "}
          Load More{" "}
        </button> :''}
       
      </div>
<Footer/>

    </div>
  );
}

export default ResourcePage;

