import React, { useRef, useState, useEffect } from 'react'
import { ApplyjobCard } from '../elements/cards'
import rightArrow from '../../images/rightArrow.svg'
import viewallicon from '../../images/viewallicon.svg'
import { getDashBoardData } from '../../function/Student/dasboard'
import { dateFormat } from '../elements/dateConvertion'
import { useDispatch } from 'react-redux';
import { jobOpening_data, placement_need } from '../../redux/useReducer'
// import { uuid } from '../../redux/useReducer'
import { useNavigate } from 'react-router-dom'
import { getProfileDetails } from '../../function/Student/Profile/Profile'
import { checkEligiblityForPlacementAndGetJobData } from '../../function/Student/job/jobs'
import WhiteLock from "../../images/White_lock.svg"

function Jobopening() {
    const scrollRef: any = useRef();
    const navigate = useNavigate()
    const uuid:any = localStorage.getItem('uid')
    const [placedJob, setPlacedJob] = useState() as any;
    const [eligibility, setEligibility] = useState({}) as any
    const [jobstate, setJobstate] = useState(false)

    const jobdetailsData = async () => {
        let placementData = await getProfileDetails(uuid)
        setPlacedJob(placementData)
        let dataValue: any = await checkEligiblityForPlacementAndGetJobData(uuid)
        setEligibility(dataValue)
        setJobstate(true)
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        jobdetailsData()
    }, [])

    const handleClick = (props: any) => {
        navigate('/latestjobdesc', { state: props })
    }

    const handleList = () => {
        navigate('/latestjob')
    }

    return (
        <div>
            <div className=' text-left'>
                <div className=' flex justify-between px-6 pt-3'>
                    <p className=' text-left  text-[#707070] text-[30px] font-medium max-mobile:text-[20px]'>Job Openings</p>
                    <p className='flex items-center text-[24px] text-[#036AD1] font-bold cursor-pointer  max-mobile:text-[16px] max-mobile:font-normal' onClick={handleList}>View All<img className='w-3 h-3 pl-1' src={viewallicon} alt='icon' /> </p>
                </div>
            </div>
            <div className="flex items-center px-10 max-tablet:px-10 max-mobile:px-3 justify-between space-x-5 h-[300px]" >
                <img
                    src={rightArrow}
                    alt="ScrollY"
                    className="rotate-180 cursor-pointer max-mobile:hidden"
                    onClick={() => (scrollRef.current.scrollLeft -= 1200)}
                />
               
                {jobstate ? <div className={`mx-auto flex space-x-5  overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth   `} ref={scrollRef}>
                    {placedJob?.placement_need ? <div>
                        {eligibility.status ? <div>
                            <div className='flex gap-2'>
                                {eligibility?.data?.length ? eligibility?.data?.map((data: any, index: number) => {
                                    return <div key={index} onClick={() => handleClick(data)}>
                                        <ApplyjobCard subtitle={data?.company_name} classStyle='w-[330px]  h-[200px]' title={data?.job_role} status='View Job' date={dateFormat(data?.inserted_at, 'DD MMM YYYY')} location={data?.location} />
                                    </div>
                                }):'No Jobs Available'}
                            </div>
                        </div> : <div>
                            { eligibility.message == 'payment-not-completed' || eligibility.message == 'progress-not-completed' ? <div className=' flex items-center align-middle '>
                                <div className=' align-middle mx-auto text-center relative w-[500px] max-mobile:w-[72vw] p-7 max-tablet:p-7 max-mobile:p-2 justify-center bg-white border border-gray-200 rounded-lg shadow' >
            
                                    <div className='flex py-10 max-mobile:py-4 items-center'>
                                        <div className='w-[40%]'>
                                            <img className='w-[150px] h-[112px] max-mobile:w-[90px] max-mobile:h-[70px]' src={WhiteLock}></img>
                                        </div>
                                        <p className='w-[60%] max-mobile:w-[80%] text-[#000000] text-[18px] max-mobile:text-[14px] text-left ml-5 max-mobile:mx-auto '>Jobs section will be enabled after you complete the Balance payment and completing at least 90% Course</p>
                                    </div>
                                </div>
                            </div> : <div className=' flex   items-center align-middle '>
                                <div className=' align-middle mx-auto text-center relative w-[450px] p-7 justify-center bg-white border border-gray-200 rounded-lg shadow' >
                                    <p className=' text-[#000000] text-[18px] '>You have not applied for any job openings in the last 90 days. If you stilll need placement support, please speak to our HR</p>
                                </div>
                            </div>}
                        </div>}
                    </div> : <div className=' flex bg-[#F9FCFF] items-center align-middle '>
                        <div className=' align-middle mx-auto text-center relative w-[450px] p-10 justify-center bg-white border border-gray-200 rounded-lg shadow' >
                            <p className=' text-[#000000] text-[18px] '>no placement support</p>
                        </div>
                    </div>
                    }
                </div> : <div> </div>}
                <img
                    src={rightArrow}
                    alt="ScrollY"
                    className=" cursor-pointer max-mobile:hidden"
                    onClick={() => (scrollRef.current.scrollLeft += 1200)}
                />
            </div>

        </div>
    )
}
export default Jobopening