import { JobStatusCalculationFunc } from "./interface";

export const findDifferenceOfDates = (startDate: any, endDate: any) => {
    const oneDay = 24 * 60 * 60 * 1000; 
    const diffInMilliseconds = Math.abs(startDate - endDate);
    return Math.round(diffInMilliseconds / oneDay);
}

export const calculatePaidCourseCompletion = (overallDuration: string, sessions: any[]) => {
    // console.log(overallDuration, sessions);
    // const progress_in_hrs = sessionByBatchId?.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue?.duration), 0)
    const completedDuration = sessions?.reduce((acc: number, currentValue: any) => Number(acc) + Number(currentValue?.duration), 0);
    return Math.floor((completedDuration / +overallDuration) * 100);
}

export const calculateFinalStatus:JobStatusCalculationFunc = (jobStatus) => {
    switch (true) {
        case jobStatus.placed:
            return "Placed"
        case jobStatus.joined:
            return "Joined";
        case jobStatus.shortlisted:
            return "Shortlisted";    
        default:
            return "Applied";
    }
}