export type Props = {
  value: any;
  image: any;
  type: any
};

export default function courseCard({ image, value, type }: Props) {
  return (
    <div className=" h-[250px] max-tablet:h-[250px] max-mobile:w-[78vw] max-tablet:w-full max-mobile:h-[200px] w-full  text-left  max-laptop:h-[250px] mx-auto   bg-white border border-gray-200 rounded-lg shadow ">
      <img className=" h-[150px]  max-laptop:h-[150px] max-tablet:h-[150px] max-mobile:h-[130px] w-full" src={image} alt='course thumbnil' />
      {type === 'Paid' ? <p className="p-4 border-t-2 text-center text-[22px] max-desktop:text-[18px]  max-tablet:text-[14px] font-medium text-[#1C0404] rounded-t-3xl">{value}</p>
        : <div className="flex justify-between p-4 border-t-2 rounded-t-3xl">
          <p className="  text-[22px] max-laptop:text-[18px] font-medium text-[#1C0404] ">{value}</p>
          <p className=' text-[#FFFFFF] mt-2 px-2 text-[11px] p-0.5 h-[20px]  rounded-md bg-[#036AD1]'>Free</p>
        </div>
      }
    </div>
  );
}