import {useState, useEffect, useRef} from 'react';
// import boldArrow from "../../Assets/boldArrow.svg";
import boldArrow from "../../../images/boldArrow.svg"

export const DropdownSingleInputWithCheckbox = ({
  mandatory,
  mandatoryClass,
  topDivStyle,
  options,
  placeholder,
  label,
  name,
  value,
  onBlur,
  mapData,
  mapThrough,
  formik,
  forId,
  classNameInput,
  zIndex,
  disabled,
  handleOut,
  trainerId,
  error,
  isTouched,
  setFunction,
  inputId
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const dropdownRef:any = useRef(null);
  const checkIsError = isTouched && error;

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    mapThrough==="status" ? !disabled && setIsOpen(!isOpen) : setIsOpen(!isOpen)
  };

  const handleDropdownClick = (event:any) => {
    event.stopPropagation(); // Prevent event bubbling
  };
    
  const selectOptionTwo = (name:string, option: any) => {
    if (forId === "course_completion") {
      setFunction(name, Number(option));   
    } else {
      // setFunction(name, option);
      setFunction((prevState:any) => ({
        ...prevState,
        [name]:option
      }));
    }
  }

  return (
    <div className={`${topDivStyle} space-y-0.5`} ref={dropdownRef}>
      <label className="text-[#707070] 2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]">
        {label} {mandatory && <span className={`${mandatoryClass}`}>*</span>}
      </label>
      <div
        className={`relative ${zIndex ? zIndex : "z-10"} cursor-pointer`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          className={`pr-2 outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-1 ${classNameInput}`}
          placeholder={placeholder}
          disabled={disabled}
          value={`${
            forId === "course_completion" && value ? value + "%" : value
          }`}
          readOnly
        />
        <img
          src={boldArrow}
          alt="boldArrow"
          className={`absolute top-2.5 right-4 ${isOpen && "rotate-180"}`}
        />
        {checkIsError && (
          <span className="text-red-500 absolute -bottom-6 right-0">{error}</span>
        )}
        {isOpen && (
          <div
            onClick={handleDropdownClick}
            className={`z-20 shadow-md shadow-[#00000029] absolute w-full bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] pt-2 min-h-[100px] max-h-[260px] scroll-smooth overflow-y-scroll`}
          >
            <div className="max-h-60">
              {options &&
                options.map((option: any, i: number) => (
                  <label
                    key={i}
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <input
                      id={inputId}
                      type="checkbox"
                      className="h-4 w-4"
                      name={name}
                      value={value}
                      checked={String(value) === String(option)}
                      onChange={() => (handleOut?handleOut(name,option,trainerId):selectOptionTwo(name,option))}
                      onBlur={onBlur}
                    />
                    <span className="ml-2 text-gray-900 font-Roboto select-none">
                      {`${
                        forId === "course_completion" ? option + "%" : option
                      }`}
                    </span>
                  </label>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};