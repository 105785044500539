import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { getProfileDetails, updateProfileData } from '../../function/Student/Profile/Profile'
// import { uuid } from '../../redux/useReducer'

function Editprofile() {
    const uuid:any = localStorage.getItem('uid')
    const [userdata, setUserdata] = useState({}) as any
    const [UpdateStudent, setUpdateStudent] = useState({
        name: '',
        email: '',
        phoneNumber: ''
    })
    const data = async () => {
        const ProfileDetails = await getProfileDetails(uuid)
        setUserdata(ProfileDetails)
        username(ProfileDetails)
    }
    const username = (props: any) => { 
        setUpdateStudent({
            name: props.name,
            email: props.email,
            phoneNumber: props.phoneNumber
        })
    }
    const handleChange = (e: any) => {
        UpdateStudent.name = e.target.value
        setUpdateStudent((prevstate) => ({ ...prevstate, name: e.target.value }))
    }
    const handleSumbit = async () => {
        await updateProfileData(UpdateStudent, uuid)
        window.location.reload()

    }
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        })

        data()
        
    }, [])
    return (
        <div className=' min-h-screen bg-[#FFFFFF] '>
            <div className=''>
                <div className='p-10 max-mobile:p-1 text-left mx-auto '>
                    <p className='mt-5  text-[#707070]  text-[25px] max-mobile:text-[20px] max-mobile:mt-1'>Edit Profile</p>
                    <hr className='mt-4' />
                </div>
                <div className='flex text-left ml-10 max-mobile:block my-3'>
                    <div className='w-full'>
                        <p className='text-20px] text-[#707070] font-medium mb-1'> Name</p>
                        <input className=' border-2 w-9/12 p-2 cursor-pointer font-medium text-[#000]' onChange={(e) => handleChange(e)} value={UpdateStudent.name} />
                    </div>
                    <div className='w-full max-mobile:my-3'>
                        <p className='text-20px] text-[#707070] font-medium mb-1'> Phone Number</p>
                        <input disabled className=' border-2  text-[#000] font-medium  w-9/12 p-2' value={userdata.phoneNumber} />
                    </div>
                    <div className='w-full'>
                        <p className='text-20px] text-[#707070] font-medium mb-1'> Email</p>
                        <input disabled className=' border-2  text-[#000] font-medium  w-9/12 p-2' value={userdata.email} />
                    </div>
                </div>
                <button onClick={handleSumbit} type="button" className="my-10 text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-full text-sm px-5 py-2 mr-14 mb-20" >Update</button>
            </div>
        </div>
    )
}

export default Editprofile