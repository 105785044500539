import { errorNotifier } from "../commonHelper"
import { getJobsForTheStudent } from "./job/jobs";
import { getResources } from "./resources/resources";

export const getDashBoardData = async (userId: string) => {
   
    try {
        const jobs = await getJobsForTheStudent(userId);
        const resources = await getResources(userId);
        return {
            ...jobs && { jobs },
            ...resources && { resources }
        }
    } catch (error) {
        return errorNotifier(error);
    }
}