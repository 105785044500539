import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../elements/header'
import ReactPlayer from 'react-player/youtube'
import Footer from '../elements/Footer'

function NotificationDetails() {
  const { state } = useLocation()
  const [notificationdetail] = useState(state)

  return (
    <div>
      <Header />
      <div className='w-11/12 max-mobile:h-[73vh] max-mobile:bg-[#fff] mx-auto bg-[#F9FCFF] h-auto'>

          <p className='p-3 text-[#000000] text-[34px] font-medium max-mobile:text-[18px]'>{notificationdetail.title}</p>
         {notificationdetail.subType === "External Link" ?<div className='bg-[#ffffff] mb-10'>
   
         <ReactPlayer className='!w-full !h-[80vh] mx-auto' url={notificationdetail?.data?.external_link} controls={true}/>
           {/* <iframe className='w-full h-[85vh] ' src={notificationdetail?.data?.external_link}></iframe> */}
         </div> : notificationdetail.subType === "Upload Video" ? <div className='bg-[#ffffff]  mb-10'>
           <iframe className='!w-full !h-[75vh] ' src={notificationdetail?.data?.video?.url}></iframe>
         </div> : notificationdetail.subType === "Text" ?  <div className='bg-[#ffffff]   mb-10'>
             {notificationdetail.desc ? <p className="pre-line text-left py-5 w-5/6  mx-auto">{notificationdetail.desc}</p>: <div></div>}
          </div>:<div className='bg-[#ffffff]  mb-10'>
             {notificationdetail.desc ? <img className='w-5/6 ml-24' src={notificationdetail.image} alt='titleImg' />:  <img className='w-full h-full' src={notificationdetail.image} alt='titleImg' /> }
             {notificationdetail.desc ? <p className="pre-line text-left py-5 w-5/6  mx-auto">{notificationdetail.desc}</p>: <div></div>}
          </div>
          }
      </div>
<Footer/>

    </div>
  )
}

export default NotificationDetails