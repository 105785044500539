import googleLogo from '../../images/googleLogo.webp'
import { useLocation, useNavigate } from 'react-router-dom'
import { SUPABASE_CLIENT } from '../../Services/Supabase'
import { useState } from 'react'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { Update } from '@mui/icons-material';
// import { updateEmail } from '../../function/Student/SignUp/Student';

function SignInGoogle() {

  const { state } = useLocation()
  const [mobileNo] = useState(state)
  const navigate = useNavigate()
  let emailData: any
  const handleClick = () => {
    navigate('/signup-from', { state: { mobileNo: mobileNo, clickvalue:'email' } })
  }

  return (
    <div className="bg-[url('../public/images/frontBg.png')] bg-cover  h-[100vh]  ">
      <div className='align-middle max-monitor:pt-80 max-desktop:pt-52  mx-auto '>
        {/* <div onClick={signInWithGoogle} className='w-[380px] mb-3 align-middle cursor-pointer items-center rounded flex m-auto text-2xl p-2 text-[#ffffff] bg-blue-600 bg-origin-content font-[roboto] font-normal max-mobile:w-[300px]'><img className='w-1/6 h-14 bg-[#fff] p-2  mr-6  ' src={googleLogo} alt='img' /> Sign in with Google</div> */}
       <div className='align-middle cursor-pointer items-center flex justify-center'>
        <GoogleOAuthProvider clientId="38118338823-e7so7jpostoc63gb5unbpvlg0kkhvg3m.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={async(credentialResponse: any) => {
              var decode = jwt_decode(credentialResponse.credential)
              emailData = decode
              navigate('/signup-from', { state: { emailData: emailData, mobileNo: mobileNo } })
            }}
            onError={() => {
            }}
            size='large'
            width='300px'
            theme='filled_blue'
          />
        </GoogleOAuthProvider>
        </div>
        <div className='text-2xl my-3 text-[#000000] font-[roboto] font-normal'>or</div>
        <div className='text-2xl max-mobile:text-[20px] cursor-pointer text-[#0048FF] font-[roboto] font-normal' onClick={() => handleClick()}>Sign in with Email</div>
      </div>
    </div>
  )
}

export default SignInGoogle