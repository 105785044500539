import React,{ useEffect, useState} from 'react'
import LeftNav from '../elements/leftNav'
import Header from '../elements/header'
import RightScreen from './rightScreen'
import { useLocation } from 'react-router-dom'
import Footer from '../elements/Footer'

function Profile() {
  const {state} = useLocation()
  const data = state
  return (
    <div>
        <Header />
        <div className='flex justify-between'>
            <LeftNav />
            <RightScreen data={data?.myState}/>
        </div>
<Footer/>

    </div>
  )
}

export default Profile