import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../elements/Footer'
import Header from '../elements/header'

function AllcoursePage( ) {
    const navigate = useNavigate()
    const {state} =useLocation()
    const [load,setLoad] = useState(true)
    const [courseDetails] = useState(state)
    const handletech = (props:any) => {
        navigate(`/technologies-course/${props.category_name.replace(/ /g,'-')}`, {state: props.id})
    }
    const [count, setCount] = useState(0)
    const handleLoad = () => {
        setLoad(false)
        let data_Lenght = courseDetails?.map((item: any) => { return item })
        setCount(data_Lenght.length)

    }

    useEffect(() => { 
        setCount(courseDetails.length)
    }
    )

    return (
        <>
        <Header />
        <p className=' text-left p-4 text-[30px] max-tablet:text-[30px] max-mobile:text-[22px] text-[#707070]  font-bold'> Explore All Courses </p>
        <div className='grid grid-cols-5 gap-0.5 h-4/6  max-laptop:grid-cols-3 max-mobile:grid-cols-2 mx-auto pl-10 max-mobile:pl-0'>
        {courseDetails?.slice(0, count).map((item: any, index:number) => {
            return<div onClick={() => handletech(item)} key={item.id} className='flex flex-col  cursor-pointer items-center justify-center m-4  w-[200px] h-[200px]  max-desktop:w-[180px] max-desktop:h-[180px] max-mobile:w-[40vw] max-mobile:h-[140px] shadow-3xl rounded-md bg-[#FFFFFF]' >
            <img className='flex mx-auto justify-center items-center w-[35px] h-[50px] max-mobile:w-[23vw]' src={item.thumbnail} alt='icon' />
            <p className=' text-center mt-3  w-3/4 text-[17px] max-tablet:text-[17px] max-mobile:text-[14px] text-[#000000] font-semibold'>{item.category_name}</p>
            <p className='text-[#4E4E4E] text-[17px] max-mobile:text-[14px]'>{item.no_of_courses} Courses</p>
        </div>
        })}
    </div>
    {load && count > 9 ?
    <button onClick={handleLoad} className="text-white text-center mt-5  w-44 bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900] font-medium rounded-md text-[20px] px-5 py-2.5 mb-2" > Load More </button>
: ''}
    <Footer/>

    </>

  )
}

export default AllcoursePage