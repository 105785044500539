import { errorNotifier } from "../../commonHelper"

import { TableNames } from "../../config/Tables"

import { FetchData, InsertData, UpdateData } from "../../crud"

import { CertificateInterface, ConcernOrFeedbackInterface, ResumeUploadInterface, UpdateStudentInterface } from "./interface";




export const progressByModuleCalculation = (courseData: any, trainingProgressByModule: any) => {


  let totalCompletedDuration = 0;
  let totalDuration = 0;
  courseData.videos = courseData.videos?.map((video: any) => {
    let progress = calculatePercentage(trainingProgressByModule[video.key] || 0, TimeFormatToSeconds(video.duration));
    totalDuration += TimeFormatToSeconds(video.duration);
    totalCompletedDuration += +trainingProgressByModule[video.key] || 0;
    return { ...video, progress }
  }) || [];
  return { course: courseData, overAllProgress: calculatePercentage(totalCompletedDuration, totalDuration) || 0 };
}

// export const progressByModuleCalculation = (courseData: any, trainingProgressByModule: any) => {

//   let totalCompletedDuration = 0;
//   let totalDuration = 0;
//   console.log(trainingProgressByModule,'asty')
//   const video_key = Object.keys(trainingProgressByModule);
//   console.log("video_key", video_key);
//   if (video_key?.length > 0) {
//     courseData.videos = courseData.videos?.map((video: any) => {
//       console.log("trainingProgressByModule[video.key] : ", trainingProgressByModule, trainingProgressByModule[video.key]);
//       let progress = calculatePercentage(trainingProgressByModule[video.key] || 0, TimeFormatToSeconds(video.duration));
//       console.log("free progress : ", progress);
//       totalDuration += TimeFormatToSeconds(video.duration);
//       totalCompletedDuration += +trainingProgressByModule[video.key] || 0;
//       return { ...video, progress }
//     }) || [];

//   } else {

//     return { course: courseData, overAllProgress: 0 };

//     // return {course: courseData, overAllProgress:calculatePercentage(totalCompletedDuration,totalDuration) || 0};

//   }

// }



const calculatePercentage = (current: number, total: number = 1) => {

  return Math.round((current / total) * 100);

}

const TimeFormatToSeconds = (timeString: string) => {

  const [sec, min, hr]: any = timeString.split(":").reverse();

  let seconds = 0;

  if (hr) {

    seconds += hr * 3600;

  }

  if (min) {

    seconds += min * 60;

  }

  if (sec) {

    seconds += +sec

  }

  return seconds;

}



export const getProfileDetails = async (studentUid: string) => {
  try {
    const { data: profile_details, error: profile_error } = await FetchData(TableNames.students, ["id","name", "email", "phoneNumber", "resume", "placement_need", "type"]).eq("uid", studentUid)

    if (profile_error) throw profile_error
    // console.log(profile_details, "profile_details")
    return profile_details[0];

  } catch (error) {

    return errorNotifier(error)

  }

}



export const updateProfileData = async (student_data: UpdateStudentInterface, studentUid: string) => {

  try {

    const { data, error } = await UpdateData(TableNames.students, student_data, { conditionKey: "uid", conditionValue: studentUid })

    if (error) throw error

    return true

  } catch (error) {

    return errorNotifier(error)

  }

}



export const getCourses = async (studentUid: string) => {
  try {
    const { data, error }: any = await FetchData(TableNames.trainings, ["id", "course:courses(id, course_name, type, thumbnail)", "batch_id"]).eq("student_id", studentUid)
    if (error) throw error
    // console.log(data)
    return data
  } catch (error) {
    return errorNotifier(error)
  }

}



// export const getCourseDetails = async (training_id: number | string, batch_id: number | string | null, studentUid: string) => {

//   try {
//     console.log(training_id, batch_id, studentUid, 'hhh')

//     if (batch_id !== null) {
//       const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["batch:batches(id, duration)", "course:courses(course_name, duration, thumbnail)", "payment_details", "total_fee", "duration"]).eq("id", training_id)
//       const { data, error }: any = await FetchData(TableNames.sessions, ["attendees", "batch_id", "duration"]).eq("batch_id", batch_id)
//       const sessions = data.filter((el: any) => (el.attendees.includes(studentUid)))
//       const total_session_time = sessions?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0);
//       const { data: TransactionsData, error: TransactionQueryError } = await FetchData(TableNames.transactions).eq("training_id", training_id);
//       if (TransactionQueryError) throw TransactionQueryError;

//       const payment = TransactionsData.reduce((acc: any, curr_val: any) => Number(acc) + Number(curr_val.paid_amount), 0)
//       console.log(payment, 'sa')
//       const payment_summary = {
//         course_name: training_data[0].course.course_name,
//         total_fee: training_data[0].total_fee,
//         paid_amount: (payment * 18) / 100 + payment,
//         balance_due: training_data[0].total_fee - ((payment * 18) / 100 + payment)
//       }
//       console.log(payment_summary, 'asd')

//       const courseData = training_data?.map((training: any) => ({ ...training, payment_details: TransactionsData, course_progress: calculatePercentage(total_session_time, training_data[0]?.duration) }))
//       if (error || training_error) throw error || training_error
//       return { courseData: courseData[0], payment_summary }

//     } else {
//       const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name, videos, thumbnail)", "progress_by_module", "branch:branches(branch_name)", "city:cities(city_name)"]).eq("id", training_id);
//       if (error) throw error
//       return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };
//     }
//   } catch (error) {
//     return errorNotifier(error)
//   }
// }

// export const getAllCertificates = async (studentUid: string) => {

//     try {

//         const { data, error } = await FetchData(TableNames.trainings, ["course:courses(course_name, id, type,duration, thumbnail, star_rating)", "student:students(name, email, phoneNumber, created_at)", "branch_id","date_of_completion", "branches(branch_name)", "city:cities(city_name)"]).eq("student_id", studentUid)



//         if (error) throw error



//         return data

//     } catch (error) {

//         return errorNotifier(error)

//     }

// }


export const getCourseDetails = async (training_id: number | string, batch_id: number | string | null, studentUid: string) => {

  try {
    const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["batch:batches(id, duration)", "course:courses(course_name, duration, thumbnail)", "payment_details", "total_fee", "duration"]).eq("id", training_id)
    if (batch_id !== null) {
      const { data, error }: any = await FetchData(TableNames.sessions, ["attendees", "batch_id", "duration"]).eq("batch_id", batch_id)
      const sessions = data.filter((el: any) => (el.attendees.includes(studentUid)))
      const total_session_time = sessions?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0);
      //  
      const { data: TransactionsData, error: TransactionQueryError } = await FetchData(TableNames.transactions).eq("training_id", training_id);
      if (TransactionQueryError) throw TransactionQueryError;
      const payment = TransactionsData.reduce((acc: any, curr_val: any) => Number(acc) + Number(curr_val.paid_amount), 0)
  
      const payment_summary = {

        course_name: training_data[0].course.course_name,

        total_fee: training_data[0].total_fee,

        paid_amount:payment,

        balance_due: training_data[0].total_fee - (payment)

      }

      //
      const courseData = training_data?.map((training: any) => (
        { ...training, payment_details: TransactionsData, course_progress: calculatePercentage(total_session_time, training_data[0]?.course.duration) }))
      
      if (error || training_error) throw error || training_error
      return { courseData: courseData[0], payment_summary }
    } else {
      const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name, videos, thumbnail)", "progress_by_module", "branch:branches(branch_name)", "city:cities(city_name)"]).eq("id", training_id);
      if (error) throw error
      const { data: TransactionsData, error: TransactionQueryError } = await FetchData(TableNames.transactions).eq("training_id", training_id);
      if (TransactionQueryError) throw TransactionQueryError;
      const payment = TransactionsData.reduce((acc: any, curr_val: any) => Number(acc) + Number(curr_val.paid_amount) , 0)

      const payment_summary = {
        course_name: training_data[0].course.course_name,
        total_fee: training_data[0].total_fee,
        paid_amount:  payment,
        balance_due: training_data[0].total_fee - payment
      }
      return { ...data[0], payment_summary: payment_summary, courseData: { payment_details: TransactionsData }, ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };
    }
  } catch (error) {
    return errorNotifier(error)
  }
}


export const getAllCertificates = async (studentUid: string) => {
  try {
    const { data, error } = await FetchData(TableNames.trainings, ["course:courses(course_name, id, type,duration, thumbnail, star_rating)", "student:students(name, email, phoneNumber, created_at)", "branch_id", "date_of_completion", "branches(branch_name)", "city:cities(city_name)"]).eq("student_id", studentUid)
    if (error) throw error
    return data
  } catch (error) {
    return errorNotifier(error)
  }
}

/*export const getAllCertificates = async (studentUid: string) => {

    try {

        const { data, error } = await FetchData(TableNames.student_certificate, ["certificate"]).eq("student_id", studentUid)

        if (error) throw error

        return data

    } catch (error) {

        return errorNotifier(error)

    }

}*/



export const getCertificateTemplate = async () => {
  try {
    const { data, error } = await FetchData(TableNames.certificates, ["certificate"])
    if (error) throw error
    return data
  } catch (error) {
    return errorNotifier(error)
  }
}


export const requestCertificate = async (studentUid: string, training_id: number | string, batch_id: number | string | null) => {
  try {
    if (batch_id !== null) {
      const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["batch:batches(id, duration, branches:branches(branch_name), city:cities(city_name))", "course:courses(course_name, duration, thumbnail)", "payment_details", "total_fee", "date_of_completion", "duration"]).eq("id", training_id)
      const { data: student_data, error: student_error } = await FetchData(TableNames.students).eq("uid", studentUid)
      const { data, error }: any = await FetchData(TableNames.sessions, ["attendees", "batch_id", "duration"]).eq("batch_id", batch_id)
      const sessions = data.filter((el: any) => (el.attendees.includes(studentUid)))
      const total_session_time = sessions?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
      const courseData = training_data?.map((training: any) => ({ ...training, course_progress: calculatePercentage(total_session_time, training_data[0]?.duration) }))
        let date_of_completion = new Date()
        await UpdateData(TableNames.trainings, { date_of_completion: date_of_completion }, { conditionKey: "id", conditionValue: training_id })
      if (error || training_error || student_error) throw error || training_error || student_error
      return { courseData: courseData[0], student_data }
    } else {
      const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "city:cities(city_name)", "course:course_id(id, course_name, duration, videos, thumbnail)", "progress_by_module", "date_of_completion", "branch:branches(branch_name))"]).eq("id", training_id);
      if (error) throw error
      return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };
    }
  } catch (error) {
    return errorNotifier(error)
  }
}



export const submitCertificateRequest = async (certificate_data: CertificateInterface) => {
  try {

    const { data: certificate, error: certificate_error } = await FetchData(TableNames.student_certificate).eq("course_id", certificate_data.course_id).eq("student_id", certificate_data.student_id)



    if (certificate && certificate.length > 0) {

      return { message: "Certificate already generated", status: false }

    }

    else {

      const { data, error } = await InsertData(TableNames.student_certificate, certificate_data)

      if (error) throw error

      return { data, status: true }

    }

  } catch (error) {

    return errorNotifier(error)

  }

}





export const uploadResume = async (studentUid: string, resumeData: ResumeUploadInterface) => {

  try {

    const { data, error } = await UpdateData(TableNames.students, resumeData, { conditionKey: "uid", conditionValue: studentUid })

    if (error) throw error

    return true

  } catch (error) {

    return errorNotifier(error)

  }

}



export const getPlacementStatus = async (studentUid: string) => {

  try {

    const { data, error } = await FetchData(TableNames.students, ["placement_need"]).eq("uid", studentUid)

    if (error) throw error

    return data[0]

  } catch (error) {

    return errorNotifier(error)

  }

}



export const submitConcernOrFeedback = async (concernData: ConcernOrFeedbackInterface) => {

  try {

    const { data, error } = await InsertData(TableNames.student_concern_feedback, concernData)

    if (error) throw error

    return data

  } catch (error) {

    return errorNotifier(error)

  }

}



export const getAllFeedbacks = async (studentId: string) => {

  try {

    // const { data, error } = await FetchData(TableNames.feedbacks, ["*", "training:trainings(course:courses(course_name))"]).eq("student_id", studentId).neq("response", "Excellent").neq("response", "Good").neq("response", "Absent").order("id", { ascending: false })

    const { data, error } = await FetchData(TableNames.feedbacks, ["*", "training:trainings(course:courses(course_name))"]).eq("student_id", studentId).order("id", { ascending: false })

    if (error) throw error

    return data

  } catch (error) {

    return errorNotifier(error)

  }

}



export const getStudentConcerns = async (studentId: string) => {

  try {

    const { data, error } = await FetchData(TableNames.student_concern_feedback, ["*", "training:trainings(course:courses(course_name))"]).eq("student_id", studentId).order("id", { ascending: false })

    if (error) throw error

    return data

  } catch (error) {

    return errorNotifier(error)

  }

}