// import React, { useEffect, useState } from 'react'
// import { Navigate, useLocation, useNavigate } from 'react-router-dom'
// import Back from '../../../images/BackIcon.png'
// import { TrainerTransactionTableData, getLoggedUserDetails, getTrainerIdBasedStudents } from '../Functions/TrainerFunctions';
// import eyeIcon from '../../../images/viewIcon.png'
// import searchIcon from '../../../images/searchIconPng.png'
// import { PulseLoader } from 'react-spinners';
// import { DateRangeFilter } from '../components/DateRangeFilter';

// export default function TrainerTransaction() {
//     const [transactionFlag, setTransactionFlag] = useState(false)
//     const [transactionList, setTransaction] = useState([]) as any
//     const [filteredTransactions, setfilteredtransactions] = useState([]) as any
//     const [dateRange, setDateRange] = useState<any>({
//         startDate: new Date(),
//         endDate: new Date()
//     });
//     const [currentTransaction, setCurrentTransaction] = useState([]) as any
//     const [isLoading, setLoading] = useState(false)
//     const navigate = useNavigate();
//     const UID: any = localStorage.getItem('uid')
//     const [search, setSearch] = useState('')
//     // const [status,setStatus] = useState('')
//     // const [schedule,setschedule] = useState('')

//     const BackBtn = () => {
//         navigate('/trainer/course-progress')
//     }
//     const getData = async () => {
//         setLoading(true)
//         const userDetails = await getLoggedUserDetails(UID)
//         const response = await TrainerTransactionTableData(userDetails)
//         if (response) {
//             setTransaction(response)
//             setfilteredtransactions(response)
//         }
//         setLoading(false)
//     }

//     const getCurrentTransaction = async (item: any) => {
//         setLoading(true)
//         const res = await getTrainerIdBasedStudents(item.id)
//         if (res && Array.isArray(res)) {
//             setCurrentTransaction(res)
//         }
//         setLoading(false)
//     }


//     const handleSearch = (element: any) => {
//         const value = element.target.value;
//         setSearch(value);
//         handleChange('search', value);
//     };

//     const handleDateChange = (startDate: string, endDate: string, apikey: string) => {
//         const dateRange = { startDate, endDate };
//         setDateRange(dateRange);
//         handleChange('date', dateRange);
//     };

//     const isValidDate = (date: Date) => {
//         return date instanceof Date && !isNaN(date.getTime());
//     };
//     const handleChange = (key: string, value: any) => {
//         const newSearch = key === 'search' ? value : search;
//         const newDateRange = key === 'date' ? value : dateRange;

//         let filteredData = transactionList;

//         if (newSearch) {
//             filteredData = filteredData.filter((item: any) =>{
//                return item.batch_code.toLowerCase().includes(newSearch.toLowerCase())}
//             );
//         }

//         if (newDateRange.startDate && newDateRange.endDate) {
//             filteredData = filteredData.filter((item: any) => {
//                 const itemDate = new Date(item.update_at);
//                 return isValidDate(itemDate) && itemDate >= new Date(newDateRange.startDate) && itemDate < new Date(newDateRange.endDate);
//             });
//         }

//         setfilteredtransactions(filteredData);
//     };


//     const currencyFormate = (price:any) => {
//         price = parseFloat(price)
//         return  price.toLocaleString("en-IN", {
//           minimumFractionDigits: 0,
//           maximunFractionDigits: 2,
//         })
//     }

//     useEffect(() => {
//         getData()
//     }, [])

//     if (isLoading) {
//         return (
//             <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
//                 <PulseLoader color="#065FEF" size={8} />
//             </div>
//         );
//     }

//     return (
//         <>
//             {transactionFlag ?

//                 <div className='bg-[#036AD1] pb-2 '>

//                     <div className='flex justify-end py-2 px-3'>
//                         <img src={Back} alt='Back' className='w-[25px]' onClick={() => {
//                             setTransactionFlag(false)
//                             setCurrentTransaction([])
//                         }} />
//                     </div>

//                     <div className='bg-[#f2f9fc] flex justify-center flex-col pb-2 rounded-md m-2 min-h-[88vh] '>
//                         <div style={{ wordBreak: 'break-word' }} className='bg-white rounded-lg py-2 mx-2'>

//                             {currentTransaction.length ? currentTransaction?.map((transaction: any, index: number) => {
//                                 return <div className='flex bg-white flex-wrap text-[14px] text-left font-medium '>
//                                     <div className={`${index % 2 == 0 ? 'bg-[#eef3f6]' : 'bg-[#f9fcfd]'} basis-1/2  py-2 text-bold`}> {transaction?.student?.name}</div>
//                                     <div className={`${index % 2 == 0 ? 'bg-[#eef3f6]' : 'bg-[#f9fcfd]'} basis-1/2  py-2 text-bold`}> Rs.{currencyFormate(transaction?.existing_trainer_payment)}</div>
//                                 </div>
//                             }) : 'No Data Available'}

//                         </div>
//                     </div>
//                 </div>

//                 :
//                 <div className='bg-[#036AD1] pb-2 h-full'>

//                     <div className='flex justify-end py-2 px-3'>
//                         <img src={Back} alt='Back' className='w-[25px]' onClick={() => BackBtn()} />
//                     </div>

//                     <div className='bg-[#f2f9fc] pb-2 rounded-md m-2 h-fulls'>
//                         <div className=''>
//                             <p className='text-start text-[#686868] font-medium px-4 pt-2 pb-1'>Trainer Transaction</p>
//                             <hr className='bg-[#686868] h-[1px]'></hr>
//                             <div className=' flex justify-end my-1 bg-white py-2'>
//                                 {/* <div className='basis-1/2 flex rounded-[50%] mx-2 py-[3px]'>
//                                     <input onChange={(element) => handleSearch(element)} type='text' placeholder='Search' className='w-[60%] rounded-tl-full rounded-bl-full h-[30px] bg-[#f9f9f9] pl-[10px] ' />
//                                     <span className='flex bg-[#276ab4] items-center justify-center rounded-tr-full rounded-br-full  w-[18%]'><img className=' w-[25px]  px-[2px]' src={searchIcon} alt='search' /></span>
//                                 </div> */}
//                                 <div className='basis-1/2'>
//                                     <DateRangeFilter
//                                         filterJSON={{ api_key: 'date', name: 'Date' }}
//                                         handleChange={handleDateChange}
//                                     />
//                                 </div>
//                             </div>

//                             <div style={{ wordBreak: 'break-word' }} className=' mt-3'>
//                                 {filteredTransactions.map((transaction: any) => {
//                                     return <div className='flex bg-white flex-wrap py-3 rounded-lg text-[14px] text-left font-medium shadow-lg mx-2 my-6 border border-red-800'>
//                                         <div className='basis-1/2 bg-[#f9fcfd]  py-2 text-bold'>{transaction.update_at ? transaction.update_at : '-'}</div>
//                                         <div className='basis-1/2 bg-[#f9fcfd] text-center  py-2'>{transaction.batch_code}</div>
//                                         <div className='basis-1/2 bg-[#eef3f6] text-center font-med py-2'>Rs. {currencyFormate(transaction.paid_amount)}</div>
//                                         <div onClick={() => {
//                                             getCurrentTransaction(transaction)
//                                             setTransactionFlag(true)
//                                         }} className='basis-1/2 bg-[#eef3f6] text-center justify-center items-center flex py-2'>View More <img className='w-8 ml-1' src={eyeIcon} alt="" /></div>
//                                     </div>
//                                 })}

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             }
//         </>
//     )
// }


import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Back from '../../../images/BackIcon.png'
import { TrainerTransactionTableData, getLoggedUserDetails, getTrainerIdBasedStudents } from '../Functions/TrainerFunctions';
import eyeIcon from '../../../images/viewIcon.png'
import searchIcon from '../../../images/searchIconPng.png'
import { PulseLoader } from 'react-spinners';
import { DateRangeFilter } from '../components/DateRangeFilter';

export default function TrainerTransaction() {
    const [transactionFlag, setTransactionFlag] = useState(false)
    const [transactionList, setTransaction] = useState([]) as any
    const [filteredTransactions, setfilteredtransactions] = useState([]) as any
    const [dateRange, setDateRange] = useState<any>({
        startDate: new Date(),
        endDate: new Date()
    });
    const [currentTransaction, setCurrentTransaction] = useState([]) as any
    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate();
    const UID: any = localStorage.getItem('uid')
    const [search, setSearch] = useState('')

    const BackBtn = () => {
        navigate('/trainer/course-progress')
    }
    const getData = async () => {
        setLoading(true)
        const userDetails = await getLoggedUserDetails(UID)
        const response = await TrainerTransactionTableData(userDetails)
        if (response) {
            setTransaction(response)
            setfilteredtransactions(response)
        }
        setLoading(false)
    }

    const getCurrentTransaction = async (item: any) => {
        setLoading(true)
        const res = await getTrainerIdBasedStudents(item.id)
        if (res && Array.isArray(res)) {
            setCurrentTransaction(res)
        }
        setLoading(false)
    }

    const handleSearch = (element: any) => {
        const value = element.target.value;
        setSearch(value);
        handleChange('search', value);
    };

    const handleDateChange = (startDate: string, endDate: string, apikey: string) => {
        const dateRange = { startDate, endDate };
        setDateRange(dateRange);
        handleChange('date', dateRange);
    };

    const isValidDate = (date: Date) => {
        return date instanceof Date && !isNaN(date.getTime());
    };

    const handleChange = (key: string, value: any) => {
        const newSearch = key === 'search' ? value : search;
        const newDateRange = key === 'date' ? value : dateRange;

        let filteredData = transactionList;

        if (newSearch) {
            filteredData = filteredData.filter((item: any) => 
                item.batch_code.toLowerCase().includes(newSearch.toLowerCase())
            );
        }

        if (newDateRange.startDate && newDateRange.endDate) {
            filteredData = filteredData.filter((item: any) => {
                const itemDate = new Date(item.update_at);
                return isValidDate(itemDate) && itemDate >= new Date(newDateRange.startDate) && itemDate < new Date(newDateRange.endDate);
            });
        }

        setfilteredtransactions(filteredData);
    };

    const currencyFormate = (price: any) => {
        price = parseFloat(price)
        return price.toLocaleString("en-IN", {
            minimumFractionDigits: 0,
            maximunFractionDigits: 2,
        })
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) {
        return (
            <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
                <PulseLoader color="#065FEF" size={8} />
            </div>
        );
    }

    return (
        <>
            {transactionFlag ?
                <div className='bg-[#036AD1] pb-2 '>
                    <div className='flex justify-end py-2 px-3'>
                        <img src={Back} alt='Back' className='w-[25px]' onClick={() => {
                            setTransactionFlag(false)
                            setCurrentTransaction([])
                        }} />
                    </div>

                    <div className='bg-[#f2f9fc] flex justify-center flex-col pb-2 rounded-md m-2 min-h-[88vh] '>
                        <div style={{ wordBreak: 'break-word' }} className='bg-white rounded-lg py-2 mx-2'>
                            {currentTransaction.length ? currentTransaction?.map((transaction: any, index: number) => {
                                return <div className='flex bg-white flex-wrap text-[14px] text-left font-medium '>
                                    <div className={`${index % 2 == 0 ? 'bg-[#eef3f6]' : 'bg-[#f9fcfd]'} basis-1/2  py-2 text-bold`}> {transaction?.student?.name}</div>
                                    <div className={`${index % 2 == 0 ? 'bg-[#eef3f6]' : 'bg-[#f9fcfd]'} basis-1/2  py-2 text-bold`}> Rs.{currencyFormate(transaction?.existing_trainer_payment)}</div>
                                </div>
                            }) : 'No Data Available'}
                        </div>
                    </div>
                </div>
                :
                <div className='bg-[#036AD1] pb-2 h-full'>
                    <div className='flex justify-end py-2 px-3'>
                        <img src={Back} alt='Back' className='w-[25px]' onClick={() => BackBtn()} />
                    </div>

                    <div className='bg-[#f2f9fc] pb-2 rounded-md m-2 h-full'>
                        <div className=''>
                            <p className='text-start text-[#686868] font-medium px-4 pt-2 pb-1'>Trainer Transaction</p>
                            <hr className='bg-[#686868] h-[1px]'></hr>
                            <div className='flex justify-end my-1 bg-white py-2'>
                                <div className='basis-1/2'>
                                    <DateRangeFilter
                                        filterJSON={{ api_key: 'date', name: 'Date' }}
                                        handleChange={handleDateChange}
                                    />
                                </div>
                            </div>

                            <div style={{ wordBreak: 'break-word' }} className='mt-3'>
                                {filteredTransactions.map((transaction: any) => {
                                    return <div className='flex bg-white flex-wrap py-3 rounded-lg text-[14px] text-left font-medium shadow-lg mx-2 my-6 p-2'>
                                        <div className='basis-1/2 bg-[#f9fcfd] py-2 text-bold'>{transaction.update_at ? transaction.update_at : '-'}</div>
                                        <div className='basis-1/2 bg-[#f9fcfd] py-2'>{transaction.batch_code}</div>
                                        <div className='basis-1/2 bg-[#eef3f6] py-2'>Rs. {currencyFormate(transaction.paid_amount)}</div>
                                        <div onClick={() => {
                                            getCurrentTransaction(transaction)
                                            setTransactionFlag(true)
                                        }} className='basis-1/2 bg-[#eef3f6] flex py-2 items-center'>
                                            View More <img className='w-8 ml-1' src={eyeIcon} alt="" />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
