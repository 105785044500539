import React, { useEffect, useRef, useState, CSSProperties } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../elements/header'
import enrolled from '../../images/enrolled.png'
import time from '../../images/time.png'
import callWhite from '../../images/callWhite.svg'
import download from '../../images/download.svg'
import studTestimol from '../../images/studTestimol.svg'
import certificatesImg from '../../images/certificatesImg.svg'
import hiringCompany from '../../images/hiringCompany.svg'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { courseEnquiry, getAllHiringCompanies, getAllTestimonials, getContactInfo, getPreloadData, getStudentByUid, getTrendingCourses } from '../../function/Student/Courses/Courses'
import rightArrow from '../../images/rightArrow.svg'
import Graterthansymbol from '../../images/Graterthansymbol.svg'
import enroleImg from '../../images/enroleImg.svg'
import treandIcon from '../../images/treandIcon.svg'
import MailIcon from '../../images/MailIcon.svg'
import PhoneIcon from '../../images/PhoneIcon.svg'
import SearchIcon1 from '../../images/SearchIcon1.svg'
import studtestingIcon from '../../images/studtestingIcon.svg'
import faqicon from '../../images/faqIcon.svg'
import { StarRating } from '../elements/progressBar'
import Select from "react-select";
import './course.css'
import certification from '../../images/certification.png'
import closeicon from '../../images/close-red-icon.svg'
import stopClock from '../../images/stopClock.svg'
// import { uuid } from '../../redux/useReducer'
import ReactPlayer from 'react-player/youtube'
import ClipLoader from "react-spinners/ClipLoader";
import VideoComp from '../elements/videoComp'
import { getCertificate } from '../../function/Student/Certificate/Certificate'
import Footer from '../elements/Footer'



export default function CourseDetialed() {
    const scrollRef: any = useRef();
    const scrollRef1: any = useRef();
    const { state } = useLocation()
    const uuid:any = localStorage.getItem('uid')
    const [courseDetails, setCourseDetails] = useState(state)
    const [trendcourseData, setTrendCourseData] = useState({}) as any
    const [testimonials, setTestimonials] = useState({}) as any
    const [certificate, setCertificate] = useState({}) as any

    const [companylogo, setCompanylogo] = useState({}) as any
    const [coursestate, setCourseState] = useState(false)
    const navigate = useNavigate()
    const [popupstate, setPopupState] = useState(true)
    const [popupsubmit, setPopupSubmit] = useState(true)
    const [studentDetail, setStudentDeatail] = useState() as any
    const [allbranch, setAllbranch] = useState({}) as any
    const [allcity, setAllcity] = useState({}) as any
    const [title, setTitle] = useState('') as any
    const [curntBranchId, setCurntBranchId] = useState('')
    let [loading, setLoading] = useState(true);


    const data = async () => {
        const course_data = await getTrendingCourses("Paid", uuid)
        setTrendCourseData(course_data)
        const companyData = await getAllHiringCompanies()
        setCompanylogo(companyData)
        const testimonialsData = await getAllTestimonials()
        setTestimonials(testimonialsData)
        const certificateData = await getCertificate()
        setCertificate(certificateData)
        setCourseState(true)
    }

    useEffect(() => {

        data()

    }, [])

    document.documentElement.scrollTo({
        top: 0,
        left: 0
    })


    const handleCourse = () => {
        navigate('/trending-course', { state: trendcourseData })
    }
    const handletrendCourse = (props: any) => {
        navigate(`/course/${props.course_name.replace(/ /g, '-').toLowerCase()}`, { state: props })
        setCourseDetails(props)
        // navigate('/CourseDetialed', { state: props })
    }
    const convertToK = (number: number) => {
        if (number >= 1000) {
            const suffixes = ["", "k", "M", "B", "T"];
            const suffixIndex = Math.floor(Math.log10(number) / 3);
            const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
            return abbreviatedNumber + suffixes[suffixIndex];
        }
        return number.toString();
    }

    const handleDownload = (props: any) => {
        const url = props.url;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'
        link.download = 'image.jpg';
        link.click();
    }
    const handleClose = () => {
        setPopupState(true)
    }

    const [phone, setPhone] = useState({}) as any
    const handleCall = async () => {
        const contact = await getContactInfo()
        setPhone(contact)
    }

    const handleEnroll = async (props: any) => {
        // setLoading(true)
        const studentData = await getStudentByUid(uuid)
        setStudentDeatail(studentData)
        const cityBranch = await getPreloadData()
        setAllbranch(cityBranch)
        setAllcity(cityBranch)
        let title_str = ''
        studentData?.name?.split(' ').forEach((element: any) => {
            title_str += element[0]?.toUpperCase()
        })
        setTitle(title_str)
        setPopupState(false)
        setPopupSubmit(true)

  
        // setLoading(false)
   
    }

    const cities = allcity
    const filterAllCities = (rawInput: any) => {
        setAllbranch(rawInput?.branches)
        return cities.filter((data: any) => {
            return data.value == rawInput.value;
        });
    };
    const [slectOption, setSlectOption] = useState(true)
    const filterAllbranch = (e: any) => {
        setSlectOption(false)
        setCurntBranchId(e?.id)
    }
    const [enquired, setEnquired] = useState({}) as any
    const handleSubmit = async () => {
        const enquiry = await courseEnquiry(uuid, courseDetails?.id, curntBranchId)
        setEnquired(enquiry)
        enquiryStatus(enquiry)
        const course_data = await getTrendingCourses("Paid", uuid)

        let current = course_data.filter((el:any) => el.id == courseDetails.id)

        if(current.length){
            setCourseDetails(current[0])
        }
    }
    const enquiryStatus = (enquiry: any) => {
        if (enquiry?.status) {
            setPopupSubmit(false)
        }
        else {
            alert(`${enquiry?.message}`)
            setPopupState(true)
        }
        setSlectOption(true)

    }

    const [isPlay, setisPlay] = useState(false)


    const playVideo = () => {
        setisPlay(true)
    }


    let length = 15

    return (
        <div>
            <Header />
            {coursestate ?
                <div>
                    {popupstate ?
                        <div>
                            <div className='flex w-full  mx-auto px-20 max-laptop:px-14 items-center py-16 justify-between bg-[#036AD1] text-left max-mobile:block max-mobile:px-8'>
                                <div className='w-3/5 max-mobile:w-full text-[#fff]'>
                                    <p className='text-[30px] max-mobile:text-[18px] font-bold '>{courseDetails.course_name}</p>
                                    <div className='flex py-2 items-center '>
                                        <p className='text-[25px] max-mobile:text-[16px]'>{courseDetails.star_rating}</p>
                                        <div className='ml-0 text-left px-4 max-mobile:px-0'>
                                            <StarRating rating=
                                            // {courseDetails.star_rating} 
                                            {5}
                                            />
                                        </div>
                                        <p className='text-[14px] text-[#E9E9E9] '>({courseDetails.ratings} Ratings)</p>
                                    </div>
                                    <div className='flex py-2 w-10/12 max-mobile:w-[88vw] max-tablet:w-10/12 justify-between items-center'>
                                        <div className='flex items-center '>
                                            <img src={enrolled} className='w-[16px] h-[16px] object-contain' alt='icon' />
                                            <p className='text-[19px] max-mobile:text-[14px] pl-2'>{convertToK(courseDetails.students_enrolled)} Enrolled</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <img src={time} className='w-[16px] h-[16px]' alt='icon' />
                                            <p className='text-[19px] max-mobile:text-[14px] text-[#E9E9E9] pl-2'>{courseDetails.duration} hr Duration</p>
                                        </div>
                                    </div>
                                    <p className='text-[#E9E9E9] text-[19px] pt-2 max-laptop:text-[14px] max-mobile:text-[14px]'>{courseDetails.description}</p>
                                    {courseDetails.is_enquired ? <div className='flex pt-8 max-mobile:justify-center max-mobile:w-[75vw]'>
                                        <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]  p-2 px-8 rounded-md shadow-3xl' >ENROLLED</button>
                                    </div> : <div className='flex pt-8 max-mobile:w-[85vw] max-mobile:justify-center'>
                                        <a  > <button className='bg-[#00B30C] p-2 mr-4 flex px-8 max-mobile:px-6 rounded-md shadow-3xl max-mobile:text-[12px]' onClick={() => handleCall()}><img src={callWhite} alt='callIcon' className='w-[20px] h-[23px] mr-2' />Call Us</button> </a>
                                        <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]  p-2 px-8 max-mobile:px-6 rounded-md shadow-3xl max-mobile:text-[12px]' onClick={() => handleEnroll(courseDetails)}>ENROLL NOW</button>
                                    </div>}
                                </div>
                                <div className=' w-2/5 flex max-mobile:w-[250px]  flex-col items-center mx-auto ' >
                                    <img src={courseDetails?.thumbnail.url} className='w-full rounded-md  max-mobile:mt-8 aspect-[5/3] max-w-[500px]' alt='photoShop' />
                                    <button className='bg-[#E95900] text-[#FFFFFF] text-[20px] max-mobile:text-[16px] p-2 w-full max-w-[500px] flex rounded-md my-1 justify-center items-center text-center mx-auto' onClick={() => handleDownload(courseDetails?.syllabus)}><img src={download} alt='download' className='w-[20px] h-[23px]  mr-2' />Download Syllabus</button>
                                </div>
                            </div>

                            {courseDetails?.course_feature.length >= 3 ? <div className='bg-[#fff] mx-auto w-8/12 max-mobile:flex-wrap max-mobile:flex  -mt-10 shadow-3xl mb-5 rounded-md grid grid-cols-3 gap-5 py-10 px-5 max-laptop:w-11/12'>
                                {courseDetails?.course_feature?.map((item: any) => {
                                    return <div className='shadow-3xl p-7 max-mobile:mx-auto max-mobile:w-[35vw] max-mobile:justify-center max-laptop:p-3 rounded-md flex items-center  max-mobile:mb-2 '>
                                        <div className='flex max-mobile:block max-mobile:mx-auto items-center'>
            
                                            <p className='max-mobile:flex max-mobile:justify-center max-mobile:mb-3'>  
                                            <img className='w-[40px] h-[40px]' src={item.icon} alt='icon' />
                                            </p>
                                            <p className='text-left max-mobile:text-[14px] max-mobile:text-center max-mobile:break-words max-mobile:ml-0 ml-4'>{item.feature} </p>
                                        </div>
                                    </div>
                                })}
                            </div> : <div className='bg-[#fff] mx-auto w-8/12 -mt-10 shadow-3xl mb-5 rounded-md  py-10 px-5 max-laptop:w-11/12 flex items-center  max-mobile:block'>
                                {courseDetails?.course_feature?.map((item: any) => {
                                    return <div className='shadow-3xl p-7 max-mobile:w-[70%] max-laptop:p-3 rounded-md flex items-center mx-auto  max-mobile:mb-2 w-[40%]'>
                                        <div className='flex max-mobile:block items-center'>
                                            <img className='w-[40px] h-[40px]' src={item.icon} alt='icon' />
                                            <p className='text-left ml-4'>{item.feature}</p>
                                        </div>
                                    </div>
                                })}
                            </div>}


                            <div className='bg-[#F3F7FF] p-6  max-mobile:p-2'>
                                <p className='mt-5 flex items-center font-bold text-left ml-10 text-[30px] text-[#000000]  max-mobile:text-[22px] max-mobile:ml-5'><img className=' w-[30px] h-[30px] mr-4' src={studtestingIcon} alt='icon' /> Student Testimonials</p>
                                <div className="flex items-center px-5 mx-5 justify-between space-x-5  max-mobile:space-x-1 max-mobile:px-0">
                                    <img
                                        src={rightArrow}
                                        alt="ScrollY"
                                        className="rotate-180 cursor-pointer max-mobile:hidden"
                                        onClick={() => (scrollRef1.current.scrollLeft -= 800)}
                                    />
                                    <div className={`mx-auto flex space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth `} ref={scrollRef1}>
                                        {testimonials?.map((item: any) => {
                                            return <div className={`${item?.type === "Upload Video" ?'py-8':'py-12'} bg-[#F3F7FF]`}>
                                                {item?.type === "Upload Video" ?
                                                        <VideoComp thumbnailUrl={item} />
                                                    :
                                                    <ReactPlayer className='max-desktop:!w-[350px] max-desktop:!h-[250px] max-tablet:h-[200px] max-mobile:!h-[33vh] max-mobile:!w-[75vw]' url={item?.external_link} poster={item?.thumbnail?.url} />
                                                
                                                }
                                            </div>
                                        })}
                                    </div>
                                    <img
                                        src={rightArrow}
                                        alt="ScrollY"
                                        className=" cursor-pointer max-mobile:hidden"
                                        onClick={() => (scrollRef1.current.scrollLeft += 800)}
                                    />
                                </div>
                            </div>
                            <div className='p-7 px-16 max-mobile:px-7'>
                                <p className='mt-5 flex items-center font-bold text-left ml-0 text-[30px] text-[#000000]  max-mobile:text-[20px]'><img className=' w-[30px] h-[30px] mr-4' src={hiringCompany} alt='icon' /> Hiring Companies</p>
                                <div className='grid grid-cols-6  max-mobile:grid-cols-3'>
                                    {companylogo?.map((item: any) => {
                                        return <div className='p-5'>
                                            <img className='w-[200px] h-[50px] max-mobile:h-[30px] max-mobile:w-[50px]' src={item.company_logo} alt='amazon' />
                                        </div>
                                    })}

                                </div>
                                <p className='w-11/12 mx-auto py-6 text-[22px] max-mobile:text-[20px] '>and <span className='text-[#036AD1]'> 1500+ Companies</span></p>
                            </div>

                            <div className="flex h-[500px] max-mobile:h-auto justify-between items-center bg-[url('../public/images/certificateBg.png')] bg-cover bg-no-repeat text-left px-12 max-laptop:bg-auto max-mobile:px-5 max-mobile:block max-mobile:bg-[#036AD1] max-mobile:pb-8">
                                <div className='w-[80%] pl-5 py-16 max-desktop:py-10 max-monitor:py-16  max-laptop:py-10'>
                                    <div className='flex text-[#fff]  max-mobile:w-[100%]'>
                                        <img src={certificatesImg} alt='certificate' className='w-[19px] h-[34px]' />
                                        <p className='pl-2  text-[20px]  max-mobile:w-[70%]'>Certificate</p>
                                    </div>
                                    <p className='text-[#fff] w-7/12 pt-4 text-[14px]  max-mobile:w-[84vw]'>{courseDetails.certificate_description}</p>
                                    {courseDetails.is_enquired ? <div className='flex pt-8 max-mobile:w-[75vw]'>
                                        <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]  p-2 px-8 rounded-md shadow-3xl text-[#fff]  ' >ENROLLED</button>
                                    </div> : <div className='flex pt-8 text-[#fff] max-mobile:w-[85vw] max-mobile:justify-center '>
                                        <a  > <button className='bg-[#00B30C] p-2 mr-4 flex px-8 rounded-md max-mobile:px-6 shadow-3xl max-mobile:text-[14px]' onClick={() => handleCall()}><img src={callWhite} alt='callIcon' className='w-[20px] h-[23px] mr-2' />Call Us</button> </a>
                                        <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801] max-mobile:px-6  p-2 px-8 rounded-md shadow-3xl max-mobile:text-[14px]' onClick={() => handleEnroll(courseDetails)}>ENROLL NOW</button>
                                    </div>}
                                </div>
                                <div className='-mt-12 max-mobile:mt-[0] w-[40%]'>
                                    <img className='w-[284px] h-[388px]  max-mobile:h-[150px] max-mobile:w-[200px] max-mobile:object-cover max-mobile:ml-20  ' src={certificate?.certificate} alt='certificate' />
                                </div>
                            </div>

                            <div className='mx-auto p-4 font-bold'>
                                <p className='items-center  text-center text-[30px] max-mobile:text-[18px] text-[#000000]'><img className='w-[30px] h-[30px] mr-4 inline ' src={faqicon} alt='icon' /> Frequently Asked Questions</p>
                                <div className='w-7/12 max-tablet:w-11/12 mx-auto accordionFont p-5'>
                                    {courseDetails?.faqs?.map((item: any) => {
                                        return <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header" className='bg-[#FBFBFB]'>
                                                <Typography className='font-bold text-[#000000]'>{item.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className='bg-[#F1F1F1] text-left'>
                                                <Typography className='font-bold text-[#000000]'>
                                                    {item.description}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    })}
                                </div>
                            </div>
                            <div className='bg-[#F3F7FF] p-6 max-mobile:p-3 '>
                                <p className='mt-5 flex items-center font-bold text-left ml-6 text-[30px] max-mobile:text-[22px] text-[#000000]'><img className=' w-[30px] h-[30px] mr-4' src={treandIcon} alt='icon' /> Trending Courses</p>
                                <div className="flex items-center px-5 mx-5 max-mobile:mx-0 max-mobile:mt-4 max-mobile:px-2 justify-between space-x-5 ">
                                    <img
                                        src={rightArrow}
                                        alt="ScrollY"
                                        className="rotate-180 cursor-pointer max-mobile:hidden"
                                        onClick={() => (scrollRef.current.scrollLeft -= 1200)}
                                    />
                                    {coursestate ? <div className={`mx-auto flex space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth  `} ref={scrollRef}>
                                        {trendcourseData?.slice(0, 5).map((item: any, index: any) => {
                                            return <div className=' cursor-pointer' onClick={() => handletrendCourse(item)}>
                                                <div className="  items-center  w-[300px] h-[280px] max-tablet:w-[300px] max-mobile:mb-8 bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[260px]">
                                                    <img className='w-full h-[150px]' src={item.thumbnail.url} alt='course thumbnil' />
                                                    <p className="px-4 pt-3 border-t-2 text-left text-[21px] max-mobile:text-[18px] font-medium text-[#707070] rounded-t-3xl">{ item.course_name?.length > 15 ? item.course_name?.substring(0, length) +'...' : item.course_name }</p>
                                                    <div className='ml-0 my-2 text-left px-4 flex items-center'>
                                                        <p className='pr-3 font-bold text-[12px] text-[#000000]'> {item.star_rating}</p>
                                                        <StarRating rating={5}
                                                        // {item.star_rating} 
                                                        />
                                                    </div>
                                                    <div className='flex justify-between p-3 '>
                                                        <p className=' text-[#007AFF] text-[14px] flex'>
                                                            <img className='w-[16px] h-[16px] mr-1' src={enroleImg} alt='enroleicon' /> {convertToK(item.students_enrolled)} Enrolled</p>
                                                        <p className=' text-[#007AFF] text-[14px] mr-3'>{item.duration} Hrs</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        <div onClick={handleCourse} className=' flex cursor-pointer  items-center'>
                                            <p className='text-[24px] max-mobile:text-[18px] text-[#036AD1]  my-5 bg-[#ffffff] rounded-full shadow-3xl h-[130px] w-[130px] max-mobile:h-[110px] max-mobile:w-[110px] items-center text-center flex p-4 mx-20'> View All<img className='pl-1' src={Graterthansymbol} alt='icon' /> </p>
                                        </div>
                                    </div>
                                        : <div></div>
                                    }
                                    <img
                                        src={rightArrow}
                                        alt="ScrollY"
                                        className=" cursor-pointer max-mobile:hidden"
                                        onClick={() => (scrollRef.current.scrollLeft += 1200)}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            {popupsubmit ? <div className='flex h-[100vh] max-tablet:h-[100vh] max-mobile:h-[80vh]  bg-[#F9FCFF] items-center align-middle '>
                                <div className=' mx-auto w-[600px] max-mobile:w-[400px]  '>
                                    <div className=' align-middle mx-auto text-center relative h-fit max-mobile:h-[50vh]  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow-3xl' >
                                        <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={handleClose} src={closeicon} alt='closeicon' />
                                        <div className='text-left  flex flex-col items-start ' >
                                            <p className='text-[20px] text-[#878787] ml-2  max-laptop:text-[16px]'><span className='bg-[#036AD1] text-[#ffffff] p-2.5 w-[10px] h-[10px] rounded-[50%]'>{title}</span> {studentDetail?.name}</p>
                                            <div className='flex items-center'>
                                                <img src={MailIcon} alt='icon' className='w-[50px] h-[50px]' />
                                                <p className='text-[20px] text-[#878787] mt-2  max-laptop:text-[16px]'>{studentDetail?.email}</p>
                                            </div>
                                            <div className='flex items-center'>
                                                <img src={PhoneIcon} alt='icon' className='ml-3 w-[25px] h-[25px]' />
                                                <p className='text-[20px] text-[#878787] mt-2 ml-2  max-laptop:text-[16px]'>{studentDetail?.phoneNumber}</p>
                                            </div>
                                        </div>
                                        <div className='m-[5%_0_0_0%] h-[142px] w-12/12 bg-[#FBFDFF] rounded-lg shadow p-2 flex '>
                                            <div className='w-[50%] pl-3 max-tablet:w-[50%] max-mobile:w-[35%]'>
                                                <img className='w-[200px] h-[108px]' src={courseDetails.thumbnail.url}></img>
                                            </div>
                                            <div className='w-[40%] text-left my-auto m-4 '>
                                                <p className='text-[23px]  max-laptop:text-[16px]'>{courseDetails.course_name}</p>
                                                <div className='ml-0 text-left pr-4 flex items-center max-laptop:pr-1'>
                                                    <p className='pr-3 font-bold text-[12px] text-[#000000]'> {courseDetails.star_rating}</p>
                                                    <StarRating rating={5}
                                                    // {courseDetails.star_rating} 
                                                    />
                                                </div>
                                                <div className='flex justify-between max-laptop:block max-mobile:text-[14px]'>
                                                    <div className='flex items-center'>
                                                        <img src={stopClock} className='w-[16px] h-[16px] mr-1' alt='icon' />
                                                        <p >{courseDetails.duration} Hr</p>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <img src={enroleImg} className='w-[16px] h-[16px] mr-1' alt='icon' />
                                                        <p>{convertToK(courseDetails.students_enrolled)} Enrolled</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='m-[5%_0_0_0] max-desktop:-mx-3'>
                                            <div className='flex justify-between max-mobile:block w-full'>
                                                <div className=' flex items-center my-1' >
                                                    <Select
                                                        placeholder='Select your City'
                                                        options={allcity}
                                                        getOptionLabel={(option) => option.city_name}
                                                        getOptionValue={(option) => option.city_name}
                                                        onChange={(e: any) => { filterAllCities(e); }}
                                                        className='text-left text-[16px] rounded-lg p-1 w-[280px] max-mobile:w-[100vw]'
                                                        required
                                                    />
                                                </div>
                                                <div className='flex item-center my-1 '>
                                                    <Select
                                                        placeholder='Select your Branch'
                                                        options={allbranch}
                                                        getOptionLabel={(option) => option.branch_name}
                                                        getOptionValue={(option) => option.branch_name}
                                                        onChange={(e: any) => { filterAllbranch(e); }}
                                                        className='text-left text-[16px] p-1 rounded-lg w-[280px] max-mobile:w-[100vw]'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {slectOption ? <button onClick={handleSubmit} disabled className="bg-[#036AD1] opacity-0 mt-8 rounded-[14px] shadow-[ 0px_3px_6px_#00000029;] text-[#FFFFFF] w-[154px] h-[46px] max-tablet:h-[46px] max-mobile:h-[35px] max-tablet:w-[154px] max-mobile:w-[100px]  text-[25px] max-mobile:text-[16px] py-1 px-4 ">
                                                Submit
                                            </button> : <button onClick={handleSubmit} className="bg-[#036AD1] mt-8 rounded-[14px] shadow-[ 0px_3px_6px_#00000029;] text-[#FFFFFF] w-[154px] h-[46px] max-mobile:text-[16px] max-tablet:h-[46px] max-mobile:h-[35px] max-tablet:w-[154px] max-mobile:w-[100px]  text-[25px] py-1 px-4 ">
                                                Submit
                                            </button>}
                                        </div>
                                    </div>
                                </div>


                            </div> : <div className=' flex h-[86vh] max-tablet:h-[86vh] max-mobile:h-[80vh] bg-[#F9FCFF] items-center align-middle '>
                                <div className=' align-middle mx-auto text-center relative  w-[50%] max-mobile:w-[70vw] justify-center p-6 bg-white border border-gray-200 rounded-lg shadow-3xl' >
                                    {/* <p className='bg-[#FF0000] text-[#ffffff] cursor-pointer rounded-full p-0.1  right-5  top-3 absolute mb-5 w-6 h-6' onClick={handleClose}> X</p> */}
                                    <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={handleClose} src={closeicon} alt='closeicon' />
                                    <div className=' px-5 py-2 ml-5'>
                                        <p className=' text-center text-[23px] items-center w-[95%]  max-mobile:text-[16px]'>Thank you for your interest,</p>
                                        <p className=' text-center text-[23px] items-center w-[95%] max-mobile:text-[16px]'> Our Career Consultant will get back to you shortly.</p>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div> :
                <div></div>
            }
<Footer/>


        </div>
    )
}


