import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables";
import { FetchData, InsertData, UpdateData } from "../../crud";
import { StudentInterface } from "./interface";

export const createStudent = async (studentData: StudentInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.students, studentData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}


export const updateStudent = async (studentData: StudentInterface) => {
    try {
        const { uid, ...rest } = studentData;
        const { data, error } = await UpdateData(TableNames.students, {...rest,details_filled:true,...studentData.email&&{email:studentData.email.toLowerCase()}}, { conditionKey: "uid", conditionValue: uid });
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getStudentData = async (studentId: string) => {
    try {
        const { data: students, error }: any = await FetchData(TableNames.students).eq("uid", studentId);
        const {data: student_trainings, error: training_error} = await FetchData(TableNames.trainings, ["id", "course_id"]).eq("student_id", studentId)
        if (error || training_error) throw error || training_error;
        if (!students[0]?.is_email_verified) {
            return "EMAIL_UNVERIFIED";
        }
        else if (!students[0]?.details_filled) {
            return "DETAILS_UNFILLED";
        }
        return {student: students[0], trainings: student_trainings};
    } catch (error) {
        return errorNotifier(error);
    }
}