import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData, InsertData } from "../../crud";
// import { progressByModuleCalculation } from "../Courses/Courses";
import {
  calculateFinalStatus,
  calculatePaidCourseCompletion,
  findDifferenceOfDates,
} from "./helper";
import {
  ApplyJobsInterface,
  checkEligiblityForPlacementAndGetJobDataType,
} from "./interface";

export const checkEligiblityForPlacementAndGetJobData: checkEligiblityForPlacementAndGetJobDataType =
  async (studentId) => {
    try {
      // Should have already checked for placement assistance from redux
      // based on paid course take the latest course
      let { data: courseData, error: QueryError }: any = await FetchData(
        TableNames.courses,
        ["id"]
      ).eq("type", "Paid");
      courseData = courseData?.map((course: any) => course.id);
      // console.log(courseData);
      const { data: TrainingData, error: TrainingQueryError }: any =
        await FetchData(TableNames.trainings, [
          "id",
          "balance_due",
          "course:courses(*)",
          "progress_by_module",
          "student:student_id(courses)",
          "batch:batches(id,duration,sessions:sessions(*))",
        ])
          .match({
            student_id: studentId,
            balance_due: 0,
          })
          .in("course_id", courseData)
          .order("id", { ascending: false });
      if (TrainingQueryError) throw TrainingQueryError;
      if (!TrainingData.length) {
        return {
          status: false,
          message: "payment-not-completed",
        };
      }
      const courseProgress = calculatePaidCourseCompletion(
        TrainingData[0]?.batch?.duration,
        TrainingData[0]?.batch?.sessions
      );

      if (courseProgress < 90) {
        return {
          status: false,
          message: "progress-not-completed",
        };
      }

      const { data, error }: any = await FetchData(TableNames.student_jobs, [
        "created_at",
      ])
        .eq("student_id", studentId)
        .order("id", { ascending: false });
      if (error) throw error;
      if (
        data.length &&
        findDifferenceOfDates(new Date(), new Date(data[0]?.created_at)) >= 90
      ) {
        return {
          status: false,
          message: "not-applied-in-90-days",
        };
      }
      return {
        status: true,
        data: await getJobsForTheStudent(
          studentId,
          true,
          TrainingData[0]?.student?.courses
        ),
      };
    } catch (error) {
      return errorNotifier(error);
    }
  };

export const getJobsForTheStudent = async (
  studentId: string,
  isCourseIdsPresent: boolean = false,
  courseData: any[] = []
) => {
  try {
    if (!isCourseIdsPresent) {
      const { data: studentData, error: StudentError }: any = await FetchData(
        TableNames.students,
        ["courses"]
      ).eq("uid", studentId);

      if (StudentError) throw StudentError;

      courseData = studentData[0]?.courses;
    }

    let { data: FilteredTechnologiesIds, error: TechnologyQueryError }: any =
      await FetchData(TableNames.technologies, ["id"]).overlaps(
        "courses",
        courseData
      );

    if (TechnologyQueryError) throw TechnologyQueryError;

    // console.log(courseData, FilteredTechnologiesIds, "FilteredTechnologiesIds")

    FilteredTechnologiesIds = FilteredTechnologiesIds?.map(
      (obj: any) => obj.id
    );

    
    let { data: JobsMatchedWithTechnologies, error: JobsQueryError }: any =
      await FetchData(TableNames.jobs, [
        "job_role",
        "company_name",
        "location",
        "inserted_at",
        "id",
        "technology",
      ])
        // .in("technology", FilteredTechnologiesIds)
        // .filter('technology', 'jsonb_contains', `[${FilteredTechnologiesIds}]`)
        .order("id", { ascending: false });

    if (JobsQueryError) throw JobsQueryError;

    // console.log(JobsMatchedWithTechnologies, "JobsMatchedWithTechnologies Before Filtering");
    
    JobsMatchedWithTechnologies = JobsMatchedWithTechnologies.filter(
      (job: any) =>
        job.technology &&
        job.technology.some((techId: any) =>
          FilteredTechnologiesIds.includes(techId?.id)
        )
    );

    // console.log(
    //   JobsMatchedWithTechnologies,
    //   "JobsMatchedWithTechnologies After Filtering"
    // );


    let { data: appliedJobs }: any = await FetchData(TableNames.student_jobs, [
      "job_id",
    ]).eq("student_id", studentId);

    appliedJobs = appliedJobs?.map((aJobs: any) => aJobs.job_id);

    JobsMatchedWithTechnologies = JobsMatchedWithTechnologies?.map(
      (job: any) => ({
        ...job,

        isApplied: appliedJobs.includes(job.id),
      })
    );
    // console.log(JobsMatchedWithTechnologies, "JobsMatchedWithTechnologies")

    return JobsMatchedWithTechnologies;
  } catch (error) {
    return errorNotifier(error);
  }
};

// Fetch Job details
export const getJobsDataById = async (studentId: string, jobId: number) => {
  try {
    const { data: JobData, error: JobsQueryError }: any = await FetchData(
      TableNames.jobs,
      ["*", "jobs:student_jobs(*)"]
    ).eq("id", jobId);
    if (JobsQueryError) throw JobsQueryError;
    return {
      ...JobData[0],
      isApplied: JobData[0]?.jobs?.some(
        (job: any) => job.student_id === studentId
      ),
    };

  } catch (error) {
    return errorNotifier(error);
  }
};


export const getAppliedJobs = async (studentId:string) => {

    try {

        let { data: appliedJobsData, error: appliedJobsQueryError }:any = await FetchData(TableNames.student_jobs, ["id", "job:jobs(job_role,company_name,location,inserted_at, id)", "shortlisted","placed","joined", "status"]).eq("student_id", studentId).order("id", {ascending: false});
        if (appliedJobsQueryError) throw appliedJobsQueryError;
        return appliedJobsData;
    } catch (error) {
        return errorNotifier(error);
    }

}

export const getAppliedJobById = async (appliedId: number) => {
  try {
    const { data: appliedJobData, error: QueryError }: any = await FetchData(
      TableNames.student_jobs,
      ["*,job:jobs(*)"]
    ).eq("id", appliedId);
    if (QueryError) throw QueryError;
    return appliedJobData.map((job: any) => ({
      ...job,
      status: calculateFinalStatus({
        shortlisted: job.shortlisted,
        joined: job.joined,
        placed: job.placed,
      }),
    }))[0];
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getLatestTraining = async (student_id: string) => {
  try {
    const { data, error } = await FetchData(TableNames.trainings, [
      "id",
      "course:courses(type)",
      "status",
    ])
      .eq("student_id", student_id)
      .order("id", { ascending: false })
      .or("status.eq.Allocated, status.eq.Completed");
    const training_data: any = data?.filter(
      (el: any) => el.course.type === "Paid"
    );
    if (error) throw error;
    return training_data[0];
  } catch (error) {
    return errorNotifier(error);
  }
};

export const applyForJob = async (applyJobData: ApplyJobsInterface) => {
  try {
    // check for job already applied?
    const { data, error } = await InsertData(TableNames.student_jobs, {
      ...applyJobData,
      unique_checker: applyJobData.student_id + applyJobData.job_id,
    });
    if (error) throw error;

    return data;
  } catch (error: any) {
    if (error?.details?.includes("unique_checker") && error?.code === "23505") {
      delete error.code;

      error.details = "Already Applied for the Job";

    }
    return errorNotifier(error);
  }
};
