import { useState, useEffect, useRef } from "react"
import React from 'react'
import circlRrightArrow from '../../images/circlRrightArrow.png'
import { useLocation, useNavigate } from "react-router-dom";
import { OTPVerification, sendOTPToEmail ,EmailOTPVerification} from "../../Services/OTP";
import { updateStudent } from "../../function/Student/SignUp/Student";
import { errorNotifier } from "../../function/commonHelper";
import { UpdateData } from "../../function/crud";
// import { uuid } from "../../redux/useReducer";
// import {get_uid} from "../signupForm/Uuid"

let currentIndex: number = 0;

function VerifyEmail() {
    const { state } = useLocation()
    const [values] = useState(state)
    const [counter, setCounter] = useState(59);
    const [totalcount,setTotalcount] = useState(599);
    const [time, settime] = useState({
        min: 9,
        sec: 59
    })
    // const [otpnumber, setOtpnumber] = useState<string[]>(new Array(6).fill(''))
    const [otpnumber, setOtpnumber] = useState<string[]>(new Array(4).fill(''))

    const [otpindex, setOtpindex] = useState<number>(0)
    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement>(null)
    const [nextbtn, setNextbtn] = useState(true)
    const uuid:any = localStorage.getItem('uid')
    const [resendOtp, setResendOtp] = useState(false)
       const [active, setActive] = useState(false)

    const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setNextbtn(true)
        const { value } = target;
        const newOtp = [...otpnumber]
        newOtp[currentIndex] = value.substring(value.length - 1)
        if (!value) {
            setOtpindex(currentIndex - 1)
        } else {
            setOtpindex(currentIndex + 1)
        }
        setOtpnumber(newOtp)
        let otpFlag = 0
        newOtp.forEach(Element => {
            if (Element === '') {
                otpFlag = 1
            }
        })
        if (otpFlag) {
            setNextbtn(true)
        } else {
            setNextbtn(false)
        }
    }

    const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        currentIndex = index
        if (key === 'Backspace') {
            setOtpindex(currentIndex - 1)
        }
    }

// test code 

    const handleClick = async (e: any) => {
        let otpvalue = otpnumber.join('')
        // let verifiOtp = await EmailOTPVerification(uuid,otpvalue,values.email)
        // const verified = await setEmailVerifiedTrue(uuid)
        if (otpvalue == '5678') {
            await updateStudent({ uid: uuid, name: values.name, email: values.email,is_email_verified:true })
            navigate('/beforeassessment')
        }

    }

//email otp live code

    // const handleClick = async (e: any) => {
    //     let otpvalue = otpnumber.join('')
    //     const data: any = await OTPVerification(otpvalue, values.email, 'email')
    //     // const { error: OTPError } = await UpdateData('students', {
    //     //                 otp: null,
               
    //     //                     is_email_verified: true
    //     //             }, { conditionKey:'email', conditionValue:  values.email });
    //     if (data) {
    //         navigate('/beforeassessment')
    //     }else{
    //         errorNotifier({'message':'invalid Otp'})
    //     }

    // }

    const handleResend = async () => {
        
        await sendOTPToEmail(uuid, (values.email))
        setActive(false)
        setCounter(59)
        settime({ min: 9, sec: 59 })
    }

    useEffect(() => {
        inputRef.current?.focus()
        var timer: any = totalcount >0  && setInterval(() => {
            setCounter(counter - 1)
            setTotalcount(totalcount-1)
        
            
                if (counter == 0 ) {
                        let currnt_min = time.min - 1
                        settime({ min: currnt_min, sec: 59 })
                        setCounter(59) 
              
                }else{
                    settime({ min:time.min , sec: counter})
                }
         
       

        }, 1000);
        if (totalcount ==0 ) {
            setResendOtp(true)
            setActive(true)
            settime({ min: 0, sec: 0 })
        }
        return () => clearInterval(timer);
    }, [counter, otpindex]);

    return (
        <div className="bg-[url('../public/images/frontBg.png')] bg-cover h-[100vh] ">
            <div className="text-[65px] text-[#036AD1] font-[roboto] font-bold pt-40 max-mobile:text-[22px]  ">Verify Your Email</div>
            <div className="text-[31px] text-[#707070] font-[roboto] font-medium mt-0 max-mobile:text-[18px]">Enter your OTP code here</div>
            <div className="flex text-center justify-center my-7">
                {otpnumber.map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            <input value={otpnumber[index]} ref={index === otpindex ? inputRef : null} onKeyDown={(e) => handleKeyDown(e, index)} type='number' className="otp-input w-10  py-3 h-14 border-b-2 ml-3 border-b-black text-[48px] max-mobile:text-[30px] text-center outline-none text-[#707070] pr-2" onChange={(e) => handleInputChange(e)} disabled={active} />
                        </React.Fragment>
                    )
                })}
            </div>
            <div className=" text-left flex  justify-center">
                <p className="text-[31px] text-[#585858] font-[roboto] font-medium mt-5 max-mobile:text-[18px]">Didn’t recive the code? {resendOtp ? <span onClick={handleResend} className="text-[#0048FF] cursor-pointer">Click here</span> : <span className="text-[#585858] ">Click here</span>}...<br />
                <span className="">{ '0' + time.min}:{time.sec < 10 ? '0' + time.sec : time.sec}</span></p>

            </div>
            <button disabled={nextbtn} onClick={handleClick}>  <img className=" inline-block w-[46px] h-[46px] my-5" src={circlRrightArrow} alt='arrow' /> </button>
        </div>
    )
}

export default VerifyEmail