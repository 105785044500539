import React, { useEffect, useState, CSSProperties } from "react";
import CourseSessionCard from "../elements/courseSessionCard";
import Header from "../elements/header";
import coursematerialData1 from "../../content/coursematerialData.json";
import { Materialcards } from "../elements/cards";
import { useDispatch, useSelector } from "react-redux";
import {
  feedback_data,
  setcourseDetails_Data,
  setfeedback_Data,
} from "../../redux/useReducer";
import {
  getCourseMaterials,
  getCourses,
  getIndividualCourseMaterials,
  getIndividualFreeCourseMaterials,
} from "../../function/Student/CourseMaterials/CourseMaterials";
import closeicon from "../../images/close-red-icon.svg";
// import { uuid } from "../../redux/useReducer";
import CourseCard from "../elements/courseCard";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";

function CourseMaterial() {
  const uuid: any = localStorage.getItem("uid");
  const dispatch = useDispatch();
  let count = 0;
  const [loadState, setLoadstate] = useState(false);
  const [loadMaterial, setLoadmaterial] = useState(false);
  const [coursematerial, setCourseMaterial] = useState() as any;
  const [courseList, setCourseList] = useState({}) as any;
  const [pageState, setPagestate] = useState(false);


  let [loading, setLoading] = useState(true);

  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };
  // let data_Lenght = coursematerial?.course_materials.map((item: any) => { return count += 1 })

  const courseMaterials = async (props: any) => {
    let batchId = props?.courseDetails?.batch_id;

  };

  const data = async () => {
    // const course_data = await getSessionsByStudentUid(uuid)
    //   courseMaterials(course_data)
    setLoading(true);
    const course_list = await getCourses(uuid);
    setCourseList(course_list);
    setPagestate(true);
    setLoadstate(true);
    setLoading(false);
  };

  const [lengthcount, setCount] = useState(9);
  const handleLoad = () => {
    let data_Lenght = coursematerial?.course_materials
      ? coursematerial?.course_materials?.map((item: any) => {
          return item;
        })
      : [];
    setCount(data_Lenght.length);
  };


  useEffect(() => {
    data();
  }, []);
  let element_div = null;

  const handleClick = async (props: any) => {
    setLoading(true);
    const data = await getIndividualCourseMaterials(props?.id);
    setCourseMaterial(data);
    setLoadstate(false);
    setLoadmaterial(true);
    setLoading(false);
  };

  const handleClickfree = async (props: any) => {
    setLoading(true);
    const data = await getIndividualFreeCourseMaterials(props?.id);
    setCourseMaterial(data);
    setLoadstate(false);
    setLoadmaterial(false);
    setLoading(false);
  };

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header />
      {loading == false ? (
        <>
          {" "}
          {pageState ? (
            <div>
              {" "}
              {loadState ? (
                <div>
                  <p className="pt-5 text-[25px] text-left  text-[#707070]   px-10">
                    My Courses
                  </p>
                  <hr className=" mx-10 " />
                  {courseList && courseList.length ? (
                    // <div className="grid max-monitor grid-cols-3  gap-4 px-10  max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                    <div className="grid max-monitor:grid-cols-3 mt-5 gap-4 px-10 max-tablet:px-10 max-mobile:px-3 max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                      {courseList?.map((item: any) => {
                        item?.course?.type === "Paid"
                          ? (element_div = (
                              <div onClick={() => handleClick(item)}>
                                <div className="w-[350px] max-tablet:w-[350px] max-mobile:w-[80vw] mx-auto">
                                  <CourseCard
                                    image={item?.course.thumbnail.url}
                                    //image={item?.course.thumbnail?item?.course.thumbnail?JSON.parse(item.course.thumbnail)?.url:'':''}

                                    value={item.course.course_name}
                                    type={item.course.type}
                                  />
                                </div>
                              </div>
                            ))
                          : (element_div = "");
                        return element_div;
                      })}
                    </div>
                  ) : (
                    <p className="text-center flex items-center justify-center mt-32 max-mobile:mt-20 text-[22px] max-mobile:text-[16px] max-mobile:w-[75vw]  w-[70%] mx-auto">
                      Currently, no courses are available for you. <br></br>
                      To get started, please explore our course catalog and
                      enroll in a course now.
                    </p>
                  )}

                  <div className="grid max-monitor:grid-cols-3 mt-5 gap-4 px-10 max-tablet:px-10 max-mobile:px-3 max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                    {courseList && courseList.length
                      ? courseList?.map((item: any) => {
                          item?.course?.type === "Free"
                            ? (element_div = (
                                <div onClick={() => handleClickfree(item)}>
                                  <div className="w-[350px] max-tablet:w-[350px] max-mobile:w-[80vw] mx-auto">
                                    <CourseCard
                                      image={item?.course.thumbnail.url}
                                      value={item?.course.course_name}
                                      type={item?.course.type}
                                    />
                                  </div>
                                </div>
                              ))
                            : (element_div = "");
                          return element_div;
                        })
                      : ""}
                  </div>
                </div>
              ) : (
                <div>
                  {loadMaterial ? (
                    <div>
                      <div className="pt-5">
                        <CourseSessionCard
                          title="Course Material"
                          courseName={coursematerial?.course_name}
                          trainer={coursematerial?.trainer}
                          branch={coursematerial?.branch}
                          city={coursematerial?.city}
                          percentage={
                            coursematerial?.course_progress == undefined
                              ? 0
                              : coursematerial?.course_progress
                          }
                        />
                      </div>
                      {lengthcount >= 3 ? (
                        <div className="!grid !grid-cols-3 gap-4 items-center mx-auto w-11/12 p-10 max-laptop:grid-cols-2 max-mobile:grid-cols-1 H-[100]">
                          {coursematerial?.course_materials
                            ? coursematerial?.course_materials
                                ?.slice(0, lengthcount)
                                ?.map((item: any) => {
                                  return (
                                    <div key={item.key}>
                                      <Materialcards
                                        title={item.title}
                                        show_percentage={item.show_percentage}
                                        url1={item.url}
                                        fileName={item.fileName}
                                        coursePercentage={
                                          coursematerial?.course_progress ==
                                          undefined
                                            ? 0
                                            : coursematerial?.course_progress
                                        }
                                      />
                                    </div>
                                  );
                                })
                            : ""}
                        </div>
                      ) : (
                        <div className="flex mt-5 mx-auto justify-center w-11/12 max-tablet:w-[60%] max-mobile:w-[80%]  max-tablet:block max-mobile:block">
                          {coursematerial?.course_materials
                            ? coursematerial?.course_materials.map(
                                (item: any) => {
                                  return (
                                    <div className="m-4" key={item.key}>
                                      <Materialcards
                                        title={item.title}
                                        show_percentage={item.show_percentage}
                                        url1={item.url}
                                        fileName={item.fileName}
                                        coursePercentage={
                                          coursematerial?.course_progress
                                        }
                                      />
                                    </div>
                                  );
                                }
                              )
                            : ""}
                        </div>
                      )}
                      {lengthcount > 9 ? (
                        <button
                          onClick={handleLoad}
                          className="text-white text-center mt-5   w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] px-5 py-2.5 mb-2"
                        >
                          {" "}
                          Load More{" "}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="pt-5">
                        <CourseSessionCard
                          title="Course Material"
                          courseName={coursematerial?.course?.course_name}
                          trainer={coursematerial?.trainer}
                          branch={coursematerial?.branch?.branch_name}
                          city={coursematerial?.city?.city_name}
                          percentage={coursematerial?.overAllProgress}
                        />
                      </div>
                      {lengthcount >= 3 ? (
                        <div className="!grid !grid-cols-3 gap-4 items-center mx-auto w-11/12 p-10 max-laptop:grid-cols-2 max-mobile:grid-cols-1">
                          {coursematerial?.course?.course_materials
                            ? coursematerial?.course?.course_materials
                                ?.slice(0, lengthcount)
                                ?.map((item: any) => {
                                  return (
                                    <div key={item.key}>
                                      <Materialcards
                                        title={item.title}
                                        show_percentage={item.show_percentage}
                                        url1={item.url}
                                        fileName={item.fileName}
                                        coursePercentage={
                                          coursematerial?.overAllProgress
                                        }
                                      />
                                    </div>
                                  );
                                })
                            : ""}
                        </div>
                      ) : (
                        <div className="flex mt-5 mx-auto justify-center w-11/12 max-tablet:w-[60%] max-mobile:w-[80%]  max-tablet:block max-mobile:block ">
                          {coursematerial?.course?.course_materials
                            ? coursematerial?.course?.course_materials.map(
                                (item: any) => {
                                  return (
                                    <div className="m-4" key={item.key}>
                                      <Materialcards
                                        title={item.title}
                                        show_percentage={item.show_percentage}
                                        url1={item.url}
                                        fileName={item.fileName}
                                        coursePercentage={
                                          coursematerial?.overAllProgress
                                        }
                                      />
                                    </div>
                                  );
                                }
                              )
                            : ""}
                        </div>
                      )}
                      {lengthcount > 9 ? (
                        <button
                          onClick={handleLoad}
                          className="text-white text-center mt-5   w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] px-5 py-2.5 mb-2"
                        >
                          {" "}
                          Load More{" "}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <>
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </>
      )}
      <Footer />
    </div>
  );
}

export default CourseMaterial;
