import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import allocatedCourseData from '../../content/allocatedCourseData.json'
import courseicons from '../../images/photoshop.png'
import Header from '../elements/header'
import searchIcon from '../../images/searchIcon.svg'
import { Link, useNavigate } from "react-router-dom";
import { getTrendingCourses } from '../../function/Student/Courses/Courses'
// import { uuid } from '../../redux/useReducer'
import { StarRating } from "../elements/progressBar";
import enroleImg from "../../images/enroleImg.svg";
import Footer from '../elements/Footer'


function Allocatedcourse() {
  const uuid:any = localStorage.getItem('uid')
    const navigate = useNavigate();
    const { state } = useLocation()
    const [courseDetail] = useState(state)
    const selectedoption = courseDetail['selecetedData'].toString() as any
    let data = allocatedCourseData
    const [trendcourseData, setTrendCourseData] = useState({}) as any;
    const [courseDetails, setcourseDetails] = useState(data[selectedoption as keyof typeof data]) as any;

    // let courseDetails = data[selectedoption as keyof typeof data]
    const [search, setSearch] = useState('')

    // {data.selectedoption}


    const [Coursedata, setCourseData] =useState({})as any

    let filteredData:any
    const searchresult1 = async(props:any) => {
        let fil_data:any = []
        Object.keys(props).forEach((key, index) => {
            const item = props[key].id;
            Object.keys(courseDetails).forEach((filterKey:any) => {
              if (props[key].id == courseDetails[filterKey].id) {
                    // cas.push(props[key])
                    if(!fil_data.includes(props[key])){
                        fil_data.push(props[key])
                    }
                    return (courseDetails[filterKey] )
              }
            });   
          });
          setcourseDetails(fil_data)

    }


    const searchresult = () => {
        if (search.length > 0) {
            return courseDetails.filter((obj:any) => obj.title.toLowerCase().includes(search.toLowerCase()))
        }
        return courseDetails
    }
  

    const dataprops= async() => {
        const course_data = await getTrendingCourses("Paid", uuid);
        setTrendCourseData(course_data);
        searchresult1(course_data)
    }


    useEffect(() => {
        dataprops()
    },[])


    const handleClick = (props:any) => {
     
        const data= trendcourseData.filter((obj:any) => obj.id == props)
        navigate(`/course/${data[0]?.course_name?.replace(/ /g, "-").toLowerCase()}`, {
          state: data[0],
        });
    }
    
    const convertToK = (number: number) => {
    if (number >= 1000) {
      const suffixes = ["", "k", "M", "B", "T"];
      const suffixIndex = Math.floor(Math.log10(number) / 3);
      const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(
        1
      );
      return abbreviatedNumber + suffixes[suffixIndex];
    }
    return number.toString();
  };

let length = 20
    return (
        <div>
            <Header />
            <p className='mt-5 text-left ml-10 text-[30px] text-[#707070]'>Course</p>
            <div className='flex mx-auto justify-center items-center'>
                <input placeholder='Search...' className=' mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]' onChange={(e) => setSearch(e.target.value)}></input>
                <img className='bg-[#036AD1] p-2 shadow-3xl  w-[45px] h-[40px]' src={searchIcon} alt='icon' />
            </div>
            <div className='grid grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 p-6'>
                {courseDetails.length? courseDetails?.courseDetails?.map((item:any) => {
                    
                    return <div className=" my-2 mx-2  w-sm  bg-white border border-gray-200 rounded-lg shadow" onClick={(e:any) => handleClick(item.id)}>
                    <img
                      className="w-full h-[150px]"
                      // src={item?.thumbnail?JSON.parse(item.thumbnail)?.url:''}
                      src={item?.thumbnail?.url}
                      alt="course thumbnil"
                    />
                    <p className="px-4 pt-3 border-t-2 text-left text-[19px] font-medium text-[#707070] rounded-t-3xl">
                    {item.course_name?.substring(0, length)}{item?.course_name?.length>20?'...':''}
                      
                    </p>
                    <div className="ml-0 my-2 text-left px-4 flex items-center">
                      <p className="pr-3 font-bold text-[12px] text-[#000000]">
                        {" "}
                        {item.star_rating}
                      </p>
                      <StarRating rating={item.star_rating} />
                    </div>
                    <div className="flex justify-between p-3 ">
                      <p className=" text-[#007AFF] text-[14px] flex">
                        <img
                          className="w-[16px] h-[16px] mr-1"
                          src={enroleImg}
                          alt="enroleicon"
                        />{" "}
                         {/* {convertToK(item?.students_enrolled)} Enrolled  */}
                      </p>
                      <p className=" text-[#007AFF] text-[14px] mr-3">
                        {item.duration} Hrs
                      </p>
                    </div>
                  </div>
                    // return <div className=" my-2 mx-2  w-sm  bg-white border border-gray-200 rounded-lg shadow" onClick={(e:any) => handleClick(item.id)}>
                    //     <img src={courseicons} alt='course thumbnil' />
                    //     <p className="p-4 border-t-2 text-left text-[18px] font-medium text-[#707070] rounded-t-3xl">{item.title}</p>
                    // </div>
                }):<p className='text-center w-[100vw]'>No Courses Available</p>}
            </div>
            <Footer/>
        </div>

    )
}

export default Allocatedcourse

function convertToK(students_enrolled: any): React.ReactNode {
    throw new Error('Function not implemented.')
}
