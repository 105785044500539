import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCoursesPerCategory } from '../../function/Student/Courses/Courses'
import Header from '../elements/header';
import searchIcon from '../../images/searchIcon.svg'
import { StarRating } from '../elements/progressBar';
import enroleImg from '../../images/enroleImg.svg'
import Footer from '../elements/Footer';
// import { uuid } from '../../redux/useReducer'

function FreeTechnologiesCourse() {
    const uuid:any = localStorage.getItem('uid')
    const scrollRef: any = useRef();
    const { state } = useLocation()
    const [Id] = useState(state)
    const [allcourseData, setAllCourseData] = useState({}) as any
    const [search, setSearch] = useState('')
    const [coursestate, setCourseState] = useState(false)
    const [loadmorebtn,setloadmorebtn] = useState(true)
    const navigate = useNavigate()

    const data = async () => {
        const course_data:any = await getCoursesPerCategory(Id.id, "Free", uuid)
        setAllCourseData(course_data)
        setCourseState(true)
        course_data.length > count ? setloadmorebtn(true) : setloadmorebtn(false)
    }
    const convertToK = (number: number) => {
        if (number >= 1000) {
            const suffixes = ["", "k", "M", "B", "T"];
            const suffixIndex = Math.floor(Math.log10(number) / 3);
            const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
            return abbreviatedNumber + suffixes[suffixIndex];
        }
        return number.toString();
    }

    const searchresult = () => {
        if (search.length > 0) {
            return allcourseData.filter((obj: any) => obj.course_name.toLowerCase().includes(search.toLowerCase()))
        }
        return allcourseData
    }
    const handletrendCourse = (props: any) => {
        navigate(`/free-course-detialed/${props.course_name.replace(/ /g,'-')}`, { state: props })
    }
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        data()
    }, [])

    const [count, setCount] = useState(6)
    const handleLoad = () => {
        let data_Lenght = allcourseData?.map((item: any) => { return item })
        setCount(data_Lenght.length)
        setloadmorebtn(false)
    }

    return (
        <div>
            <Header />
            <p className='mt-5 text-left ml-10 text-[30px] text-[#707070]  font-bold'>Courses</p>
            <div className='flex mx-auto justify-center items-center'>
                <input placeholder='Search...' className=' mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]' onChange={(e) => setSearch(e.target.value)}></input>
                <img className='bg-[#036AD1] p-2 shadow-3xl w-[45px] h-[40px]' src={searchIcon} alt='icon' />
            </div>
           {coursestate ?   <div className='grid grid-cols-3  max-laptop:grid-cols-2 max-mobile:grid-cols-1 p-6'>
                {searchresult()?.slice(0,count).map((item: any, index: any) => {
                    return <div onClick={() => handletrendCourse(item)} className='mx-auto my-3 cursor-pointer'>
                        <div className="  items-center mx-3  w-[300px] h-[280px] bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[300px]">
                            <img className='w-full h-[150px]' src={item.thumbnail.url} alt='course thumbnil' />
                            <p className="px-4 pt-3 border-t-2 text-left text-[21px] font-medium text-[#707070] rounded-t-3xl">{item.course_name.length > 20 ?item.course_name.slice(0,20)+'...' : item.course_name}</p>
                            <div className='ml-0 text-left px-1 flex items-center'>
                                <p className='pr-3 font-bold text-[12px] text-[#000000]'> {item.star_rating}</p>
                                {/* <StarRating rating={item.star_rating} /> */}
                                <StarRating rating={5} />
                            </div>
                            <div className='flex justify-between p-3 '>
                                <p className=' text-[#007AFF] text-[14px] flex'><img className='w-[16px] h-[16px] mr-1' src={enroleImg} alt='enroleicon' /> {convertToK(item.students_enrolled)} Enrolled</p>
                                <p className=' text-[#007AFF] text-[14px] mr-3'>{item.duration} Hrs</p>
                            </div>
                        </div>
                    </div>
                })}
                

            </div> : <div></div>
            }
            {loadmorebtn ?
            <button onClick={handleLoad} className="text-white mx-auto text-center mt-5   w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900] font-medium rounded-md text-[20px] px-5 py-2.5 mb-2" > Load More </button>
            : ''
             }
             <Footer/>
        </div>
    )
}

export default FreeTechnologiesCourse