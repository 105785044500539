import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ApplyjobCardDesc } from '../elements/cards'
import Header from '../elements/header'
import rightarrowhead from '../../images/rightheadarrow.svg'
import Upload from "../../images/upload.svg"
import { applyForJob, getJobsDataById, getJobsForTheStudent, getLatestTraining } from '../../function/Student/job/jobs'
import { dateFormat } from '../elements/dateConvertion'
import closeicon from '../../images/close-red-icon.svg'
// import { settraining_idData, uuid } from '../../redux/useReducer'
import { useSelector } from 'react-redux'
import { getProfileDetails, uploadResume } from '../../function/Student/Profile/Profile'
import { uploadFile } from '../../function/Storage/storeage'
import Footer from '../elements/Footer'
import parse from "html-react-parser";

function Latestjobdesc() {
    const uuid:any = localStorage.getItem('uid')
    const { state } = useLocation()
    let [JobDetails] = useState(state)
    const [uploadPop, setuploadPop] = useState(true);
    const [submitPop, setSubmitPop] = useState(true);
    const [messagePop, setMessagePop] = useState(true);
    const [fileName, setFileName] = useState('');
    const [jobDesc, setJobDesc] = useState({}) as any
    const [jobState, setJobstate] = useState(false)
    const [resumeurl, setResumeUrl] = useState({}) as any
    const [trainingid, setTrainingid] = useState() as any

    const handleChange = async (e: any) => {
        setFileName(e.target.files[0].name)
        let file = e.target.files[0];
        const resumeData = await uploadFile({ file }, 'resume')
        setResumeUrl(resumeData)
        setSubmitPop(false)
    }
    const handlePop = async (props: any) => {
        const value = await getProfileDetails(uuid)
        let data = { 'value': value, 'job_id': props.id }
        checkresume(data)
    }
    const checkresume = async (props: any) => {
        if (props.value.resume !== null) {
            setuploadPop(false)
            setSubmitPop(false);
            setMessagePop(false)
            let data = await applyForJob({ 'student_id': uuid, 'training_id': trainingid.id, 'job_id': props.job_id })
            JobDetails.isApplied = true
        }
         else {
            setuploadPop(false)
          
        }
    }


    const handleSubmit = async () => {
        let key = Math.random().toString(36).substring(3, 7)
        let url = resumeurl.publicUrl
        await uploadResume(uuid, { resume: { 'key': key, 'url': url, 'fileName': fileName } })

        let data = await applyForJob({ 'student_id': uuid, 'training_id': trainingid.id, 'job_id': state.id })


        setMessagePop(false)
    }

    const handleClose = () => {
        setuploadPop(true)
        // setSubmitPop(true)
    }

    const handleRemove = () => {
        setSubmitPop(true);
    }

    const data = async () => {
        const jobdata = await getJobsDataById(uuid, JobDetails.id)
        setJobDesc(jobdata)
        const trainingId: any = await getLatestTraining(uuid)
        setTrainingid(trainingId)
        setJobstate(true)
    }
    useEffect(() => {
        data()

    }, [])

    return (
        <div>
            {jobState ? <div>
                <Header />
                {uploadPop ? <div className=' cursor-default'>
                    <ApplyjobCardDesc title={jobDesc?.job_role} subtitle={jobDesc?.company_name} location={jobDesc?.location?.join(', ')} date={dateFormat(jobDesc?.updated_at, 'DD MMM YYYY')} opening={jobDesc?.no_of_openings} status='' />
                    <div className=' w-7/12 mx-auto max-laptop:w-7/12 max-mobile:w-10/12'>
                        {jobDesc?.about_company ? <div>
                            <p className='text-[26px] text-[#707070] text-left font-medium py-1'>About Company</p>
                            <p className='text-18px] text-left text-[#707070] py-1'>{parse(jobDesc?.about_company)}</p>
                        </div> : <p></p>}
                        {jobDesc?.job_description ? <div>
                            <p className='text-[26px] text-[#707070] text-left font-medium py-1'>Job Description</p>
                            <p className='text-18px] text-left text-[#707070] py-1'>{parse(jobDesc?.job_description)}</p>
                        </div> : <p></p>}
                        {jobDesc?.skills ? <div>
                            <p className='text-[26px] text-[#707070] text-left font-medium py-1'>Key Skills</p>
                            {jobDesc?.skills?.map((item: any, index: any) => {
                                return <ul key={index} className="text-left text-[20px]  text-[#707070] px-6 max-mobile:text-[14px] max-mobile:px-2 ">
                                    <li className='flex items-center ' > <img className='w-3 h-3' src={rightarrowhead} alt='icon' /> <span className='pl-4'>{item}</span></li>
                                </ul>
                            })}
                        </div> : <p></p>}
                        {jobDesc?.eligibility ? <div>
                            <p className='text-[26px] text-[#707070] text-left font-medium py-1'>Eligibility</p>
                            <p className='text-[18px] text-left text-[#707070] my-2'>{jobDesc?.eligibility ? 'Yes' : 'No'}</p>
                        </div> : <p></p>}
                        <div className=' shadow-3xl justify-between flex items-center p-5 mt-2 mb-10 rounded-md max-mobile:block'>
                            <p className=' max-mobile:-ml-20 text-[16px] font-medium text-[#000000]'>Last Date to Apply: <span className=' text-[#036AD1]'>{dateFormat(jobDesc?.last_date_to_apply, 'DD MMM YYYY')}</span> </p>
                            {JobDetails.isApplied ? <p className='text-[20px] w-[102px] h-[32px]   text-[#FFFFFF] bg-[#036AD1] rounded-full px-4  cursor-pointer' >Applied</p> :
                                <p className='text-[20px] w-[102px] h-[32px]   text-[#FFFFFF] bg-[#036AD1] rounded-full px-4  cursor-pointer' onClick={() => handlePop(jobDesc)}>Apply</p>
                            }

                        </div>
                    </div>
                </div>
                    :
                    <div>
                        {submitPop ? <div className=' flex h-[100vh] max-mobile:h-[84vh] bg-[#F9FCFF] items-center align-middle '>
                            <div className=' align-middle mx-auto text-center relative h-[416px] w-[519px] py-10 justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                                <div className='mt-[20%]'>
                                    <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={() => handleClose()} src={closeicon} alt='closeicon' />
                                    <p className='text-[#707070] text-[32px] mt-[5%]' >Upload your Resume</p>
                                    <div className='flex justify-between items-center mx-auto px-6 bg-[#036AD1] w-[200px] p-3 rounded-full mt-[5%] cursor-pointer'>
                                        <label htmlFor="files" className="text-white text-[25px] flex justify-between w-[200px] cursor-pointer"  >Upload
                                            <input id="files" accept='.pdf,.doc' type="file" className=' opacity-0 hidden  ' onChange={(e) => handleChange(e)} />
                                            <img src={Upload} className='w-[40px]' alt='upload'></img>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <div>
                                {messagePop ?
                                    <div className=' flex h-[100vh] max-mobile:h-[84vh] bg-[#F9FCFF] items-center align-middle '>
                                        <div className=' align-middle mx-auto text-center relative h-[416px] w-[519px]  py-10  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                                            <div className='my-20 w-[100%]'>
                                                <div className='flex items-center justify-around font-bold'>
                                                    <p className='text-[#707070] text-[25px] text-left break-all' >{fileName}</p>
                                                    <img className='cursor-pointer ml-4 w-6 h-6 ' onClick={handleRemove} src={closeicon} alt='closeicon' />
                                                </div>
                                                <button onClick={handleSubmit} className="bg-[#036AD1] mt-8 rounded-full shadow-[ 0px_3px_6px_#00000029;] text-[#FFFFFF] w-[154px] h-[46px]  text-[25px] py-1 px-4 ">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className=' flex h-[100vh] max-mobile:h-[84vh] bg-[#F9FCFF] items-center align-middle '>
                                        <div className=' align-middle mx-auto text-center relative h-[417px] w-[518px] max-tablet:h-[400px] max-mobile:h-[330px] max-tablet:w-[518px] max-mobile:w-[90vw]  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                                            <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={() => handleClose()} src={closeicon} alt='closeicon' />
                                            <p className='text-[#000000] text-[21px] max-mobile:text-[18px] mt-[25%]' >Your resume will be emailed to the HR of the company. If your profile is shortlisted, you will receive a notification in the App. All the best for your Career</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
            }
            </div> : <div></div>
            }
<Footer/>
            
        </div>
    )
}

export default Latestjobdesc