import React, { useEffect, useRef, useState, CSSProperties } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../elements/header'
import enrolled from '../../images/enrolled.png'
import time from '../../images/time.png'
import callWhite from '../../images/callWhite.svg'
import download from '../../images/download.svg'
import certificatesImg from '../../images/certificatesImg.svg'
import hiringCompany from '../../images/hiringCompany.svg'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { freeCourseEnrollment, getAllHiringCompanies, getAllTestimonials, getTrendingCourses } from '../../function/Student/Courses/Courses'
import rightArrow from '../../images/rightArrow.svg'
import Graterthansymbol from '../../images/Graterthansymbol.svg'
import enroleImg from '../../images/enroleImg.svg'
import treandIcon from '../../images/treandIcon.svg'
import studtestingIcon from '../../images/studtestingIcon.svg'
import faqicon from '../../images/faqIcon.svg'
import { StarRating } from '../elements/progressBar'
import certification from '../../images/certification.png'
// import { uuid } from '../../redux/useReducer'
import ReactPlayer from 'react-player/youtube'
import ClipLoader from "react-spinners/ClipLoader";
import VideoComp from '../elements/videoComp'
import { getCertificate } from '../../function/Student/Certificate/Certificate'
import Footer from '../elements/Footer'

export default function FreeCourseDetialed() {
    const uuid:any = localStorage.getItem('uid')

    const scrollRef: any = useRef();
    const scrollRef1: any = useRef();
    const { state } = useLocation()
    const [courseDetails, setCourseDetails] = useState(state)
    const [trendcourseData, setTrendCourseData] = useState({}) as any
    const [testimonials, setTestimonials] = useState({}) as any
    const [companylogo, setCompanylogo] = useState({}) as any
    const [coursestate, setCourseState] = useState(false)
    const navigate = useNavigate()
    const [popupstate, setPopupState] = useState(true)
    // const [popupsubmit, setPopupSubmit] = useState(true)
    let [loading, setLoading] = useState(true);
    const [certificate, setCertificate] = useState({}) as any
    const override: CSSProperties = {
        flex: 1,
        marginTop:240,
        justifyContent: 'center',
        alignItems:'center'
      };

    const data = async () => {
        setLoading(true)
        const course_data = await getTrendingCourses("Free", uuid)
        setTrendCourseData(course_data)
        const companyData = await getAllHiringCompanies()
        setCompanylogo(companyData)
        const testimonialsData = await getAllTestimonials()
        setTestimonials(testimonialsData)
        setCourseState(true)
        const certificateimg = await getCertificate()
        setCertificate(certificateimg)
        setLoading(false)
       
    }
    useEffect(() => {
       
        data()
    }, [])

    document.documentElement.scrollTo({ top: 0, left: 0})

    const handleCourse = () => {
        navigate('/free-trending-course', { state: trendcourseData })
    }
    const handletrendCourse = (props: any) => {
        navigate(`/free-course-detialed/${props.course_name.replace(/ /g,'-')}`, { state: props }) 
        setCourseDetails(props)
        // navigate('/CourseDetialed', { state: props })
    }
    const convertToK = (number: number) => {
        if (number >= 1000) {
            const suffixes = ["", "k", "M", "B", "T"];
            const suffixIndex = Math.floor(Math.log10(number) / 3);
            const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
            return abbreviatedNumber + suffixes[suffixIndex];
        }
        return number.toString();
    }

    const handleDownload = (props: any) => {
        const url = props.url;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'
        link.download = 'image.jpg';
        link.click();
    }

    const handleEnroll = async (props: boolean) => {
        if (props) {
            const courseenrollment:any = await freeCourseEnrollment({ student_id: uuid, course_id: courseDetails?.id })
            navigate('/course-video', { state: courseenrollment?.message[0]?.id })
        } else {
            navigate('/course-video', { state: courseDetails?.training?.id })
        }
    }

    return (
        <div>
            <Header />
            {loading == false ?<> {coursestate ?
                <div>
                    <div className='flex w-full mx-auto px-20 max-laptop:px-14 items-center py-16 justify-between bg-[#036AD1] text-left  max-mobile:block max-mobile:px-8'>
                        <div className='w-7/12  text-[#fff]  max-laptop:w-6/12 max-mobile:w-[90%]'>
                            <p className='text-[30px] max-tablet:text-[30px] max-mobile:text-[22px] '>{courseDetails?.course_name}</p>
                            <div className='flex py-2 items-center '>
                                <p className='text-[25px]'>{courseDetails?.star_rating}</p>
                                <div className='ml-0 text-left px-4 max-mobile:px-1 max-tablet:px-4'>
                                    <StarRating rating=
                                    // {courseDetails?.star_rating}
                                    {5}
                                     />
                                </div>
                                <p className='text-[14px] text-[#E9E9E9]'>(
                                    {courseDetails?.ratings} 
                                    Ratings)</p>
                            </div>
                            <div className='flex py-2 w-11/12 max-tablet:w-11/12 max-mobile:w-[80vw] justify-between items-center'>
                                <div className='flex items-center'>
                                    <img src={enrolled} className='w-[16px] h-[16px]' alt='icon' />
                                    <p className='text-[19px] max-mobile:text-[15px] pl-2'>{convertToK(courseDetails?.students_enrolled)} Enrolled</p>
                                </div>
                                <div className='flex items-center'>
                                    <img src={time} className='w-[16px] h-[16px]' alt='icon' />
                                    <p className='text-[19px] max-mobile:text-[15px] text-[#E9E9E9] pl-2'>{courseDetails?.duration} hr Duration</p>
                                </div>
                            </div>
                            {/* <p className='text-[#E9E9E9] text-[15px] pt-2 w-11/12'>{courseDetails.description}</p> */}
                            <p className='text-[#E9E9E9] text-[19px] pt-2 max-laptop:text-[14px]'>{courseDetails?.description}</p>
                            <div className='flex pt-8 max-mobile:justify-center'>
                                {courseDetails?.is_enrolled ? <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]   p-2 px-8 rounded-md' onClick={() => handleEnroll(false)}>Enrolled</button> : <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]   p-2 px-8 rounded-md' onClick={() => handleEnroll(true)}>ENROLL NOW</button>}
                            </div>
                        </div>
                        <div className=' w-4/12 flex flex-col items-center mx-auto max-laptop:w-5/12 max-mobile:w-[78vw]' onClick={() => handleDownload(courseDetails?.syllabus)} >
                            <img className=' w-full rounded-md  max-mobile:mt-8 aspect-[5/3] max-w-[500px]' src={courseDetails.thumbnail.url} alt='course thumbnil' />
                            <button className='bg-[#E95900] max-w-[500px] text-[#FFFFFF] text-[20px]  max-mobile:text-[16px] p-2 w-full flex rounded-md my-1 justify-center items-center text-center mx-auto' onClick={() => handleDownload(courseDetails?.syllabus)}><img src={download} alt='download' className='w-[20px] h-[23px] mr-2' />Download Syllabus</button>
                        </div>
                    </div>


                    {courseDetails?.course_feature.length >=3 ?  <div className='bg-[#fff] mx-auto w-8/12 max-mobile:flex-wrap max-mobile:flex -mt-10 shadow-3xl mb-5 rounded-md grid grid-cols-3 gap-5 py-10 px-5 max-laptop:w-11/12'>

                        {courseDetails?.course_feature?.map((item: any) => {
                            return <div className='shadow-3xl p-7 max-tablet:p-7 max-mobile:p-2 max-mobile:w-[36vw] max-mobile:justify-center max-laptop:p-3 rounded-md flex items-center  max-mobile:mb-2 '>
                            
                            <div className='flex max-mobile:block items-center'>

                                <p className='max-mobile:flex max-mobile:justify-center max-mobile:mb-3'>  

                                    <img className='w-[40px] h-[40px]' src={item.icon} alt='icon' />
                                    </p>
                                    <p className='text-left ml-4 max-mobile:text-[14px]  max-mobile:break-words max-mobile:ml-0 max-mobile:text-center'>{item.feature}</p>
                                </div>
                            </div>
                        })}
                            </div> : <div className='bg-[#fff] mx-auto w-8/12 -mt-10 shadow-3xl mb-5 rounded-md  py-10 px-5 max-laptop:w-11/12 flex items-center  max-mobile:block'>

                    {courseDetails?.course_feature?.map((item: any) => {
                        return  <div className='shadow-3xl p-7 max-mobile:w-[70%] max-laptop:p-3 rounded-md flex items-center mx-auto  max-mobile:mb-2 w-[40%]'>

<div className='flex max-mobile:block items-center'>
                                <img className='w-[40px] h-[40px]' src={item.icon} alt='icon' />
                                <p className='text-left ml-4'>{item.feature}</p>
                            </div>
                        </div>
                    })}
                </div>
                    }
                    <div className='bg-[#F3F7FF] p-6  max-mobile:p-2'>
                        <p className='mt-5 flex items-center font-bold text-left ml-10 text-[30px] text-[#000000]  max-mobile:text-[20px] max-mobile:ml-5'><img className=' w-[30px] h-[30px] mr-4' src={studtestingIcon} alt='icon' /> Student Testimonials</p>
                        <div className="flex items-center px-5 mx-5 justify-between space-x-5  max-mobile:space-x-1 max-mobile:px-0">
                            <img
                                src={rightArrow}
                                alt="ScrollY"
                                className="rotate-180 cursor-pointer max-mobile:hidden"
                                onClick={() => (scrollRef1.current.scrollLeft -= 800)}
                            />
                            <div className={`mx-auto flex space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth  `} ref={scrollRef1}>
                                {testimonials?.map((item: any) => {
                                            return <div className={`${item?.type === "Upload Video" ?'py-8':'py-12'} bg-[#F3F7FF]`}>

                                        {item.type === "Upload Video" ?
                                        <VideoComp thumbnailUrl={item}/>:
                                            // <iframe className='w-[350px] h-[200px]  max-mobile:w-[250px] max-mobile:h-[150px] ' src={item?.video_url?.url}></iframe> :
                                            <ReactPlayer className='!w-[350px] !h-[250px] max-tablet:h-[200px] max-mobile:h-[200px] ' url={item?.external_link} poster={item?.thumbnail?.url} />
                                            
                                            // <ReactPlayer  className='!w-[350px] !h-[200px] max-tablet:h-[200px] max-mobile:h-[200px] '  url={item?.external_link} />
                                           // <iframe className='w-[350px] h-[200px]  max-mobile:w-[250px] max-mobile:h-[150px]' src={item?.external_link}></iframe>
                                        }
                                    </div>
                                })}
                            </div>
                            <img
                                src={rightArrow}
                                alt="ScrollY"
                                className=" cursor-pointer max-mobile:hidden"
                                onClick={() => (scrollRef1.current.scrollLeft += 800)}
                            />
                        </div>
                    </div>
                    <div className='p-7 px-16 max-mobile:px-7'>
                        <p className='mt-5 flex items-center font-bold text-left ml-0 text-[30px] text-[#000000]  max-mobile:text-[24px]'><img className=' w-[30px] h-[30px] mr-4' src={hiringCompany} alt='icon' /> Hiring Companies</p>
                        <div className='grid grid-cols-6  max-mobile:grid-cols-3'>
                            {companylogo?.map((item: any) => {
                                return <div className='p-5'>
                                    <img className='w-[200px] h-[50px] max-mobile:h-[6vh] max-mobile:w-[18vw] object-fill' src={item.company_logo} alt='amazon' />
                                </div>
                            })}

                        </div>
                        <p className='w-11/12 mx-auto py-6 text-[22px] max-mobile:text-[20px] '>and <span className='text-[#036AD1]'> 1500+ Companies</span></p>
                    </div>
                    <div className="flex h-[500px] justify-between items-center bg-[url('../public/images/certificateBg.png')] bg-cover bg-no-repeat text-left px-12 max-laptop:bg-auto max-mobile:px-5 max-mobile:block max-mobile:bg-[#036AD1] max-mobile:pb-8">
                        <div className='w-[80%] pl-5 py-16 max-desktop:py-10 max-monitor:py-16  max-laptop:py-10'>
                            <div className='flex text-[#fff]  max-mobile:w-[100%]'>
                                <img src={certificatesImg} alt='certificate' className='w-[19px] h-[34px]' />
                                <p className='pl-2  text-[20px]  max-mobile:w-[70%]'>Certificate</p>
                            </div>
                            <p className='text-[#fff] w-7/12 pt-4 text-[14px] max-laptop:w-[80%]  max-mobile:w-[100%]'>{courseDetails?.certificate_description}</p>
                            <div className='flex pt-8 text-[#fff] max-desktop:mb-16 max-mobile:justify-center '>
                                {courseDetails?.is_enrolled ? <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]   p-2 px-8 rounded-md' onClick={() => handleEnroll(false)}>Enrolled</button> : <button className='bg-gradient-to-r from-[#E92000] to-[#EA5801]   p-2 px-8 rounded-md' onClick={() => handleEnroll(true)}>ENROLL NOW</button>}
                            </div>
                        </div>
                        <div className='-mt-7 w-[40%]'>
                            <img className='w-[284px] h-[388px]  max-mobile:h-[200px] max-mobile:w-[200px] max-mobile:ml-28' src={certificate?.certificate} alt='certificate' />
                        </div>
                    </div>

                    <div className='mx-auto p-4 font-bold'>
                        <p className='items-center  text-center text-[30px] max-tablet:text-[30px] max-mobile:text-[18px] text-[#000000]'><img className='w-[30px] h-[30px] mr-4 inline ' src={faqicon} alt='icon' /> Frequently Asked Questions</p>
                        <div className='w-7/12 max-mobile:w-11/12 mx-auto accordionFont p-5'>
                            {courseDetails?.faqs?.map((item: any) => {
                                return <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header" className='bg-[#FBFBFB]'>
                                        <Typography className='font-bold '>{item.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className='bg-[#00000029] text-left'>
                                        <Typography>
                                            {item.description}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            })}
                        </div>
                    </div>
                    <div className='bg-[#F3F7FF] p-6 max-mobile:p-3'>
                        <p className='my-5 flex items-center font-bold text-left ml-6 text-[30px] max-tablet:text-[30px] max-mobile:text-[18px] text-[#000000]'><img className=' w-[30px] h-[30px] mr-4' src={treandIcon} alt='icon' /> Trending Courses</p>
                        <div className="flex items-center px-5 mx-5 max-mobile:mx-0 max-mobile:px-2 justify-between space-x-5 ">
                            <img
                                src={rightArrow}
                                alt="ScrollY"
                                className="rotate-180 cursor-pointer max-mobile:hidden"
                                onClick={() => (scrollRef.current.scrollLeft -= 1200)}
                            />
                            {coursestate ? <div className={`mx-auto flex space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth  `} ref={scrollRef}>
                                {trendcourseData?.slice(0, 5).map((item: any, index: any) => {
                                    return <div className=' cursor-pointer' onClick={() => handletrendCourse(item)}>
                                        <div className="  items-center  w-[300px] h-[280px] bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[75vw]">
                                            <img className=' aspect-[5/3] mx-auto' src={item.thumbnail.url} alt='course thumbnil' />
                                            <p className="px-4 pt-3 border-t-2 text-left text-[21px] font-medium text-[#707070] rounded-t-3xl">{item.course_name}</p>
                                            <div className='ml-0 my-2 text-left px-4 flex items-center'>
                                                <p className=' font-bold text-[12px] text-[#000000]'> {item.star_rating}</p>
                                                <StarRating
                                                //  rating={item.star_rating}
                                                rating = {5}
                                                  />
                                            </div>
                                            <div className='flex justify-between p-3 '>
                                                <p className=' text-[#007AFF] text-[14px] flex'>
                                                    <img className='w-[16px] h-[16px] mr-1' src={enroleImg} alt='enroleicon' /> {convertToK(item.students_enrolled)} Enrolled</p>
                                                <p className=' text-[#007AFF] text-[14px] mr-3'>{item.duration} Hrs</p>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                <div onClick={handleCourse} className=' flex cursor-pointer  items-center'>
                                    <p className='text-[24px] max-tablet:text-[24px] max-mobile:text-[20px] text-[#036AD1]  my-5 bg-[#ffffff] rounded-full shadow-3xl h-[130px] w-[130px] max-tablet:w-[130px] max-mobile:w-[100px] max-tablet:h-[130px] max-mobile:h-[100px] items-center text-center flex p-4 mx-20'> View All<img className='pl-1' src={Graterthansymbol} alt='icon' /> </p>
                                </div>
                            </div>
                                : <div></div>
                            }
                            <img
                                src={rightArrow}
                                alt="ScrollY"
                                className=" cursor-pointer max-mobile:hidden"
                                onClick={() => (scrollRef.current.scrollLeft += 1200)}
                            />
                        </div>
                    </div>
                </div>
                :
                <div></div>
            }</> : <> <ClipLoader

            color="#036AD1"
          
            loading={loading}
          
            cssOverride={override}
          
          /></> }
           <Footer/>

        </div>
    )
}




