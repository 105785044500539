import { createSlice } from "@reduxjs/toolkit";

export  const userslice = createSlice ({
    name:"user",
    initialState:{mobileno:'', jobstate:'',courseData:'', feedbackState:'', startTimeState:'', placementNeed:'', traning_id:''},
    reducers:{
        mobileno_data: (state, action) => {
            state.mobileno = action.payload
        },
        jobOpening_data: (state, action) => {
            state.jobstate = action.payload
        },
        courseDetails_Data:(state, action) => {
            state.courseData = action.payload
        },
        feedback_data:(state, action)=> {
            state.feedbackState = action.payload
        },
        startTime_data:(state, action)=> {
            state.startTimeState = action.payload
        },
        placement_need:(state, action)=> {
            state.placementNeed = action.payload
        },
        training_idData:(state, action) => {
            state.traning_id = action.payload
        }
      
    }
})

export const {mobileno_data, jobOpening_data, courseDetails_Data, feedback_data,startTime_data, placement_need, training_idData} = userslice.actions
export const selected_mobileNo = (state:any) => state.user.mobileno
export const setjobOpening_data = (state:any) => state.user.jobstate
export const setcourseDetails_Data = (state:any) => state.user.courseData
export const setfeedback_Data = (state:any) => state.user.feedbackState
export const setStartTime_Data = (state:any) => state.user.startTimeState
export const setPlacement_need = (state:any) => state.user.placementNeed
export const settraining_idData = (state:any) => state.user.traning_id
// export const uuid = 'f39f12ce-5134-49b8-ab60-dfe887a207ab'  //gowthama UUID //

// 

// export const uuid = "f8135595-ad9f-4483-af7a-1fc2b057f7ed" // naresh //



// export const uuid = "43317cfc-b225-415b-a6e1-35acc1c860f9"  //shalini



export const uuid ="db1efc57-4a50-4486-8243-3c0708c3c8a5"


// export const uuid ="9d4a4e14-6794-4c15-938d-a82281db6bf4" 


// export const uuid="013ba5e7-6c89-4c37-9112-c29864b9afe8"

// export const uuid="d0e480d0-1837-4318-8771-b216d467fd98"

// export const uuid = "95da898e-a755-4fe7-825a-81bfd976cbdd"

// export const uuid ='297dde7b-f166-4cd8-aaf1-4652d20fc83b'
export default userslice.reducer