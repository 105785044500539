import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { FetchData, InsertData } from "../../crud"
import { progressByModuleCalculation } from "../Courses/Courses"
import * as Sentry from "@sentry/react";

export const getTrainingDetails = async (trainingId: number | string) => {
    try {
        const { data: training, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(id, course_name, videos, duration, thumbnail)", "progress_by_module", "date_of_completion"]).eq("id", trainingId) 
        // edited by frontend
        if(!training.length){
         return errorNotifier('no data')
        }
        // 
        if (error) throw error
        return { ...training[0], ...progressByModuleCalculation(training[0].course, training[0].progress_by_module)};
    } catch (error) {
        Sentry.captureException(error);
        return errorNotifier(error)
    }
}

export const requestCertificate = async (studentUid: string, courseId: string, certificate: string) => {
    try {
        let certificateData = {
            student_id: studentUid,
            course_id: courseId,
            certificate: certificate
        }
        const {data, error} = await InsertData(TableNames.student_certificate, certificateData)
        if (error) throw error
        return data
    } catch (error) {
        Sentry.captureException(error);
        return errorNotifier(error)
    }
}


export const getCertificate = async () => {
    try {
        const { data: certificates, error }: any = await FetchData(TableNames.certificates);
        if (error) throw error;
        return certificates[0];
    } catch (error) {
        Sentry.captureException(error);
        return errorNotifier(error);
    }
}


// export const getCertificate = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.certificates, ["certificate"])
//         if (error) throw error
//         return data[0]
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }