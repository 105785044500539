import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import homeMenu from '../../content/homeMenu.json'
import Tooltip from '@mui/material/Tooltip';
import profileIcon from '../../images/profileIcon.svg'
import { Menu } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { NavLink, Link, useNavigate, Routes } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
import MenuIcon from '@mui/icons-material/Menu';


export default function Footer() {
 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
      

  };

  async function signout() {
    window.localStorage.clear();
    localStorage.removeItem('uid')
    navigate('/')
}
  return (
    <div className='h-[42px] relative bottom-0 max-mobile:flex max-desktop:hidden max-monitor:hidden max-tablet:hidden'>
  
        <div className="fixed w-full py-2 justify-end  bottom-0   bg-[#FCFCFC] max-mobile:flex max-tablet:hidden hidden ">

    <React.Fragment>
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <Tooltip title="">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{}}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon color='primary' />
          
          {/* <img className='w-7 ' src={profileIcon} alt='profileIcon' /> */}
        </IconButton>
      </Tooltip>
    </Box>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {homeMenu.map(data => {
        return data.routeLink !== 'logout'? <NavLink to={`${data.path}`} state={{ myState: `${data.routeLink}` }}>
          <MenuItem onClick={handleClose}>
            {data.value}
          </MenuItem>
        </NavLink>:
        <MenuItem onClick={signout}>
            {data.value}
          </MenuItem>
      })}

    </Menu>
  </React.Fragment>
  </div>
  </div>
  )
}
