
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../elements/header'

import { Progressbar } from '../elements/progressBar'
import time from '../../images/stopClock.svg'
import certificatesImg from '../../images/certificatesImg.svg'
import { getCourseVideosById } from '../../function/Student/Courses/Courses'
import { getStudentData } from '../../function/Student/SignUp/Student'
import Footer from '../elements/Footer'
// import { uuid } from '../../redux/useReducer'


export default function CourseVideo() {
    const uuid:any = localStorage.getItem('uid')
    const location= useLocation()
    // const [courseId, setCourseId] = useState(state)
    const courseId = location.state
    const [courseList, setCourseList] = useState({}) as any
    const [loadstate, setLoadstate] = useState(false)
    const navigate = useNavigate()
    const [progress, ] = useState({}) as any
    let falg = localStorage.getItem('visited')

    // localStorage.setItem('visited','false')
    const data = async () => {
        const courselist = await getCourseVideosById(courseId)
        const userDetail = await getStudentData(uuid)
        setCourseList(courselist)
        setLoadstate(true)
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
    data()
    localStorage.setItem('visited','false')
          
        
    }, [falg])

    // if( == 'yes'){
    //     data()
       
    // }
// setInterval(data(),1000)


    const [clickState, setClickstate] = useState(true)
    const handleClick = (props: any) => {
        // console.log('props', props,courseId)
        // navigate('/singleVideos', { state: { allCourse: courseId, selectCourse: props } })
        if (clickState) {
            navigate('/single-videos', { state: { allCourse: courseId, selectCourse: props } })
            if (props.progress !== 100) {
                setClickstate(false)
            }
        }
    }
    const requestCertificate = () => {
        navigate('/request-certificate', { state: courseId })
    }
    let current_click: any
    let prev:any = null;
    return (
        <div>
            <Header />
            {loadstate ? <div className=' mb-10'>
                <div>
                    
                    {courseList?.course?.videos?.map((item: any) => {
                        prev = (courseList?.course?.videos).indexOf(item)
                        return <div>
                            {(  prev==0 ||   courseList?.course?.videos[prev-1].progress === 100) ? <div className='flex mx-auto shadow-3xl rounded-md w-6/12 max-laptop:w-7/12 max-mobile:w-10/12 justify-between px-6 mt-6 items-center cursor-pointer' onClick={() => handleClick(item)}>
                                <div className='text-left py-5' key={item.key}>
                                    <p className='text-[#036AD1] text-[26px] max-laptop:text-[22px] font-medium'>{item.title}</p>
                                    <div className='flex pt-5 items-center'>
                                        <img src={time} alt='time' className=' w-[22px] h-[22px] mr-2 ' />
                                        <p className=' text-[#707070] text-[22px]'>{item.duration} Hrs</p>
                                    </div>
                                </div>
                                <div className='py-2'><Progressbar percentage={item.progress?item.progress:0} className='w-[100px] h-[100px]' />
                                </div>
                            </div> : <div className='flex mx-auto shadow-3xl rounded-md w-6/12 max-laptop:w-7/12 max-mobile:w-10/12 justify-between px-6 mt-6 items-center cursor-pointer '>
                                <div className='text-left py-5' key={item.key}>
                                    <p className='text-[#036AD1] text-[26px] max-laptop:text-[22px] font-medium'>{item.title}</p>
                                    <div className='flex pt-5 items-center'>
                                        <img src={time} alt='time' className=' w-[22px] h-[22px] mr-2 ' />
                                        <p className=' text-[#707070] text-[22px]'>{item.duration} Hrs</p>
                                    </div>
                                </div>
                                <div className='py-2'><Progressbar percentage={item.progress?item.progress:0} className='w-[100px] h-[100px]' />
                                </div>
                            </div>}

                        </div>
                    })}
                </div>
                <div>
                    {courseList.overAllProgress === 100 ? <div className='rounded-full mx-auto bg-[#036AD1] max-monitor:w-72 mt-12 mb-10 py-2 flex text-center max-monitor:pl-5 max-desktop:pl-5 items-center cursor-pointer' onClick={requestCertificate}>
                        <img src={certificatesImg} className='max-monitor:pl-2 max-desktop:pl-1 w-[50px] h-[27px] items-center' />
                        <p className='text-[#fff] max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px]' >Request a Certificate</p>
                    </div>
                        : <div></div>
                    }
                </div>
            </div> : <div></div>
            }
            <Footer/>
        </div>
    )
}