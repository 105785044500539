import FitaLogo from "../../images/FitaLogo.svg";
import HeaderData from "../../content/header.json";
import { NavLink, Link, useNavigate, Routes } from "react-router-dom";
import AccountMenu from "./profile";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "../../images/notificationsIcon.svg";
import { useEffect, useState } from "react";
import { getPushContentByStudentUid, pushNotificationRead} from "../../function/Student/pushNotification/PushContent";
import Footer from "./Footer";
// import { uuid } from "../../redux/useReducer";

export const Iconbadge = (props: any) => {
  if (window.location.pathname === props.props.routeLink) {
    return (
      <img
        className="w-[30px] h-[20px] max-mobile:w-[25px] max-mobile:h-[15px] mr-2"
        src={props.props.colorIcon}
        alt="icon"
      />
    );
  }
  return <img className="w-[30px] h-[20px] max-mobile:w-[25px] max-mobile:h-[15px]  mr-2" src={props.props.icon} />;
};



export const JobIcon = (props: any) => {
  if (
    window.location.pathname == "/latestjob" ||
    window.location.pathname == "/appliedjob"
  ) {
    return (
      <img
        className="w-[30px] h-[20px]"
        src={props.props.colorIcon}
        alt="icon"
      />
    );
  }
  return <img className="w-[30px]  h-[20px]" src={props.props.icon} />;
};

let jobs_list = ["/latestjob", "/appliedjob"];
function Header() {
  const uuid:any = localStorage.getItem('uid')
  const navigate = useNavigate();
  if(uuid === null){
    navigate('/')

  }

  async function signout() {
    window.localStorage.clear();
    localStorage.removeItem('uid')
    navigate('')
}
  const handleLink = (props: any) => {
    navigate(props);
  };
  const [count, setCount] = useState(0)
  const [load, setload] = useState(false)
  let pushconent : any
  const data = async () => {
  
    let pushconent:any = await getPushContentByStudentUid(uuid)

    // pushconent = []
    if(pushconent){
      pushconent = pushconent.filter((el: any) => {
        return el.read != true})
    }
    // let data_Lenght = all_pushcontent?all_pushcontent?.map((item: any) => { return item }):0

    pushconent?setCount(pushconent?.length):setCount(0)

    
    setload(true)

  }

  const readNotification = async() => {
  
    const pushcontent = await pushNotificationRead(uuid)

    if(pushcontent.status == 'success'){
      setCount(0)
    }

  }
  // console.log('count', count)  

  useEffect(() => {
    data()
  }, []);
  return (
    <div className="sticky top-0 z-10">
      {/* {load ? */}
        <header className="bg-[#fff] sticky w-full  shadow-3xl top-0 left-0 z-10">
          <div className="sticky top-0 flex p-3 text-center align-middle bg-[#FCFCFC] w-full max-laptop:p-1 max-mobile:justify-between">
            <div className="logo items-center flex pl-1 ">
              <Link to="/home">
                <img
                  className="w-[200px] h-[60px]  max-laptop:w-[100px]  max-laptop:h-[50px]"
                  src={FitaLogo}
                  alt="logoIcon"
                ></img>
              </Link>
            </div>
            <div className=" w-full flex justify-end  max-mobile:hidden ">
              <ul className="flex items-center text-center ">
                {HeaderData?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="text-[20px] font-normal  max-laptop:text-[14px] max-tablet:text-[12px] max-mobile:text-[12px]"
                    >
                      <div className="flex align-middle items-center mx-3">
                        {item?.option === undefined ? (
                          <NavLink
                            to={item?.routeLink}
                            className={({ isActive }) =>
                              isActive
                                ? "text-[#036AD1]  font-bold flex items-center"
                                : " font-normal  text-[#707070]  flex items-center"
                            }
                          >
                            <Iconbadge props={item} />
                            <p className=" max-desktop:text-[20px] max-laptop:text-[16px] max-tablet:text-[14px] max-mobile:text-[12px]">
                              {" "}
                              {item.navLink}{" "}
                            </p>
                          </NavLink>
                        ) : (
                          <div className="flex items-center max-tablet:hidden">
                            {/* <img className='w-[30px] h-[20px]' src={item.icon} alt='icons' /> */}
                            <JobIcon props={item} />
                            {jobs_list.includes(window.location.pathname) ? (
                              <select
                                className="bg-[#FCFCFC]   text-[#036AD1]  outline-none"
                                onChange={(e) => handleLink(e.target.value)}
                              >
                                <option disabled>Jobs</option>
                                {window.location.pathname == "/latestjob" ? (
                                  <option
                                    selected
                                    className=" text-[#036AD1]"
                                    value={item.routeLink1}
                                  >
                                    Latest Jobs
                                  </option>
                                ) : (
                                  <option value={item.routeLink1}>
                                    Latest Jobs
                                  </option>
                                )}
                                {window.location.pathname == "/appliedjob" ? (
                                  <option
                                    selected
                                    className=" text-[#036AD1]"
                                    value={item.routeLink}
                                  >
                                    Applied Jobs
                                  </option>
                                ) : (
                                  <option value={item.routeLink}>
                                    Applied Jobs
                                  </option>
                                )}
                              </select>
                            ) : (
                              <select
                                className="bg-[#FCFCFC]  text-[#707070]  outline-none"
                                onChange={(e) => handleLink(e.target.value)}
                              >
                                <option disabled selected>
                                  Jobs
                                </option>
                                {window.location.pathname == "/latestjob" ? (
                                  <option
                                    selected
                                    className=" text-[#036AD1]"
                                    value={item.routeLink1}
                                  >
                                    Latest Jobs
                                  </option>
                                ) : (
                                  <option value={item.routeLink1}>
                                    Latest Jobs
                                  </option>
                                )}
                                {window.location.pathname == "/appliedjob" ? (
                                  <option
                                    selected
                                    className=" text-[#036AD1]"
                                    value={item.routeLink}
                                  >
                                    Applied Jobs
                                  </option>
                                ) : (
                                  <option value={item.routeLink}>
                                    Applied Jobs
                                  </option>
                                )}
                              </select>
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className=" flex">
              <div className="py-4 px-2  ">
                <IconButton>
                  <Badge
                    badgeContent={count}
                    color="secondary"
                    style={{ fontSize: "55" }}
                  >
                    <NavLink to="/notificationpage" state={"Instant Push Content"}>
                      <img
                        className="w-9 px-1  max-laptop:w-9"
                        src={NotificationsIcon}
                        alt="icon"
                        onClick={()=>readNotification()}
                      />
                    </NavLink>
                  </Badge>
                </IconButton>
              </div>
              <div className=" flex items-center mx-4 -mt-1">
                <AccountMenu />
              </div>
            </div>
          </div>
        </header>
     {/* : <div></div>} */}
    </div>
  );
}

export default Header;
