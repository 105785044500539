import React from 'react'
import FitaLogo from "../../../images/FitaLogo.svg";
import { SUPABASE_CLIENT } from '../../../Services/Supabase';

export default function TrainerHeader() {
  const handleLogout = async () => {
    try {
      // Sign out the user
      await SUPABASE_CLIENT.auth.signOut();
      window.location.href = '/trainer/login';
      localStorage.removeItem('uid')
      // Redirect to the login page or update your app's state as needed
    } catch (error) {
      console.error('Error logging out', error);
    }
  };
  return (
    <div className='flex justify-between bg-[#fff] py-5 items-center'>
        <img className='w-[100px] ml-4' src={FitaLogo} alt ='FitaLogo' />
        <button onClick={()=>handleLogout()} className='text-[red] mr-[15px] font-bold'>
            Logout
        </button>

    </div>
  )
}
