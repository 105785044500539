import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setcourseDetails_Data } from "../../redux/useReducer";
import { SessionCard } from "../elements/courseSessionCard";
import { getSessionsByStudentUid } from "../../function/Student/CourseProgree/CourseProgress";
import rightArrow from "../../images/rightArrow.svg";
import viewallicon from "../../images/viewallicon.svg";
import { Link } from "react-router-dom";
// import { uuid } from '../../redux/useReducer'

function CourseProgress() {
  const scrollRef: any = useRef();
  const uuid: any = localStorage.getItem("uid");
  // const getCourseProgress = useSelector(setcourseDetails_Data)
  const [courseData, setCourseData] = useState({}) as any;

  const data = async () => {
    const course_data: any = await getSessionsByStudentUid(uuid);
    // console.log(course_data, "course_data");
    setCourseData(course_data);
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    data();
  }, []);

  return (
    <div>
      {"message" in courseData == false ? (
        Object.values(courseData).length ? (
          <>
            <div className=" text-left">
              <div className=" flex justify-between px-6 pt-3">
                <p className=" text-left  text-[#707070] text-[30px] font-medium  max-mobile:text-[20px]">
                  Course Progress
                </p>
                <Link to="/course-page">
                  {" "}
                  <p className="flex items-center text-[24px] font-bold text-[#036AD1]  max-mobile:text-[16px] max-mobile:font-normal">
                    View All
                    <img
                      className="w-3 h-3 pl-1 "
                      src={viewallicon}
                      alt="icon"
                    />{" "}
                  </p>
                </Link>
              </div>
            </div>
            <div className="flex items-center px-10 max-tablet:px-10 max-mobile:px-3  justify-between space-x-1 h-[250px]">
              <div
                className={`mx-auto w-full flex space-x-5 max-mobile:ml-[0%] overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth`}
                ref={scrollRef}
              >
                {courseData?.sessions
                  ?.slice(0, 4)?.reverse()
                  .map((item: any, index: any) => {
                    return (
                      <SessionCard
                        attendents={item.is_present ? "Present" : "Absent"}
                        sessionMessage={
                          item.is_present &&
                          item.recent_session &&
                          item.feedbackGiven == false
                            ? "Give Feedback"
                            : "View Session"
                        }
                        date={item.session_date}
                        subtitle={item.title}
                        data={courseData}
                        sessionData={item}
                      />
                    );
                  })}
              </div>
              <div className="flex justify-center"></div>
            </div>
          </>
        ) : (
          <div></div>
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default CourseProgress;
