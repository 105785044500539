import { SUPABASE_CLIENT } from "../../../Services/Supabase"
import { calculatePercentage, errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { FetchData, InsertData } from "../../crud"



export const pushNotificationRead = async (studentUid: string) => {
    try {
        let pushcontent: any = await getPushContentByStudentUid(studentUid)
        if (!pushcontent) {
            return pushcontent
        }
        let pushcontentIdList:any = []
         pushcontent?.map((el: any) => {
            // if (el.read != true) {
                pushcontentIdList.push(el.id)
            // }
        })


        const { data: student_data } : any=await FetchData(TableNames.students,['read_pushcontent'] ).eq('uid',studentUid)
        
        let readPushcontent:any = student_data[0].read_pushcontent
        readPushcontent = readPushcontent.concat(pushcontentIdList)
        readPushcontent = readPushcontent.filter((value:any, index:any, self:any) => {const numericValue = typeof value === 'string' && !isNaN(Number(value)) ? Number(value) : value; 
            return self.indexOf(numericValue) === index;});

        const { data: notification, error } = await SUPABASE_CLIENT.from('students').update({ "read_pushcontent" : readPushcontent }).eq('uid',studentUid).select('*')
        if (error) throw error
        return { 'status': 'success', data: notification }
    } catch (error) {
        return errorNotifier(error)
    }
}



export const getPushContentByStudentUid = async (studentUid: string) => {
    try {
        const { data: student_data } : any=await FetchData(TableNames.students,['read_pushcontent'] ).eq('uid',studentUid)

        const { data: training, error }: any = await FetchData(TableNames.trainings,

            ["id", "duration", "course_id", "batch:batches(in_progress, branch_id)", "courses:courses(duration)", "status", "batch_id"])
            .eq("student_id", studentUid).order("id", { ascending: false })

        //courses.duration
        if (!training.length) {
            const { data: pushdata } =await FetchData(TableNames.push_content, ['id','thumbnail', 'title', 'content_type'])
            pushdata?.map((content:any)=>{content.read =student_data[0].read_pushcontent.includes(content.id.toString())})
            return pushdata
        }
    
        const filtered_trainings = training?.filter((training: any) => training?.batch?.in_progress === true)
        
        const { data: technology }: any = await FetchData(TableNames.technologies, ["id"]).contains("courses", [filtered_trainings[0]?.course_id])

        // console.log(filtered_trainings, "filtered_trainings")
        const { data: student_sessions }: any = await FetchData(TableNames.sessions, ["id", "batch_id", "attendees", "title", "batch:batches(course:courses(course_name), branch:branches(id, branch_name), duration,batch_progress_in_hrs)", "details", "duration", "session_date", "recent_session"]).eq("batch_id", filtered_trainings[0]?.batch_id).order("id", { ascending: false })
        if(!student_sessions ){
            return false
        }

        const total_session_time = student_sessions.length? Number(student_sessions[0].batch.batch_progress_in_hrs):0
        const courseProgress = Math.round(calculatePercentage(total_session_time, training[0]?.courses.duration))
        const { data: pushContent } = await FetchData(TableNames.push_content).or
        (`student_status.eq.${filtered_trainings[0]?.status}, branch.cs.{${filtered_trainings[0]?.batch.branch_id}}, content_type.eq.Predefined Push Content`).order("id", { ascending: false })
        let filtered_push_content = pushContent?.filter((el: any) => {
            return courseProgress >= el.course_completion
        })
        filtered_push_content?.map((content:any)=>{content.read =student_data[0].read_pushcontent.includes(content.id.toString())})

        filtered_push_content = filtered_push_content?.sort((a: any, b: any) => (b.course_completion - a.course_completion))
        if (error) throw error
        
        return filtered_push_content
    } catch (error) {
        return errorNotifier(error)
    }

}
