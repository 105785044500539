import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import _ from "lodash";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export function Separator(props: any) {
    return (
        <div
            style={{
                position: "absolute",
                height: "100%",
                transform: `rotate(${props.turns}turn)`,
            }}
        >
            <div style={props.style} />
        </div>
    );
}

export function RadialSeparators(props: any) {
    const turns = 1 / props.count;
    return (
        <>
            {_.range(props.count).map((index: number) => (
                <Separator turns={index * turns} style={props.style} />
            ))}
        </>
    );
}

export const Progressbar = ({ percentage ,className}: any) => {
  

    const percentageVlaue =  Math.round(percentage)

    return (
        <div className={className}>
            <CircularProgressbarWithChildren
                value={percentage}
                text={`${percentageVlaue}%`}
                
                strokeWidth={15}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: `#55AB33`,
                    textColor: '#000',
                    
                })}
                
                
            >
                <RadialSeparators
                    count={10}
                    style={{
                        background: "#fff",
                        width: "2px",
                        
                        // This needs to be equal to props.strokeWidth
                        height: `${15}%`,
                    }}
                />
            </CircularProgressbarWithChildren>
        </div>
    );

};


export const StarRating = ({ rating }:any) => {
    return (
          <svg xmlns="http://www.w3.org/2000/svg" width="95" height="20" viewBox="0 0 59 11">
              <g transform="translate(-0.05 0.336)">
                  <g transform="translate(0 0)">
                      {/* 1st star */}
                      <rect fill="#dcdce6" width="11"  height="25" transform="translate(0.05 -0.336)" />
                      {rating >= 1 ? (
                          <rect fill="#ffce00" width="11"  height="25" transform="translate(0.05 -0.336)" />
                      ) : rating/1 == 1 ? (
                          <rect fill="#ffce00" width={parseFloat((rating % 1).toFixed(1)) * 11}  height="25" transform="translate(0.05 -0.336)" /> 
                      ) : null}
  
                      {/* 2nd star */}
                      <rect fill="#dcdce6" width="11"  height="25" transform="translate(12.05 -0.336)" />
                      {rating >= 2 ? (
                          <rect fill="#ffce00" width="11"  height="25" transform="translate(12.05 -0.336)" />
                      ) : rating/1 > 1 ? (
                          <rect fill="#ffce00" width={parseFloat((rating % 1).toFixed(1)) * 11}  height="25" transform="translate(12.05 -0.336)" />
                      ) : null}
  
                      {/* 3rd star */}
                      <rect fill="#dcdce6" width="11"  height="25" transform="translate(24.05 -0.336)" />
                      {rating >= 3 ? (
                          <rect fill="#ffce00" width="11"  height="25" transform="translate(24.05 -0.336)" />
                      ) : rating/1 > 2 ? (
                          <rect fill="#ffce00" width={parseFloat((rating % 1).toFixed(1)) * 11}  height="25" transform="translate(24.05 -0.336)" />
                      ) : null}
  
                      {/* 4th star */}
                      <rect fill="#dcdce6" width="11"  height="25" transform="translate(36.05 -0.336)" />
                      {rating >= 4 ? (
                          <rect fill="#ffce00" width="11"  height="25" transform="translate(36.05 -0.336)" />
                      ) : rating/1 > 3 ? (
                          <rect fill="#ffce00" width={parseFloat((rating % 1).toFixed(1)) * 11}  height="25" transform="translate(36.05 -0.336)" />
                      ) : null}
                      
                      {/* 5th star */}
                      <rect fill="#dcdce6" width="11"  height="25" transform="translate(48.05 -0.336)" />
                      {rating >= 5 ? (
                          <rect fill="#ffce00" width="11"  height="25" transform="translate(48.05 -0.336)" />
                      ) : rating/1 > 4 ? (
                          <rect fill="#ffce00" width={parseFloat((rating % 1).toFixed(1)) * 11}  height="25" transform="translate(48.05 -0.336)" />
                      ) : null}
  
                      <path fill="#fff"
                          d="M18.237,21.739l1.667-.423.7,2.147Zm3.837-2.775H19.14l-.9-2.764-.9,2.764H14.4l2.375,1.713-.9,2.764,2.375-1.713,1.462-1.051,2.364-1.713Z"
                          transform="translate(-12.595 -14.439)" />
                      <path fill="#fff"
                          d="M122.237,21.739l1.667-.423.7,2.147Zm3.837-2.775H123.14l-.9-2.764-.9,2.764H118.4l2.375,1.713-.9,2.764,2.375-1.713,1.462-1.051,2.364-1.713Z"
                          transform="translate(-104.718 -14.439)" />
                      <path fill="#fff"
                          d="M226.237,21.739l1.667-.423.7,2.147Zm3.837-2.775H227.14l-.9-2.764-.9,2.764H222.4l2.375,1.713-.9,2.764,2.375-1.713,1.462-1.051,2.364-1.713Z"
                          transform="translate(-196.84 -14.439)" />
                      <path fill="#fff"
                          d="M330.237,21.739l1.667-.423.7,2.147Zm3.837-2.775h-2.935l-.9-2.764-.9,2.764H326.4l2.375,1.713-.9,2.764,2.376-1.713,1.462-1.051,2.364-1.713Z"
                          transform="translate(-288.963 -14.439)" />
                      <path fill="#fff"
                          d="M434.237,21.739l1.667-.423.7,2.147Zm3.837-2.775h-2.935l-.9-2.764-.9,2.764H430.4l2.376,1.713-.9,2.764,2.375-1.713,1.462-1.051,2.364-1.713Z"
                          transform="translate(-381.085 -14.439)" />
                  </g>
              </g>
          </svg>
    )
  }
  