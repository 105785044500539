import * as Yup from "yup";

var strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validateEmail = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const loginValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .required("Required")
    .matches(validateEmail, "Only alphabets are allowed for this field ")
    .max(50),
  password: Yup.string().required("Please Enter Password"),
});

export const AddUserValidation = (isEdit: boolean) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .required("Please Enter Firstname")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    last_name: Yup.string()
      .required("Please Enter Lastname")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Required")
      .matches(validateEmail, "Only alphabets are allowed for this field ")
      .max(50),
    phone_number: Yup.string()
      .matches(phoneRegExp, {
        message: "Phone number is not valid",
        excludeEmptyString: false,
      })
      .length(10, "Please enter a valid mobile number")
      .required("Required"),
    ...(isEdit
      ? {
          password: Yup.string(),
          confirm_password: Yup.string(),
        }
      : {
          password: Yup.string()
            .required("Please Enter Password")
            .min(6, "Should have minimum 6 digits")
            .max(12, "Enter maximum 15 digits")
            .matches(/^(?=.*[a-z])/, "Must Contain One Lowercase Character")
            .matches(/^(?=.*[A-Z])/, "Must Contain One Uppercase Character")
            .matches(/^(?=.*[0-9])/, "Must Contain One Number Character")
            .matches(
              /^(?=.*[!@#\$%\^&\*])/,
              "  Must Contain  One Special Case Character"
            ),
          confirm_password: Yup.string()
            .required("Required")
            .oneOf([Yup.ref("password")], "Your passwords does not match."),
        }),
    // city: Yup.string(),
    // branches: Yup.array().of(Yup.string()),
    // roles: Yup.string(),
    // courses: Yup.array().of(Yup.string()),
  });
};

export const AddRoleValidation = Yup.object().shape({
  role: Yup.string(),
  createdby: Yup.string(),
});

export const AddBranchValidation = (fee_type: any) => {
  return Yup.object().shape({
    ownership: Yup.string().required("Required"),
    branch_name: Yup.string().required("Enter Branch Name"),
    company_name: Yup.string().required("Enter Company Name"),
    company_address: Yup.string().required("Fill the Address"),
    state: Yup.string().required("Required"),
    pincode: Yup.string().required("Please Enter Pincode"),
    ...(fee_type !== "None" && {
      gstin: Yup.string()
        // .matches(
        //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}[0-9]{1}$/,
        //   "Invalid GSTIN"
        // )
        .required("Enter GSTIN"),
      hsn: Yup.string()
        // .matches(/^[0-9]{6}$/, "Invalid HSN")
        .required("Enter HSN"),
    }),
    ...(fee_type === "IGST" && {
      igst: Yup.string()
        // .matches(/^[0-9]{1,5}(\.[0-9]{1,2})?$/, "Invalid IGST")
        .required("Enter IGST"),
    }),
    ...(fee_type === "SGST" && {
      sgst: Yup.string()
        // .matches(/^[0-9]{1,5}(\.[0-9]{1,2})?$/, 'Invalid SGST')
        .required("Enter SGST"),
      cgst: Yup.string()
        // .matches(/^[0-9]{1,5}(\.[0-9]{1,2})?$/, 'Invalid CGST')
        .required("Enter CGST"),
    }),
    // city: Yup.string().required("Enter City"),
    // rooms: Yup.array()
    //   .of(Yup.string().required('Enter Room Name'))
  });
};

export const AddCourseValidation = (type: string, material: any) => {
  return Yup.object().shape({
    course_name: Yup.string().required("Enter Course Name"),
    // ...(type === "Paid"
    //   ? {
    //       course_fee: Yup.string().required("Enter Course Fee"),
    //       // city_based_course_fee: Yup.array().of(
    //       //   Yup.object().shape({
    //       //     city: Yup.string().required("Select City"),
    //       //     course_fee: Yup.string().required("Enter Course Fee"),
    //       //     discounted_fee: Yup.string().required("Enter Discount Fee"),
    //       //   })
    //       // ),
    //       discounted_fee: Yup.string().required("Enter Discount Fee"),
    //     }
    //   : {}),
    description: Yup.string().required("Enter Description"),
    duration: Yup.string().required("Enter Duration"),
    ratings: Yup.string().required("Enter Rating"),
    students_enrolled: Yup.string().required("Enter Student Count"),
    certificate_description: Yup.string().required(
      "Enter Certificate Description"
    ),
    ...(material && {
      course_materials: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("Title is required"),
          show_percentage: Yup.string().required("Enter Percentage"),
          url: Yup.string().required("required"),
          // Add more validations for other fields if needed
        })
      ),
    }),
    ...(type === "Free" && {
      videos: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("Title is required"),
          link: Yup.string().required("required"),
        })
      ),
    }),
    ...(type === "Paid" && {
      syllabus: Yup.object().shape({
        url: Yup.string().required("URL is Required"),
      }),
    }),
    thumbnail: Yup.object().shape({
      url: Yup.string().required("Kindly Upload Thumbnail"),
    }),
    // ...(type === "Paid" && {
    //   city_based_course_fee: Yup.array().of(
    //     Yup.object().shape({
    //       city: Yup.array()
    //         .of(Yup.string())
    //         .min(1, "At least one city is required")
    //         .test("is-unique", "City names must be unique", function (value) {
    //           console.log("Value received in the test function:", value);
    //           // Rest of the validation logic...
    //         }),
    //       course_fee: Yup.string().required("required"),
    //       discounted_fee: Yup.string().required("required"),
    //     })
    //   ),
    // }),
  });
};

export const AddCategory = Yup.object().shape({
  category_name: Yup.string().required("Enter Category Name"),
  thumbnail: Yup.string().required("Kindly Upload Thumbnail"),
});

export const AddTechnology = Yup.object().shape({
  technology: Yup.string().required("Enter Technology Name"),
  course_name: Yup.array()
    .min(1, "Select AtLeast One Course")
    .required("Select Course")
    .of(Yup.string()),
});

export const AddCourseMapping = Yup.object().shape({
  mapping_course: Yup.string().required("Enter Course Mapping Name"),
  course_name: Yup.array()
    .min(1, "Select AtLeast One Course")
    .required("Select Course")
    .of(Yup.string()),
});

export const AddFeaturesData = Yup.object().shape({
  features: Yup.array().of(
    Yup.object().shape({
      feature: Yup.string().required("Title is required"),
      icon: Yup.string().required("Kindly Upload Icon"),
    })
  ),
});

export const AddTestimonial = (type: any) => {
  return Yup.object().shape({
    ...(type === "External Link" && {
      external_link: Yup.string()
        .required("Enter URL")
        .url("Please Enter URL Type"),
    }),
    ...(type === "Upload Video" && {
      video_url: Yup.object().shape({
        url: Yup.string().required("URL is required"),
      }),
    }),
    ...(type === "Upload Video" && {
      thumbnail: Yup.object().shape({
        url: Yup.string().required("Kindly Upload Thumbnail"),
      }),
    }),
  });
};

export const AddContact = Yup.object().shape({
  phone_number: Yup.string()
    .matches(phoneRegExp, {
      message: "Phone number is not valid",
      excludeEmptyString: false,
    })
    .length(10, "Please enter a valid mobile number")
    .required("Required"),
  email: Yup.string()
    .email("Email is invalid")
    .required("Please Enter Email")
    .matches(validateEmail, "Only alphabets are allowed for this field")
    .max(50),
});

export const AddPushData = (content_type: string, sub_type: string) => {
  return Yup.object().shape({
    title: Yup.string().required("Enter Title"),
    ...(content_type === "Instant Push Content" && {
      student_status: Yup.string().required("Select Status"),
    }),
    ...(content_type === "Instant Push Content" && {
      branch_names: Yup.array()
        .min(1, "Select AtLeast One Branch")
        .required("Select Branch")
        .of(Yup.string()),
    }),
    // tech_name: Yup.array()
    //   .min(1, "Select AtLeast One Technology")
    //   .required("Select Technology")
    //   .of(Yup.string()),
    // course_completion: Yup.string().required("Select Completion %"),
    ...(sub_type === "External Link" && {
      external_link: Yup.string().required("Enter Link"),
    }),
    ...(sub_type === "Text" && {
      text_message: Yup.string().required("Enter Text"),
    }),
    ...(sub_type === "Upload Video" && {
      video: Yup.object().shape({
        url: Yup.string().required("URL is required"),
      }),
    }),
    thumbnail: Yup.object().shape({
      url: Yup.string().required("Kindly Upload Thumbnail"),
    }),
  });
};

export const AddResourcePost = (content_type: string) => {
  return Yup.object().shape({
    title: Yup.string().required("Enter Title"),
    ...(content_type === "Instant Push Content" && {
      student_status: Yup.string().required("Select Status"),
    }),
    resource_category_name: Yup.string().required("Select Category Name"),
    // technology_names: Yup.array()
    //   .min(1, "Select AtLeast One Technology")
    //   .required("Select Technology")
    //   .of(Yup.string()),
    // ...(content_type === "External Link" && {
    //   external_link: Yup.string().required("Enter Link"),
    // }),
    ...(content_type === "Text" && {
      text_message: Yup.string().required("Enter Text"),
    }),
    ...(content_type === "Upload Video" && {
      video: Yup.object().shape({
        url: Yup.string().required("URL is required"),
      }),
    }),
    thumbnail: Yup.object().shape({
      url: Yup.string().required("Kindly Upload Thumbnail"),
    }),
  });
};

export const AddResourseCategory = Yup.object().shape({
  category_name: Yup.string().required("Enter Category Name"),
  thumbnail: Yup.string().required("Kindly Upload Thumbnail"),
  order: Yup.number().required("Enter Order Number"),
});

export const AddStudentCourse = Yup.object().shape({
  // phone_number: Yup.string()
  //   .matches(phoneRegExp, {
  //     message: "Phone number is not valid",
  //     excludeEmptyString: false,
  //   })
  //   .length(10, "Please enter a valid mobile number")
  //   .required("Enter PhoneNumber"),
  phone_number: Yup.string()
    .required("Phone number is required.")
    .test(
      "phoneNumber",
      "Invalid format based on country code",
      function (value) {
        const { number_code } = this.parent;
        const phoneRegExp = /^[0-9\-\s]+$/;

        if (number_code === "+91") {
          if (!phoneRegExp.test(value) || value.length !== 10) {
            return false;
          }
        } else {
          if (
            !phoneRegExp.test(value) ||
            value.length < 6 ||
            value.length > 15
          ) {
            return false;
          }
        }
        return true;
      }
    ),
  number_code: Yup.string()
    .required("Country code is required.")
    .min(1, "Country code must be at least 1 digit."),
  student_name: Yup.string().required("Enter Student Name"),
  email: Yup.string()
    .email("Email is invalid")
    .required("Please Enter Email")
    .matches(validateEmail, "Only alphabets are allowed for this field")
    .max(50),
  course_name: Yup.string().required("Select Course"),
  city_name: Yup.string().required("Select City"),
  branch_name: Yup.string().required("Select Branch"),
  course_schedule: Yup.string().required("Select Schedule"),
  // course_mode: Yup.string().required("Select Mode"),
  expected_start_date: Yup.string().required("Enter Date"),
  // comments: Yup.string().required("Please Enter Comments"),
  payment_details: Yup.array().of(
    Yup.object().shape({
      paid_amount: Yup.string().required("Enter Amount"),
      payment_date: Yup.string().required("Enter Date"),
    })
  ),
  balance_due: Yup.number()
    .required("Balance due is required")
    .min(0, "Balance due must be 0 or greater")
    .typeError("Invalid balance due value"),
});

export const AddBatch = Yup.object().shape({
  course: Yup.string().required("Select Course"),
  city: Yup.string().required("Select City"),
  branch: Yup.string().required("Select Branch"),
  schedule: Yup.string().required("Select schedule"),
  duration: Yup.string().required("Enter Duration"),
  start_date: Yup.string().required("Select Start Date"),
  start_time: Yup.string().required("Select Start Time"),
  end_time: Yup.string().required("Select End Time"),
  trainer_name: Yup.string().required("Enter Trainer Name"),
  trainer_phone_number: Yup.string()
    .matches(phoneRegExp, {
      message: "Phone number is not valid",
      excludeEmptyString: false,
    })
    .length(10, "Enter valid mobile number")
    .required("Enter PhoneNumber"),
  trainer_email: Yup.string()
    .email("Email is invalid")
    .required("Please Enter Email")
    .matches(validateEmail, "Only alphabets")
    .max(50),
});

export const AddSession = Yup.object().shape({
  session_date: Yup.string().required("Select Date"),
  start_time: Yup.string().required("Select Start Time"),
  end_time: Yup.string().required("Select End Time"),
});

export const AddCreatJobValidation = Yup.object().shape({
  company: Yup.string().required("Please enter a company"),
  position: Yup.string().required("Please enter a position"),
  location: Yup.string().required("Please enter a location"),
  technology: Yup.string().required("Please select a technology"),
  number_of_openings: Yup.string().required(
    "Please enter a number of openings"
  ),
});

export const ShortListedJobValidation = Yup.object().shape({
  interview_details: Yup.string(),
});

export const appEnquiriesValidation = Yup.object().shape({
  branch_name: Yup.string().required("Please select a branch"),
});

export const AddPerformAction = (status: any) => {
  return Yup.object().shape({
    status: Yup.string().required("Select Status"),
    ...((status === "Drop" || status === "Hold") && {
      reason: Yup.string().required("Enter Comments"),
    }),
    ...(status === "Nullify Payment" && {
      nullType: Yup.string().required("Select Type"),
    }),
    ...(status === "Nullify Payment" && {
      nullReason: Yup.string().required("Enter Reason"),
    }),
  });
};

export const BulkSessionUpdate = Yup.object().shape({
  session_date: Yup.string().required("Select Date"),
  start_time: Yup.string().required("Select Start Time"),
  end_time: Yup.string().required("Select End Time"),
});
