
import React, { useEffect } from 'react'
import Header from '../elements/header'
import { useState } from 'react'
import java from '../../images/photoshop.png'
import { Progressbar } from '../elements/progressBar'
import certification from '../../images/certification.png'
import closeicon from '../../images/close-red-icon.svg'
import { useLocation } from 'react-router-dom'
import { getCertificate, getTrainingDetails, requestCertificate } from '../../function/Student/Certificate/Certificate'
// import { uuid } from '../../redux/useReducer'
import { dateFormat } from '../elements/dateConvertion'
import { useNavigate } from 'react-router-dom'
import Footer from '../elements/Footer'

export default function RequestCertificate() {
    const uuid:any = localStorage.getItem('uid')
    const navigate = useNavigate()
    const { state } = useLocation() 
    const [studentDetails, setstudentDetails] = useState(state)
    const [cerficicateSubmit, setcerficicateSubmit] = useState(true)
    const handleSubmit = () => {
        requestCertificate(uuid,studentDetail?.course?.id,cetificate?.cetificate)
        setcerficicateSubmit(false)
    }

    const handleClose = () => {
        setcerficicateSubmit(true)
    }
    const [cetificate, setCertificate] =useState({})as any
    const [studentDetail, setStudentDetail] = useState({}) as any

    const data= async() => {
        const certifate = await getCertificate()
        setCertificate(certifate)
        const studentdetails = await getTrainingDetails(studentDetails)
        setStudentDetail(studentdetails)
    }

    const handleCirtificate =() =>{
        navigate('/profile/mycertificate', {state:{myState:'mycertificate'}})
    }
    
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        data()
    },[])

    return (
        <div>
            <Header />
            {cerficicateSubmit ?
                <div className='bg-[#F9FCFF] pb-10'>
                    <p className='text-left text-[#707070] text-[28px] font-medium px-8 pt-14'>Request a Certificate </p>
                    <div className='flex w-8/12 max-laptop:w-10/12 max-mobile:w-11/12 mx-auto rounded items-center shadow-3xl justify-between px-5 py-6 mt-4 bg-[#fff]'>
                        <div>
                            <img src={java} alt='java image' className='w-[180px] h-[100px] max-mobile:w-[100px]' />
                        </div>
                        <div>
                            <p className='text-[24px] max-mobile:text-[14px]'>{studentDetail?.course?.course_name}</p>
                        </div>
                        <div>
                            <Progressbar percentage={studentDetail?.overAllProgress} className='w-[100px] h-[100px] max-mobile:w-[60px] max-mobile:h-[60px]' />
                        </div>
                    </div>
                    <div className='w-8/12 max-laptop:w-10/12 max-mobile:w-11/12 mx-auto rounded shadow-3xl px-10 py-12 mt-10 text-left mb-9 bg-[#fff]'>
                        <div className='flex max-mobile:block justify-between '>
                            <div>
                                <p className='text-[28px]  text-[#707070] font-medium pb-3'>Certificate Info</p>
                                <div className=' pb-3 text-[25px] max-laptop:text-[22px] max-mobile:text-[18px]'>
                                    <p className=' my-2 flex text-[#707070] font-bold'>Name:<span className='text-[#707070] ml-3 font-normal'>{studentDetail?.student?.name}</span></p>
                                    <p className=' my-2 flex text-[#707070] font-bold'>Course:<span className='text-[#707070] ml-3 font-normal'>{studentDetail?.course?.course_name}</span></p>
                                    <p className=' my-2 flex text-[#707070] font-bold'>Duration:<span className='text-[#707070] ml-3 font-normal'>{studentDetail?.course?.duration} Hrs</span></p>
                                    <p className=' my-2 flex text-[#707070] font-bold'>Date of Completion:<span className='text-[#707070] ml-3 font-normal'>{dateFormat(studentDetail?.date_of_completion, "DD MMM YYYY")}</span></p>
                                </div>
                            </div>
                            <div>
                                <img src={cetificate?.certificate} alt='certification' className='w-[190px] h-[270px]' />
                            </div>
                        </div>
                        <div className='rounded-lg mx-auto bg-[#036AD1] w-32 items-center justify-between text-center mt-7'>
                            <button className='text-[#fff] max-monitor:text-[20px]  max-desktop:text-[20px] max-tablet:[16px] ' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
                : <div className=' flex h-[83vh] bg-[#F9FCFF] items-center align-middle '>
                    <div className=' align-middle mx-auto text-center relative  w-[570px] max-mobile:w-[80%]  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                        <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={handleClose} src={closeicon} alt='closeicon' />
                        <div className=' px-5 py-2 ml-5'>
                            <p className=' text-center text-[23px] items-center w-[95%] text-[#707070]'>Certificate Generated Sucessfully</p>
                        </div>
                        <div className='rounded-lg mx-auto bg-[#036AD1] w-44 items-center mt-7'>
                            <button onClick={handleCirtificate} className='text-[#fff] max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px] px-6 py-2'>My Certificate</button>
                        </div>
                    </div>
                </div>
            }
<Footer/>
            
        </div>
    )
}