import { Id } from "@reduxjs/toolkit/dist/tsHelpers";
import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables"
import { FetchData, FetchDataById, InsertData, UpdateData } from "../../crud"
import { FeedbackInterface } from "./interface";

export const getFeedback = async () => {
    try {
        const { data: feedbacks, error } = await FetchData(TableNames.feedbacks)
        if (error) throw error; 
        return feedbacks
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const getFeedbackById = async (feedbackId: number) => {
    try {
        const { data: feedback, error } = await FetchDataById(TableNames.feedbacks, feedbackId)
        if (error) throw error; 
        return feedback[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}


export const createFeedback = async (feedbackData: FeedbackInterface) => {
     try {
        const { data, error } = await InsertData(TableNames.feedbacks, feedbackData);
        // await UpdateData(TableNames.sessions, { recent_session: false }, {conditionKey: "id", conditionValue: feedbackData.session_id}) 
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}



export const updateStatus = async (tableName:any,id:any) => {
    try {
        // const {tableName,feedbackId} = formdata
        const { data, error } = await UpdateData(tableName,{status : 'ReOpen'},{ conditionKey: "id", conditionValue: id });
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}
