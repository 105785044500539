import React, { useState } from 'react';

import play from '../../images/YouTube.png'

const VideoPlayer = ( item :any) => {
    const [isPlaying, setIsPlaying] = useState(false);
    // console.log(videoUrl, 'saa', thumbnailUrl)
    let videoUrl =item?.thumbnailUrl?.video_url?.url
    let thumbnailUrl =item?.thumbnailUrl?.thumbnail?.url
    // console.log(videoUrl,'aaa',item)
    const playVideo = () => {
        // console.log(videoUrl, 'saa')
        setIsPlaying(true);
    };



    return (
        <div className="  ">
            {!isPlaying && (
                <div
                className=" w-[350px] h-[250px] bg-cover bg-center cursor-pointer items-center flex mt-4"
                    onClick={playVideo}
                    style={{ backgroundImage: `url(${thumbnailUrl}) `}}
                >
                <img src = {play} alt = 'play' className='w-[60px] h-[45px] mx-auto'/>
                </div>
            )}
            {isPlaying && (
                <iframe
                className=" py-4 w-[350px] h-[280px]"
                     src={videoUrl}
                    title="Embedded Video"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
};

export default VideoPlayer;