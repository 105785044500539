
import selectedCheckbox from "../../../images/selectedCheckbox.svg";
import emptyCheckbox from "../../../images/emptyCheckbox.svg";
import { useEffect, useState } from "react";

export const StudentDetails = ({formData,setFormdata, studentData }: any) => {
  const bodyTextStyle = `w-[30%] text-[#686868] text-center text-[14px] font-Roboto font-[400]`;
  const Header = ["", "Name", "Phone Number", "Status"];
  const [studentList,setStudentList] = useState([])
  const checkStatus = (uid: string, index: number) => {
    // console.log(uid, index, "uid_index");
    let attendeesDict = formData.attendees
    let attendees = 'batch_id' in studentData ? [...formData.attendees[studentData.batch_id]]:[...formData.attendees];
    if (attendees.includes(uid)) {
        attendees.splice(attendees.indexOf(uid), 1);
    } else {
        attendees.splice(index, 0, uid);
    }
    if(!Array.isArray(formData.attendees)){
      attendeesDict[studentData.batch_id] = attendees
    }else{
      attendeesDict = attendees
    }
    setFormdata((prevState: any) => ({
      ...prevState,
      attendees: attendeesDict
  }));
  };
  useEffect(()=>{
    if('batch_id' in studentData){
      setStudentList(studentData.student_list)
    }else{
      setStudentList(studentData)
    }
  },[])
  return (
    <div className="space-y-4 m-2">
  
      <h1 className="text-[#000000] font-Roboto font-[500]">
        Student Details
      </h1>
      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-3">
     {!Array.isArray(studentData)?  
     <div className="flex justify-between py-1 px-2">
        <p><span>{studentData.course_name}</span> <span>({studentData.batch_code})</span></p>
        <p>Mode : {studentData.mode?studentData.mode:'-'}</p>
      </div>
      :''}
        <div className="flex items-center justify-between bg-[#EFF7FF] py-3">
          {Header.map((head: any, i: number) => (
            <p
              className={`text-[#686868] text-[16px] font-Roboto font-[500] text-center ${
                i === 0 ? "w-[10%]" : "w-[30%]"
              }`}
            >
              {head}
            </p>
          ))}
        </div>
        <div className="min-h-[100px]">
          {studentList?.map((body: any, index: number) => {
            return (
              <div
                key={index}
                style={{wordBreak:'break-word'}} 
                className={`flex items-center justify-between py-3 ${
                  index % 2 !== 0 && "bg-[#EFF7FF]"
                }`}
              >
                {/* <input type='checkbox' className='w-[10%]' checked={formik.values?.attendees.includes(body?.student?.uid)} onChange={()=>checkStatus(body?.student?.uid, index)} /> */}
                <div className="w-[10%] flex items-center justify-center">
                  <img
                    src={
                    Array.isArray(formData.attendees)? formData.attendees.includes(body?.student?.uid)
                        ? selectedCheckbox
                        : emptyCheckbox:formData.attendees[studentData.batch_id].includes(body?.student?.uid)
                        ? selectedCheckbox
                        : emptyCheckbox

                    }
                    alt="CheckType"
                    className={`w-4 h-4 cursor-pointer`}
                    onClick={() => checkStatus(body?.student?.uid, index)}
                  />
                </div>
                <p className={bodyTextStyle}>{body?.student?.name}</p>
                <p className={bodyTextStyle}>{body?.student?.phoneNumber}</p>
                <p className={bodyTextStyle}>{body.status}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
