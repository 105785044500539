import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../elements/Footer'
import Header from '../elements/header'


function AllFreecoursePage( ) {
    const navigate = useNavigate()
    const {state} =useLocation()
    const [courseDetails] = useState(state)
    const handletech = (props:any) => {
        navigate(`/free-technologies-course/${props.category_name.replace(/ /g,'-')}}`, { state: props })
    }
    const [count, setCount] = useState(10)
  
    const handleLoad = () => {
       
        let data_Lenght = courseDetails?.map((item: any) => { return item })
        setCount(data_Lenght.length)
       
    }

    return (
        <>
        <Header />
      <> <p className=' text-left p-4 text-[30px] text-[#707070]  font-bold'> Explore All Free Courses </p>
        <div>
            {courseDetails.length>3 ?  
            <div className='grid grid-cols-5 h-4/6 gap-0.5 max-laptop:grid-cols-3 max-mobile:grid-cols-2  '>
            {courseDetails?.slice(0, count).map((item: any, index:number) => {
            return <div onClick={() => handletech(item)} key={item.id} className='flex flex-col  cursor-pointer items-center justify-center m-4 w-[250px] h-[250px]  max-desktop:w-[200px] max-desktop:h-[200px] max-mobile:w-[180px] max-mobile:h-[180px] shadow-3xl rounded-md bg-[#FFFFFF] mx-auto' >
            <img className='flex mx-auto justify-center items-center w-[35px] h-[50px] ' src={item.thumbnail} alt='icon' />
            <p className=' text-center mt-3  w-2/4 text-[17px] text-[#000000] font-normal'>{item.category_name}</p>
            <p className='text-[#4E4E4E]'>{item.no_of_courses} Courses</p>
        </div>
        
        })}
        </div> :  <div className="flex item-center h-4/6  px-20 mx-auto mb-5">
        {courseDetails?.slice(0, count).map((item: any, index:number) => {
            return <div onClick={() => handletech(item)} key={item.id} className='flex flex-col  cursor-pointer items-center justify-center m-4 w-[250px] h-[250px]  max-desktop:w-[200px] max-desktop:h-[200px] max-mobile:w-[180px] max-mobile:h-[180px] shadow-3xl rounded-md bg-[#FFFFFF] mx-auto' >
            <img className='flex mx-auto justify-center items-center w-[35px] h-[50px] ' src={item.thumbnail} alt='icon' />
            <p className=' text-center mt-3  w-2/4 text-[17px] text-[#000000] font-normal'>{item.category_name}</p>
            <p className='text-[#4E4E4E]'>{item.no_of_courses} Courses</p>
        </div>
        
        })}
        </div>}
       
    </div>
    <button onClick={handleLoad} className="text-white text-center mt-5  w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900] font-medium rounded-md text-[20px] px-5 py-2.5 mb-2" > Load More </button>
    </> 
       <Footer/>
    </>

  )
}

export default AllFreecoursePage