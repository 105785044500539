import React, { useEffect, useState, CSSProperties } from "react";
import CourseCard from "../elements/courseCard";
import profileCourseData from "../../content/profileCourseData.json";
// import { uuid } from "../../redux/useReducer";
import {
  getAllCertificates,
  getCertificateTemplate,
  getCourseDetails,
  getCourses,
  requestCertificate,
} from "../../function/Student/Profile/Profile";
import { Progressbar } from "../elements/progressBar";
import certificationwhilte from "../../images/certificationwhilte.svg";
import { dateFormat } from "../elements/dateConvertion";
import closeicon from "../../images/close-red-icon.svg";
import { useNavigate } from "react-router-dom";
import fitacertificate from "../../images/fitacertificate.png";
import ClipLoader from "react-spinners/ClipLoader";

function Mycourse() {
  const uuid:any = localStorage.getItem('uid')
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({}) as any;
  const [courseState, setCourseState] = useState(false);
  const [detailState, setDeatilState] = useState(false);
  const [CourseDetails, setCourseDetail] = useState({}) as any;
  const [certificateData, setCertificateData] = useState({}) as any;
  const [batchdetail, setBatchdetail] = useState({}) as any;
  const [certificatieImg, setCertificatieImg] = useState({}) as any;
  const [courseType, setCoursetype] = useState(true);
  const [submitState, setSubmitState] = useState(true);
  const [conform, setConform] = useState(true);
  const [pageState, setPageState] = useState(false);
  let [loading, setLoading] = useState(true);

  const override: CSSProperties = {
    flex: 1,
    marginTop: 300,
    marginLeft:450,
    alignItems: "center",
  };

  const data = async () => {
    setLoading(true)
    // console.log(uuid,'ase')
    const CourseData = await getCourses(uuid);
    setCourseData(CourseData);
    setPageState(true);
    setCourseState(true);
    setConform(true);
    setLoading(false)
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    data();
  }, []);


const currencyFormate = (price:any) => {
    price = parseFloat(price)
    return  price.toLocaleString("en-IN", {
      minimumFractionDigits: 0,
      maximunFractionDigits: 2,
    })
}
  // const filterData =() => {
  //   let filterdata = courseData.filter((obj: any) => obj.content_type.toLowerCase().includes(state.toLowerCase()))

  // }

  const handleClose = () => {
    setConform(true);
    setSubmitState(true);
  };
  const handleSubmit = async () => {
    setSubmitState(false);
  };
  const handleClick = async (props: any) => {
    const courseDetail = await getCourseDetails(props.id, props.batch_id, uuid);
    setCourseDetail(courseDetail);
    setBatchdetail(props);
    setCourseState(false);
    setDeatilState(true);
    if (props.course.type === "Free") {
      setCoursetype(false);
    } else {
      setCoursetype(true);
    }
  };

  const handleCertificate = async () => {
    setLoading(true);
    const requsetcertificate = await requestCertificate(
      uuid,
      batchdetail.id,
      batchdetail.batch_id
    );
    setCertificateData(requsetcertificate);
    const certificateimg = await getCertificateTemplate();
    setCertificatieImg(certificateimg);
    setDeatilState(false);
    setSubmitState(true);
    setLoading(false);
  };

  const handleCertificateRoute = () => {
    navigate("/profile/mycertificate", { state: { myState: "mycertificate" } });
  };

  const handleConform = () => {
    setConform(false);
  };

  const handleCancel = () => {
    setConform(true);
  };
  let element_div = null;
  return (
    <div className=" text-left bg-[#FFFFFF] h-full ">
      {loading == false? 
        <>
          {pageState ? (
            <div>
              {courseState ? (
                <div>
                  <p className="pt-8 text-[25px] max-tablet:text-[25px] max-mobile:text-[18px]  text-[#707070]   px-10">
                    My Courses
                  </p>
                  <hr className=" mx-10 " />
                  {courseData && courseData.length ?<div className="grid max-monitor grid-cols-3  gap-4 px-10  max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                  {courseData?.map((item: any) => {
                    
                    item?.course?.type === "Paid"
                      ? (element_div = (
                          <div onClick={() => handleClick(item)}>
                            <div>
                              <CourseCard
                                image={item.course.thumbnail.url}
                                value={item.course.course_name}
                                type={item.course.type}
                              />
                            </div>
                          </div>
                        ))
                      : (element_div = "");
                    return element_div;
                  })}
                  </div> :  <p className='text-center flex items-center justify-center mt-32 text-[20px] max-mobile:text-[16px] w-[70%] mx-auto'>

Currently, no courses are available for you. <br></br>
To get started, please explore our course catalog and enroll in a course now.

</p>}
                  

                  <div className="grid max-monitor:grid-cols-3 mt-5 gap-4 px-10 max-desktop:grid-cols-3 max-laptop:grid-cols-2 max-mobile:grid-cols-1 py-10">
                    {courseData?.map((item: any) => {
                      item?.course?.type === "Free"
                        ? (element_div = (
                            <div onClick={() => handleClick(item)}>
                              <div>
                                <CourseCard
                                  image={item.course.thumbnail.url}
                                  value={item.course.course_name}
                                  type={item.course.type}
                                />
                              </div>
                            </div>
                          ))
                        : (element_div = "");
                      return element_div;
                    })}
                  </div>
                </div>
              ) : (
                <div className=" w-full h-full">
                  {detailState ? (
                    <div className="pb-6">
                      <p className="pt-5 text-[25px] max-tablet:text-[25px] max-mobile:text-[18px]  text-[#707070]  px-10">
                        My Courses
                      </p>
                      <hr className="my-4 mx-10 " />
                      {courseType ? (
                        <div>
                    
                              
                          <div className=" px-10 max-mobile:px-3">
                          
                        
                            <div className="flex w-6/12 max-tablet:w-11/12 max-mobile:w-[92vw] p-3 rounded-lg shadow-3xl justify-between bg-[#FFFFFF] items-center text-center">
                              <img
                                className="w-[100px] h-[60px]"
                                src={CourseDetails?.course ? CourseDetails?.course?.thumbnail.url:CourseDetails?.courseData?.course?.thumbnail.url}
                                alt="thumbnail"
                              />
                              <p className="text-[18px] text-[#3B3B3B] font-normal">
                           {CourseDetails?.course ? CourseDetails?.course?.course_name:CourseDetails?.courseData?.course?.course_name}

                              </p>
                              
                              <Progressbar
                                percentage={
                                  CourseDetails?.overAllProgress != undefined ? CourseDetails?.overAllProgress:CourseDetails.courseData.course_progress
                                }
                                className="w-[55px] h-[55px]"
                              />
                            </div>
                            <p className="text-[25px] max-tablet:text-[25px] max-mobile:text-[18px] font-bold text-left my-6">
                              Payment Summary
                            </p>
                            <div className="w-full  text-[20px]  max-laptop:text-[14px]  flex shadow-3xl justify-between max-tablet:block">
                              <div className=" bg-[#FFFFFF] w-[30%] py-5 pl-4  max-laptop:py-4 ">
                                <p className="text-[#686868]">Course</p>
                                <p className=" text-[#000] font-bold ">
                                  {" "}
                                  {CourseDetails?.payment_summary?.course_name}
                                </p>
                              </div>
                              <div className=" grid grid-cols-3 w-[70%] max-tablet:w-full py-3 text-[16px] max-tablet:text-[16px] max-mobile:text-[14px] bg-[#F9F9F9]  text-center  border-[#036AD1] divide-x h-auto border-l border-opacity-28 items-center">
                                <div className=" ">
                                  <p className="text-[#686868]">Fees</p>
                                  <p className=" text-[#000] font-bold">
                                    <span>&#8377;</span>{" "}
                                    {currencyFormate(CourseDetails?.payment_summary?.total_fee)}
                                  </p>
                                </div>
                                <div className=" ">
                                  <p className="text-[#686868]">Paid Amount</p>
                                  <p className=" text-[#000] font-bold">
                                    <span>&#8377;</span>{" "}
                                    {
                                      currencyFormate(CourseDetails?.payment_summary
                                        ?.paid_amount)
                            
                                    }
                                  </p>
                                </div>
                                <div className="text-[#FF0000] font-bold">
                                  <p className="">Balance Due</p>
                                  <p className="  ">
                                    <span>&#8377;</span>{" "}
                                    {
                                    currencyFormate(CourseDetails?.payment_summary
                                        ?.balance_due)
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className=" bg-[#FCFCFC] w-full my-5">
                            <div className="px-10 py-4">
                              <p className="text-[25px] max-tablet:text-[25px] max-mobile:text-[18px] font-bold text-left my-6">
                                Payment Details
                              </p>
                              {CourseDetails?.courseData?.payment_details.map(
                                (item: any) => {
                                  return (
                                    <div className="w-full max-mobile:overflow-scroll max-tablet:w-full max-mobile:w-[83vw] text-[20px] max-laptop:text-[14px] bg-[#FFFFFF] flex shadow-3xl items-center justify-between my-5">
                                      <div className="  w-[30%] max-tablet:w-[30%] max-mobile:w-[50%] max-mobile:text-[14px] py-5 pl-4  max-laptop:p-4  mx-auto">
                                        <p className="text-[#686868]">
                                          Payment Date
                                        </p>
                                        <p className=" text-[#000] font-bold">
                                          {dateFormat(
                                            item.payment_date,
                                            "DD MMM YYYY"
                                          )}{" "}
                                        </p>
                                      </div>
                                      <div className=" grid grid-cols-3 w-[70%]  max-mobile:flex  max-tablet:w-full py-3 text-[16px] max-mobile:text-[14px]  text-center   h-auto items-center">
                                        <div className=" max-mobile:w-[50vw]">
                                          <p className="text-[#686868]">
                                            Mode of Payment
                                          </p>
                                          <p className=" text-[#000] break-words font-bold">
                                            {item.payment_type}
                                          </p>
                                        </div>
                                        <div className="max-mobile:w-[60vw] ">
                                          <p className="text-[#686868]">
                                            Amount Paid
                                          </p>
                                          <p className=" text-[#000] font-bold">
                                            <span>&#8377;</span>{" "}
                                            {currencyFormate((parseFloat(item.paid_amount) ))}
                
                                          </p>
                                        </div>
                                        <div className="max-mobile:w-[50vw]">
                                          <p className="text-[#707070]">
                                            Balance Due
                                          </p>
                                          <p className=" text-[#FF0000] ">
                                            <span>&#8377;</span> {currencyFormate(parseFloat(item.balance).toFixed(2))}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        
                        <div className=" flex  items-center justify-center">
                          <div className="flex w-6/12 max-mobile:w-9/12  my-10 p-3 rounded-lg shadow-3xl justify-between bg-[#FFFFFF] items-center text-center">
                            <img
                              className="w-[100px] h-[60px]"
                              src={CourseDetails?.course?.thumbnail?.url}
                              alt="thumbnail"
                            />
                            <p className="text-[18px] text-[#3B3B3B] font-normal">
                              {CourseDetails?.course?.course_name}
                            </p>
                            <Progressbar
                              percentage={CourseDetails?.overAllProgress}
                              className="w-[55px] h-[55px]"
                            />
                          </div>
                        </div>
                      )}
                      {CourseDetails?.courseData?.course_progress === 100 ? (
                        <div
                          className="flex text-[#fff]  text-[22px] max-tablet:text-[22px] max-mobile:text-[18px] cursor-pointer font-bold w-fit bg-[#036AD1] p-4 max-tablet:p-4 max-mobile:p-2 rounded-md  items-center justify-center mx-auto"
                          onClick={handleCertificate}
                        >
                          <img
                            className=" pr-5"
                            src={certificationwhilte}
                            alt="icon"
                          />
                          <p>Request a Certificates</p>
                        </div>
                      ) : (
                        <div>
                          <div  className="flex text-[#fff]  text-[22px]  max-mobile:text-[18px] cursor-pointer font-bold w-fit bg-[#036AD1] p-4 max-tablet:p-4 max-mobile:p-2 disabled: rounded-md  items-center justify-center mx-auto">
                            <img
                              className=" pr-5"
                              src={certificationwhilte}
                              alt="icon"
                            />
                            <p>Request a Certificate</p>
                          </div>
                          <p className="text-center pt-5 w-[60%] mx-auto text-[28px] max-mobile:text-[18px] text-[#707070] max-tablet:text-[20px] max-tablet:w-[80%]">
                            You must need to complete 100% of your course to
                            request a certificate
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {submitState ? (
                        <div>
                          {courseType ? (
                            <div>
                              {conform ? (
                                <div>
                                  <p className="pt-5 text-[25px]  text-[#707070]  px-10">
                                    Request a Certificate
                                  </p>
                                  <hr className="my-4 mx-10 " />
                                  <div className="flex w-6/12 p-3 shadow-3xl justify-between rounded-lg bg-[#FFFFFF] mx-auto items-center text-center max-laptop:w-10/12 max-mobile:w-[80vw]">
                                    <img
                                      className="w-[100px] h-[60px]"
                                      src={
                                        certificateData?.courseData?.course
                                          ?.thumbnail?.url
                                      }
                                      alt="thumbnail"
                                    />
                                    <p className="text-[18px] text-[#3B3B3B] font-normal">
                                      {
                                        certificateData?.courseData?.course
                                          ?.course_name
                                      }
                                    </p>
                                    <Progressbar
                                      percentage={
                                        certificateData?.courseData?.course_progress
                                      }
                                      className="w-[55px] h-[55px]"
                                    />
                                  </div>
                                  <div className="w-full  mx-auto bg-[#FCFCFC] my-5 py-10">
                                    <div className="flex max-mobile:block">
                                      <div className=" flex max-mobile:justify-center max-tablet:block max-mobile:block py-10 mx-auto justify-center">
                                        <div className="mr-32 mt-5 max-mobile:-mt-8 max-mobile:mr-0 max-mobile:ml-10">
                                          <p className=" text-[#373737] text-[25px] ">
                                            Certificate Info
                                          </p>
                                          <div className=" flex text-[#707070] text-[20px] ">
                                            <p className=" font-bold">Name: </p>
                                            <p className=" font-normal pl-4">
                                              {certificateData?.student_data?.map(
                                                (item: any) => {
                                                  return item.name;
                                                }
                                              )}
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Course:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {
                                                certificateData?.courseData
                                                  ?.course?.course_name
                                              }
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Duration:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {
                                                certificateData?.courseData
                                                  ?.course?.duration
                                              }
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Course completion:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {" "}
                                              {dateFormat(
                                                certificateData?.courseData
                                                  ?.date_of_completion,
                                                "MMM YYYY"
                                              )}{" "}
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Branch:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {
                                                certificateData?.courseData
                                                  ?.batch?.branches?.branch_name
                                              }
                                              ,{" "}
                                              {
                                                certificateData?.courseData
                                                  ?.batch?.city?.city_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="max-mobile:mx-24 max-tablet:my-10 max-tablet:mx-24 max-mobile:my-10">
                                          <img
                                            className="w-[200px] h-[250px]"
                                            src={fitacertificate}
                                            alt="certificate"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p
                                        className="flex  text-[#fff] text-[17px] cursor-pointer font-bold w-fit bg-[#036AD1] rounded-full px-10 py-3 items-center justify-center mx-auto mb-10"
                                        onClick={handleConform}
                                      >
                                        Submit
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="py-40 ">
                                  <div className=" align-middle mx-auto text-center relative py-10  w-[570px] max-tablet:w-[80%]  max-mobile:w-[86vw] justify-center p-6 bg-white border border-gray-200 rounded-lg shadow">
                                    <div className=" px-5 py-2 ml-5">
                                      <p className=" text-center text-[23px] max-mobile:text-[18px] items-center w-[95%] text-[#707070]">
                                        Check the Certificate Information
                                      </p>
                                    </div>
                                    <div className=" inline-flex mx-auto item-center">
                                      <button
                                        onClick={handleCancel}
                                        className="text-[#fff]  mr-9  bg-[red] rounded-md max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px] px-10 max-tablet:px-10 max-mobile:px-4 max-mobile:py-1 py-2"
                                      >
                                        cancel
                                      </button>
                                      <button
                                        onClick={handleSubmit}
                                        className="text-[#fff]  bg-[#036AD1] rounded-md max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px] px-10 max-tablet:px-10 max-mobile:py-1 max-mobile:px-4 py-2"
                                      >
                                        yes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {conform ? (
                                <div>
                                  <p className="pt-5 text-[25px]  px-10">
                                    Request a Certificate
                                  </p>
                                  <hr className="my-4 mx-10 " />
                                  <div className=" flex items-center justify-center">
                                    <div className="flex w-6/12 max-mobile:w-9/12 max-laptop:w-10/12 my-10 p-3 rounded-lg shadow-3xl justify-between bg-[#FFFFFF] items-center text-center">
                                      <img
                                        className="w-[100px] h-[60px]"
                                        src={
                                          CourseDetails?.course?.thumbnail?.url
                                        }
                                        alt="thumbnail"
                                      />
                                      <p className="text-[18px] text-[#3B3B3B] font-normal">
                                        {CourseDetails?.course?.course_name}
                                      </p>
                                      <Progressbar
                                        percentage={
                                          CourseDetails?.overAllProgress
                                        }
                                        className="w-[55px] h-[55px]"
                                      />
                                    </div>
                                  </div>

                                  <div className="w-full  mx-auto bg-[#FCFCFC] my-5 py-10">
                                    <div className="flex max-mobile:block ">
                                      <div className=" flex max-tablet:block max-mobile:block py-10 mx-auto justify-center">
                                        <div className="mr-32 ">
                                          <p className=" text-[#373737] text-[25px] ">
                                            Certificate Info
                                          </p>
                                          <div className=" flex text-[#707070] text-[20px] ">
                                            <p className=" font-bold">Name: </p>
                                            <p className=" font-normal pl-4">
                                              {certificateData?.student?.name}
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Course:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {
                                                certificateData?.course
                                                  ?.course_name
                                              }
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Duration:{" "}
                                            </p>
                                            <p className=" font-normal px-4">
                                              {
                                                certificateData?.course
                                                  ?.duration
                                              }{" "}
                                              Hrs
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Course completion:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {dateFormat(
                                                certificateData?.date_of_completion,
                                                "MMM YYYY"
                                              )}{" "}
                                            </p>
                                          </div>
                                          <div className=" flex text-[#707070] text-[20px]">
                                            <p className=" font-bold">
                                              Branch:{" "}
                                            </p>
                                            <p className=" font-normal pl-4">
                                              {
                                                certificateData?.branch
                                                  ?.branch_name
                                              }
                                              ,{" "}
                                              {certificateData?.city?.city_name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="max-mobile:mx-24 max-tablet:mx-24 max-tablet:my-10 max-mobile:my-10">
                                          <img
                                            className="w-[200px] h-[250px]"
                                            src={fitacertificate}
                                            alt="certificate"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p
                                        className="flex text-[#fff] text-[18px] cursor-pointer font-bold w-fit bg-[#036AD1] rounded-full px-10 py-3 items-center justify-center mx-auto mb-7"
                                        onClick={handleConform}
                                      >
                                        Submit
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="py-40 ">
                                  <div className=" align-middle mx-auto text-center relative py-10  w-[570px] max-tablet:w-[80%]  max-mobile:w-[70%] justify-center p-6 bg-white border border-gray-200 rounded-lg shadow">
                                    <div className=" px-5 py-2 ml-5">
                                      <p className=" text-center text-[23px] items-center w-[95%] text-[#707070]">
                                        Check the Certificate Information
                                      </p>
                                    </div>
                                    <div className=" inline-flex mx-auto item-center">
                                      <button
                                        onClick={handleCancel}
                                        className="text-[#fff]  mr-9  bg-[red] rounded-md max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px] px-6 py-2"
                                      >
                                        cancel
                                      </button>
                                      <button
                                        onClick={handleSubmit}
                                        className="text-[#fff] bg-[#036AD1] rounded-md max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px] px-6 py-2"
                                      >
                                        yes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className=" bg-[#F9FCFF] min-h-screen pb-80 flex  ">
                          <div className=" flex items-center justify-center mt-48 align-middle mx-auto">
                            <div className=" align-middle mx-auto text-center relative  w-[570px] max-tablet:w-[95%]  max-mobile:w-[80%] justify-center p-6 bg-white border border-gray-200 rounded-lg shadow">
                              <img
                                className="cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 "
                                onClick={handleClose}
                                src={closeicon}
                                alt="closeicon"
                              />
                              <div className=" px-5 py-2 ml-5">
                                <p className=" text-center text-[23px] items-center w-[95%] text-[#707070]">
                                  Certificate Generated Sucessfully
                                </p>
                              </div>
                              <div
                                className="rounded-lg mx-auto bg-[#036AD1] w-44 items-center mt-7"
                                onClick={handleCertificateRoute}
                              >
                                <button className="text-[#fff] max-monitor:text-[20px] max-desktop:text-[20px] max-tablet:[16px] px-6 py-2">
                                  My Certificate
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </>
       :
        <>
         
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </>
      }
    </div>
  );
}

export default Mycourse;
